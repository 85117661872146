import React from "react";
import { withStyles, CircularProgress, Snackbar, Button } from "@material-ui/core";
import { primaryColor } from "assets/jss/material-dashboard-react.jsx";

const circularStyles = () => ({
  coverLoading: {
    position: "fixed",
    top: 0,
    height: "100%",
    width: "calc(100% - 10px)",
    backgroundColor: "#ddd",
    opacity: 0.3,
  },
  progress: {
    color: primaryColor,
    position: "fixed",
    top: "calc(50% - 25px)",
    left: "calc(50% - 25px)",
  }
});

export const CircularLoader = withStyles(circularStyles)(function (props) {
  const { classes, zIndex } = props;
  const cover_zIndex = isNaN(zIndex) ? 10 : zIndex;

  return (
    <div>
      <div className={classes.coverLoading} style={{ zIndex: cover_zIndex }} />
      <CircularProgress className={classes.progress} size={50} thickness={5} style={{ zIndex: cover_zIndex + 1 }} />
    </div>
  );
});

const progressStyles = () => ({
  table: {
    width: 280,
    maxWidth: "100%",
  },
  tableInfoColumn: {
    width: 20,
  },
  tableNameColumn: {
    paddingRight: 10,
  },
  cancelButtonDisabled: {
    color: 'gray !important',
  }
});
let progressLoaderCounter = 0;

class ProgressLoaderClass extends React.Component {
  constructor(props) {
    super(props);

    this._internalName = `progress-loader-${progressLoaderCounter++}`;
  }

  render() {
    const { classes, payload, onCancelClicked, disabledCancelButton, message, action, ContentProps, ...args } = this.props;

    return (
      <Snackbar
        {...args}
        message={
          <div id={this._internalName}>
            <table className={classes.table}>
              <tbody>
                {payload.map((m, i) => (
                  <tr key={`${this._internalName}-entry-${i}`}>
                    <td className={classes.tableNameColumn}>
                      {m.name.length > 24 ? m.name.substr(0, 22).trim() + '..' + m.name.substr(m.name.lastIndexOf('.')) : m.name}
                    </td>
                    <td className={classes.tableInfoColumn}>
                      {m.percentage < 100 ? `${m.percentage}%` : 'Done'}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        }
        action={[
          <Button
            variant="text"
            key="cancelLoad"
            color="inherit"
            size="small"
            onClick={onCancelClicked}
            disabled={disabledCancelButton}
            classes={{ disabled: classes.cancelButtonDisabled }}>
            Cancelar
          </Button>
        ]}
        ContentProps={{
          "aria-describedby": this._internalName,
        }}
      />
    );
  }
}

export const ProgressLoader = withStyles(progressStyles)(ProgressLoaderClass);