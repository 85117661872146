import React from "react";
import PropTypes from "prop-types";

import Table from "@material-ui/core/Table";
import withStyles from "@material-ui/core/styles/withStyles";
import Grid from "@material-ui/core/Grid";

import CircularProgress from "@material-ui/core/CircularProgress";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import TableRow from "@material-ui/core/TableRow";
import { Redirect } from "react-router-dom";
import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";

import AuthService from "components/Services/AuthService";

const styles = theme => ({
  root: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
  },
  input: {
    marginLeft: 8,
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  progress: {
    margin: theme.spacing.unit * 2,
  },
});

let debounce = null;

class GarbagePickups extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      navigateToCreate: false,
      navigateToEdit: null,
      originalPickups: [],
      garbagePickups: [],
      search: "",
      error: null,
    };
    this.AuthService = new AuthService();
  }

  componentDidMount() {
    this.setState({ loading: true });
    this.AuthService.authFetch("/admin/garbage-pickup")
      .then(response => {
        this.setState({
          loading: false,
          garbagePickups: response.result,
          originalPickups: response.result,
        });
      })
      .catch(error => this.setState({ loading: false, error: error }));
  }

  searchSector = event => {
    const self = this;
    event.persist();
    clearTimeout(debounce);
    debounce = setTimeout(() => {
      const { value } = event.target;

      if (value === "") {
        self.setState({
          garbagePickups: self.state.originalPickups,
        });
      } else {
        self.setState({
          garbagePickups: self.state.originalPickups.filter(pickup => {
            return pickup.name.toLowerCase().indexOf(value.toLowerCase()) !== -1;
          }),
        });
      }
    }, 250);
  };

  render() {
    const { classes } = this.props;
    if (this.state.navigateToEdit !== null) {
      return (
        <Redirect
          push
          to={{
            pathname: "/garbage-pickup/pickups/edit",
            state: { garbagePickup: this.state.navigateToEdit },
          }}
        />
      );
    }
    if (this.state.loading === true) {
      return (
        <Grid container spacing={8} justify="center">
          <Grid item xs={3} sm={3} md={3}>
            <CircularProgress className={classes.progress} size={50} />
          </Grid>
        </Grid>
      );
    }

    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>Listado de recogida de basura</h4>
              <Paper className={classes.root} elevation={1}>
                <IconButton className={classes.iconButton} aria-label="Search">
                  <SearchIcon />
                </IconButton>
                <InputBase className={classes.input} placeholder="Buscar sector" onChange={this.searchSector} />
              </Paper>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <Grid item xs={12} sm={12} md={12}>
                  <Table>
                    <TableBody>
                      {this.state.garbagePickups.map(garbagePickup => {
                        return (
                          <TableRow
                            key={garbagePickup.id}
                            hover
                            onClick={() => {
                              this.setState({ navigateToEdit: garbagePickup });
                            }}>
                            <TableCell>{garbagePickup.name}</TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </Grid>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}
GarbagePickups.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(GarbagePickups);
