import React from "react";
import PropTypes from "prop-types";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import InputBase from "@material-ui/core/InputBase";
import FormControl from "@material-ui/core/FormControl";
import NoSsr from "@material-ui/core/NoSsr";

import AuthService from "components/Services/AuthService";

// core components
import Button from "components/CustomButtons/Button.jsx";
import Select from "components/Select/Select.jsx";

const styles = theme => ({
  input: {
    display: "flex",
    padding: 0,
  },
  divider: {
    height: theme.spacing.unit * 2,
  },
  commentForm: {
    display: "flex",
    flexWrap: "wrap",
  },
  bootstrapRoot: {
    width: "100%",
  },
  margin: {
    width: "100%",
    marginTop: theme.spacing.unit,
  },
  bootstrapInput: {
    borderRadius: 4,
    backgroundColor: theme.palette.common.white,
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: theme.spacing.unit,
    marginLeft: -theme.spacing.unit,
    width: "100%",
    height: "100px !important",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    "&:focus": {
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
});

class IncidentUserDialog extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      users: [],
      templates: props.templates
        .map(m => ({
          id: m.id,
          label: m.name,
          value: m.description
        })),
      userSelected: null,
      comment: "",
      loading: false,
      error: null,
    };

    this.suggestions = [];
    this.AuthService = new AuthService();
  }

  handleAccept = () => {
    const { action } = this.props;
    let selected = this.state.userSelected;

    if (this.props.onAccept)
      this.props.onAccept(action, selected, this.state.comment);
  };

  handleClose = () => {
    if (this.props.onClose)
      this.props.onClose();
  };

  handleChangeComment = event => {
    this.setState({ comment: event.target.value });
  };

  componentDidMount() {
    this.AuthService.authFetch("/account/users/incidents")
      .then(res => {
        const { selectedValue } = this.props;
        const newState = {
          users: res.result.users
        };

        newState.users.map(user => ({
          value: user.id,
          label: user.displayName
        })).forEach(f => {
          this.suggestions.push(f);
        });

        const selected = selectedValue ? this.suggestions.find(f => f.value === selectedValue.id) : null;

        if (selected)
          newState.userSelected = selected;

        this.setState(newState);
      })
      .catch(error => {
        console.error('Failed retrieving users (handlers):', error); // eslint-disable-line
      });
  }

  componentWillReceiveProps(nextProps) {
    const newState = {};

    if (nextProps.selectedValue !== this.props.selectedValue) {
      const { selectedValue } = nextProps;
      let selected;

      if (Array.isArray(selectedValue))
        selected = this.suggestions.filter(f => selectedValue.some(s => s.id === f.id));
      else
        selected = selectedValue ? this.suggestions.find(f => f.value === selectedValue.id) : null;

      newState.userSelected = selected;
    }

    if (nextProps.templates !== this.props.templates)
      newState.templates = nextProps.templates
        .map(m => ({
          id: m.id,
          label: m.name,
          value: m.description
        }));

    this.setState(newState);
  }

  render() {
    const { classes, title, action, selectedValue, onAccept, ...other } = this.props;
    const { userSelected } = this.state;

    const templates = [{
      id: 'BLANK_ID',
      label: '(Sin plantilla)',
      value: ''
    }].concat(this.state.templates);

    const changingResponsible = action === 'change-handler' || action === 'change-solver';
    const acceptMultiple = !changingResponsible;

    return (
      <Dialog
        onEntering={() => {
          if (this.state.comment)
            this.setState({
              comment: '',
              userSelected: null,
            });
        }}
        onClose={this.handleClose}
        aria-labelledby="simple-dialog-title"
        {...other}>
        <DialogTitle id="simple-dialog-title">{title}{''}</DialogTitle>
        <DialogContent style={{ width: "500px" }}>
          <NoSsr>
            <Select
              multiple={acceptMultiple}
              options={this.suggestions}
              onChange={(value) => {
                this.setState({
                  userSelected: value
                });
              }}
              placeholder="Escribe el nombre del usuario"
              value={userSelected}
            />

            <div style={changingResponsible ? { display: 'none' } : {}}>
              <br />

              <Select
                options={templates}
                onChange={obj => {
                  this.setState({ comment: obj.value });
                }}
                placeholder="(Opcional) seleccione una plantilla"
              />
            </div>
          </NoSsr>
          <div className={classes.divider} />
          <form className={classes.commentForm} autoComplete="off" style={changingResponsible ? { visibility: 'hidden' } : {}}>
            <FormControl className={classes.margin}>
              <InputBase
                id="bootstrap-input"
                multiline
                placeholder="Comentario"
                value={this.state.comment}
                onChange={this.handleChangeComment}
                classes={{
                  root: classes.bootstrapRoot,
                  input: classes.bootstrapInput,
                }}
              />
            </FormControl>
            <span style={{ fontSize: "12px", marginLeft: "-8px" }}>Puede ingresar un comentario de forma opcional.</span>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleClose} color="danger" simple={true}>
            Cancelar
          </Button>
          <Button onClick={this.handleAccept} color="primary" simple={true}>
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

IncidentUserDialog.propTypes = {
  title: PropTypes.string,
  templates: PropTypes.array,
  open: PropTypes.bool,
  onAccept: PropTypes.func,
  onClose: PropTypes.func,
  selectedValue: PropTypes.object,
  action: PropTypes.string
};

export default withStyles(styles)(IncidentUserDialog);
