import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Dialog from "@material-ui/core/Dialog";
import IconButton from "@material-ui/core/IconButton";

// @material-ui/icons
import Close from "@material-ui/icons/Close";

import { primaryColor } from "assets/jss/material-dashboard-react.jsx";

const styles = {
  dialogRoot: {
    "& div": {
      maxWidth: "none !important;",
      maxHeight: "none !important;",
    },
  },
  close: {
    fontSize: 36,
    color: primaryColor,
  },
  container: {
    width: "100%",
    "& img": {
      width: "100%;",
      height: "auto;",
      marginBottom: "-7px",
      marginTop: "-60px",
    },
  },
};

function ImageDialog({ ...props }) {
  const { classes, image, open } = props;

  return (
    <Dialog
      open={open}
      onClick={() => props.closeDialog()}
      classes={{
        root: classes.dialogRoot,
      }}>
      <div>
        <IconButton className={classes.iconButton} key="close" aria-label="Close" color="inherit" onClick={() => props.closeDialog()} style={{ float: "right" }}>
          <Close className={classes.close} />
        </IconButton>
      </div>
      <div className={classes.container}>
        <img alt="Dialog img" src={image} />
      </div>
    </Dialog>
  );
}

ImageDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  image: PropTypes.node,
  open: PropTypes.bool,
};

export default withStyles(styles)(ImageDialog);
