import React from "react";
import { DialogContent, Dialog, DialogActions, Button, DialogTitle, RadioGroup, FormControlLabel, Radio } from "@material-ui/core";

export default class ConfirmOptionDialog extends React.Component {
  state = {
    value: this.props.value
  };

  handleAcceptClick = () => {
    if (this.props.onAcceptBtnClick)
      this.props.onAcceptBtnClick();

    this.props.onClose(this.state.value);
  }

  handleCancelClick = () => {
    if (this.props.onCancelBtnClick)
      this.props.onCancelBtnClick();

    this.props.onClose(this.props.value);
  }

  handleChange = (ev, value) => {
    this.setState({
      value: {
        internalName: value,
        publicName: this.props.collection.find(f => f.internalName === value).publicName
      }
    })
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.value !== this.props.value) {
      this.setState({ value: nextProps.value });
    }
  }

  handleEntering = () => {
    this.radioGroupRef.focus();
  };

  render() {
    const radioGroupName = this.props.name || 'selectedValue';

    return (
      <Dialog
        open={this.props.open}
        disableBackdropClick
        onEntering={this.handleEntering}
        maxWidth="xs">
        <DialogTitle>{this.props.title}</DialogTitle>

        <DialogContent>
          <RadioGroup
            ref={ref => {
              this.radioGroupRef = ref;
            }}
            name={radioGroupName}
            value={this.state.value.internalName}
            onChange={this.handleChange}>
            {this.props.collection.map(m => (
              <FormControlLabel
                value={m.internalName}
                key={m.internalName}
                control={<Radio color="primary" />}
                label={m.publicName}
              />
            ))}
          </RadioGroup>
        </DialogContent>

        <DialogActions>
          <Button color="primary" onClick={this.handleCancelClick}>
            {this.props.cancelBtnText || "Cancelar"}
          </Button>

          <Button color="primary" onClick={this.handleAcceptClick}>
            {this.props.acceptBtnText || "Aceptar"}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}