import React from "react";
import { Route } from "react-router-dom";

import PlacesCategories from "./PlacesCategories";
import ManagePlacesCategories from "./ManagePlacesCategories";

function PlacesCategoriesContainer() {
  return (
    <div>
      <Route path="/places/categories" exact component={PlacesCategories} />
      <Route path="/places/categories/create" exact component={ManagePlacesCategories} />
      <Route path="/places/categories/edit/:id" exact component={ManagePlacesCategories} />
    </div>
  );
}

export default PlacesCategoriesContainer;
