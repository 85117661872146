import React from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import { Switch, Route, Redirect, Link } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";

import { primaryColor } from "assets/jss/material-dashboard-react";

import AuthService from "components/Services/AuthService";
import { GLOBAL_ROLES } from "variables/general";
import homeDashboard from "routes/homeDashboard";

import incidentIcon from "assets/img/incident_icon.svg";
import placesIcon from "assets/img/places_icon.svg";
import paymentIcon from "assets/img/payment_icon.svg";
import newsIcon from "assets/img/news_icon.svg";
import garbageTruckIcon from "assets/img/garbage_truck_icon.svg";

const styles = theme => ({
  root: {
    flexGrow: 1,
    height: "auto",
    zIndex: 1,
    overflow: "hidden",
  },
  modules: {
    margin: "0 auto",
    padding: 30,
  },
  paper: {
    height: "100%",
    padding: theme.spacing.unit * 2,
    textAlign: "center",
    color: primaryColor,
    "&:hover": {
      textDecoration: "none",
      backgroundColor: "rgba(0, 0, 0, 0.08)",
    },
  },
  control: {
    padding: theme.spacing.unit * 2,
  },
  icon: {
    width: "100%",
    height: 50,
  },
});

const modules = [
  {
    to: "/incidents",
    icon: incidentIcon,
    label: "Incidentes",
    name: "INCIDENT_MODULE",
  },
  {
    to: "/garbage-pickup",
    icon: garbageTruckIcon,
    label: "Recogida de basura",
    name: "GARBAGE_PICKUP_MODULE",
  },
  {
    to: "/news",
    icon: newsIcon,
    label: "Noticias",
    name: "NEWS_MODULE",
  },
  {
    to: "/users",
    icon: placesIcon,
    label: "Usuarios",
    name: "USERS_MODULE",
  },
  {
    to: "/payments",
    icon: paymentIcon,
    label: "Pagos",
    name: "PAYMENTS_MODULE",
  },
  {
    to: "/places",
    icon: placesIcon,
    label: "Lugares",
    name: "PLACES_MODULE",
  },
];

class HomeDashboard extends React.Component {
  constructor(props) {
    super(props);
    this.AuthService = new AuthService();
  }

  render() {
    if (!this.AuthService.loggedIn()) {
      this.props.history.push("/login");
      return null;
    }

    const { classes } = this.props;
    const authenticated = this.AuthService.loggedIn();
    const profile = this.AuthService.getProfile();

    // In case something fails when returning the profile
    if (profile === null) {
      this.AuthService.logout();
      this.props.history.push('/login');
      return null;
    }

    let allowedModules;

    if (GLOBAL_ROLES.isSuperAdmin(profile.user.role)) {
      allowedModules = modules;
    } else {
      allowedModules = modules.filter(
        f =>
          profile.user &&
          profile.user.modules &&
          profile.user.modules.filter(userModule => {
            return f.name === userModule.name && userModule.status === "ACTIVE";
          }).length > 0
      );
    }

    if (this.props.location.pathname === "/" && !GLOBAL_ROLES.isSuperAdmin(profile.user.role) && allowedModules.length === 1) {
      this.props.history.push(allowedModules[0].to);
    }

    return (
      <div className={classes.root}>
        {this.props.location.pathname === "/" ? (
          <Grid container spacing={16} alignItems="center" direction="row" justify="center" className={classes.modules}>
            {allowedModules.map((prop, key) => {
              return (
                <Grid item xs={12} sm={12} md={3} key={key}>
                  <Link to={prop.to}>
                    <Paper className={classes.paper}>
                      <img alt="icon" src={prop.icon} className={classes.icon} />
                      <h4>{prop.label}</h4>
                    </Paper>
                  </Link>
                </Grid>
              );
            })}
          </Grid>
        ) : (
            <Switch>
              {homeDashboard.map((prop, key) => {
                if (prop.redirect) return <Redirect from={prop.path} to={prop.to} key={key} />;
                else if (prop.private && !authenticated) return <Redirect to="/login" key={key} />;

                return <Route path={prop.path} component={prop.component} key={key} />;
              })}
            </Switch>
          )}
      </div>
    );
  }
}

HomeDashboard.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(HomeDashboard);
