import React from "react";
import PropTypes from "prop-types";

import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import { Checkbox, TextField, FormControlLabel } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { IncidentCategoriesTypes } from "./IncidentCategories";

const styles = theme => ({
  button: {
    margin: theme.spacing.unit
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 400
  }
});

class IncidentCategoryForm extends React.Component {
  constructor(props) {
    super(props);
    let initialForm = {
      name: null,
      addressRequired: false,
      type: IncidentCategoriesTypes.REGULAR.id,
      hidden: false,
    };

    let isEdit = false;
    if (props.form) {
      isEdit = true;
      initialForm = props.form;
    }

    this.state = {
      form: initialForm,
      errorForm: {
        name: false
      },
      isEditForm: isEdit
    };
  }

  handleTextFieldChange = event => {
    const target = event.target;
    const name = target.name;
    const updatedForm = {
      ...this.state.form
    };
    updatedForm[name] = target.value;

    const updatedErrorForm = {
      ...this.state.errorForm
    };
    updatedErrorForm[name] = !target.value || target.value.length === 0;

    this.setState({
      form: updatedForm,
      errorForm: updatedErrorForm
    });
  };

  handleCheckBoxChange = event => {
    const target = event.target;
    const name = target.name;
    const updatedForm = {
      ...this.state.form
    };
    updatedForm[name] = target.checked;

    this.setState({
      form: updatedForm
    });
  };

  handleSubmit = event => {
    event.preventDefault();
    if (this.isFormValid()) {
      const result = {
        ...this.state.form
      };
      this.props.submitHandler(result);
    }
  };

  isFormValid = () => {
    const form = this.state.form;
    const updatedErrorForm = {
      ...this.state.errorForm
    };
    updatedErrorForm.name = !form.name || form.name.length === 0;

    this.setState({
      errorForm: updatedErrorForm
    });
    return !updatedErrorForm.name;
  };

  render() {
    const { classes } = this.props;
    const submitButtonText = this.state.isEditForm ? "Salvar" : "Crear";

    return (
      <div>
        <form
          className={classes.container}
          noValidate
          autoComplete="off"
          onSubmit={this.handleSubmit}
        >
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <TextField
                name="name"
                label="Nombre"
                required
                error={this.state.errorForm.name}
                className={classes.textField}
                value={this.state.form.name}
                onChange={this.handleTextFieldChange}
                margin="normal"
              />
            </GridItem>

            <GridItem xs={12} sm={6} md={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="addressRequired"
                    checked={this.state.form.addressRequired}
                    onChange={this.handleCheckBoxChange}
                  />
                }
                label="Dirección Requerida"
              />
            </GridItem>

            <GridItem xs={12} sm={6} md={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="hidden"
                    checked={this.state.form.hidden}
                    onChange={this.handleCheckBoxChange}
                  />
                }
                label="Ocultar"
              />
            </GridItem>
          </GridContainer>
          <Button
            color="primary"
            className={classes.button}
            style={{ marginTop: "30px" }}
            type="submit"
          >
            {submitButtonText}
          </Button>
          <Button
            color="secondary"
            className={classes.button}
            onClick={this.props.cancelHandler}
            style={{ marginTop: "30px" }}
          >
            Cancelar
          </Button>
        </form>
      </div>
    );
  }
}

IncidentCategoryForm.propTypes = {
  history: PropTypes.object,
  form: PropTypes.object,
  cancelHandler: PropTypes.func.isRequired,
  submitHandler: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired
};
export default withStyles(styles)(IncidentCategoryForm);
