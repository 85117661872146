import React from "react";
import PropTypes from "prop-types";
import { diff } from "deep-object-diff";

import EditUserForm from "./EditUserForm";
import AuthService from "components/Services/AuthService";

class EditUser extends React.Component {
  constructor(props) {
    super(props);
    this.AuthService = new AuthService();
    this.state = {
      form: {
        firstName: this.props.location.state.user.firstName,
        id: this.props.location.state.user.id,
        lastName: this.props.location.state.user.lastName,
        modules: this.props.location.state.user.modules,
        role: this.props.location.state.user.role,
        status: this.props.location.state.user.status,
      },
      currentProfile: this.AuthService.getProfile().user,
    };
  }

  submitHandler = form => new Promise((_resolve, reject) => {
    if (Object.keys(diff(this.state.form, form)).length > 0) {
      this.AuthService.authFetch(`/account/update/${form.id}`, {
        method: "PUT",
        body: JSON.stringify({
          firstName: form.firstName,
          lastName: form.lastName,
          modules: form.modules,
          role: form.role,
          status: form.status,
        }),
      }).then(() => {
        this.props.history.goBack();
      }).catch(() => reject({
        warningSnack: {
          open: true,
          content: 'Ocurrió un error actualizando el usuario'
        }
      }));
    }
    else
      reject({
        warningSnack: {
          open: true,
          content: 'No hay nada que actualizar'
        }
      })
  });

  cancelHandler = () => {
    this.props.history.goBack();
  };

  render() {
    return <EditUserForm
      form={this.state.form}
      submitHandler={this.submitHandler}
      cancelHandler={this.cancelHandler}
      currentProfile={this.state.currentProfile}
    />;
  }
}

EditUser.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default EditUser;
