import React from "react";
import PropTypes from "prop-types";
import uuid from "uuid";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, TimePicker } from "material-ui-pickers";

import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import Button from "@material-ui/core/Button";
import NativeSelect from "@material-ui/core/NativeSelect";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import FormControl from "@material-ui/core/FormControl";

import AuthService from "components/Services/AuthService";

const styles = theme => ({
  button: {
    margin: theme.spacing.unit,
  },
  textField: {},
});

class GarbagePickupForm extends React.Component {
  constructor(props) {
    super(props);
    let initialForm = {
      name: "monday",
      startTime: "08:00",
      endTime: "00:00",
    };

    let isEdit = false;
    if (props.form) {
      isEdit = true;
      initialForm = props.form;
    }

    this.state = {
      form: initialForm,
      errorForm: {
        name: false,
      },
      isEditForm: isEdit,
    };

    this.AuthService = new AuthService();
  }

  handleChange = name => event => {
    const target = event.target;
    const updatedForm = {
      ...this.state.form,
    };
    updatedForm[name] = target.value;

    const updatedErrorForm = {
      ...this.state.errorForm,
    };
    updatedErrorForm[name] = !target.value || target.value.length === 0;

    this.setState({
      form: updatedForm,
      errorForm: updatedErrorForm,
    });
  };

  handleSubmit = event => {
    event.preventDefault();

    if (this.isFormValid()) {
      const result = {
        ...this.state.form,
      };
      this.props.submitHandler(result);
    }
  };

  isFormValid = () => {
    const form = this.state.form;
    const updatedErrorForm = {
      ...this.state.errorForm,
    };
    updatedErrorForm.name = !form.name || form.name.length === 0;

    this.setState({
      errorForm: updatedErrorForm,
    });
    return !updatedErrorForm.name;
  };

  handleDateChange = stateProp => date => {
    const updatedState = { ...this.state.form };
    const hours = date.getHours() < 10 ? `0${date.getHours()}` : date.getHours();
    const minutes = date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes();

    updatedState[stateProp] = `${hours}:${minutes}`;

    this.setState({ form: updatedState });
  };

  render() {
    const { classes } = this.props;
    const submitButtonText = "Salvar";
    const startTimeArr = this.state.form.startTime.split(":");
    const startTimeHours = startTimeArr[0];
    const startTimeMinutes = startTimeArr[1];
    const endTimeArr = this.state.form.endTime.split(":");
    const endTimeHours = endTimeArr[0];
    const endTimeMinutes = endTimeArr[1];

    return (
      <div>
        <form className={classes.container} noValidate autoComplete="off" onSubmit={this.handleSubmit}>
          <GridContainer>
            {this.state.isEditForm ? (
              <GridItem xs={12} sm={12} md={12}>
                <Button color="secondary" className={classes.button} onClick={this.props.deleteHandler(this.state.form.id)} style={{ marginTop: "30px" }}>
                  Eliminar
                </Button>
              </GridItem>
            ) : (
              false
            )}
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <GridItem xs={12} sm={12} md={12}>
                <List>
                  <ListItem key={uuid()} role={undefined} dense>
                    <FormControl>
                      <InputLabel htmlFor="day-selector-id">Nombre</InputLabel>
                      <NativeSelect value={this.state.form.name} onChange={this.handleChange("name")} input={<Input name="day" id="day-selector-id" />}>
                        <option value="" />
                        <option value={"monday"}>Lunes</option>
                        <option value={"tuesday"}>Martes</option>
                        <option value={"wednesday"}>Miércoles</option>
                        <option value={"thursday"}>Jueves</option>
                        <option value={"friday"}>Viernes</option>
                        <option value={"saturday"}>Sábado</option>
                        <option value={"sunday"}>Domingo</option>
                      </NativeSelect>
                    </FormControl>
                  </ListItem>
                  <ListItem key={uuid()} role={undefined} dense>
                    <FormControl>
                      <TimePicker ampm={false} label="Hora inicial" minutesStep={10} value={new Date().setHours(startTimeHours, startTimeMinutes, 0)} onChange={this.handleDateChange("startTime")} />
                    </FormControl>
                  </ListItem>
                  <ListItem key={uuid()} role={undefined} dense>
                    <FormControl>
                      <TimePicker ampm={false} label="Hora final" minutesStep={10} value={new Date().setHours(endTimeHours, endTimeMinutes, 0)} onChange={this.handleDateChange("endTime")} />
                    </FormControl>
                  </ListItem>
                </List>
              </GridItem>
            </MuiPickersUtilsProvider>
          </GridContainer>
          <Button color="primary" className={classes.button} style={{ marginTop: "30px" }} type="submit">
            {submitButtonText}
          </Button>
          <Button color="secondary" className={classes.button} onClick={this.props.cancelHandler} style={{ marginTop: "30px" }}>
            Cancelar
          </Button>
        </form>
      </div>
    );
  }
}

GarbagePickupForm.propTypes = {
  history: PropTypes.object,
  form: PropTypes.object,
  cancelHandler: PropTypes.func.isRequired,
  submitHandler: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(GarbagePickupForm);
