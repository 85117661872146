import React from "react";
import { Route } from "react-router-dom";
import Places from "./Places";
import ManagePlaces from "./ManagePlaces";

function PlacesContainer() {
  return (
    <div>
      <Route path="/places/entries" exact component={Places} />
      <Route path="/places/entries/create" exact component={ManagePlaces} />
      <Route path="/places/entries/edit/:id" exact component={ManagePlaces} />
    </div>
  );
}

export default PlacesContainer;
