import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Hidden from "@material-ui/core/Hidden";
// @material-ui/icons
import Menu from "@material-ui/icons/Menu";
// core components
import Button from "components/CustomButtons/Button";

import headerStyle from "assets/jss/material-dashboard-react/components/headerStyle.jsx";
import { Badge } from "@material-ui/core";


const customStyles = {
  ...headerStyle(),
  headerBurgerBadge: {
    right: 15,
    top: 15,
    width: 8,
    height: 8,
    borderRadius: '50%',
  }
}

function Header({ ...props }) {
  function makeBrand() {
    var name;
    props.routes.map(prop => {
      if (prop.path === props.location.pathname) {
        name = prop.navbarName;
      }
      return null;
    });
    return name;
  }
  const { classes, color, hideBadge } = props;
  const appBarClasses = classNames({
    [` ${classes[color]}`]: color,
  });
  return (
    <AppBar className={classes.appBar + appBarClasses}>
      <Toolbar className={classes.container}>
        <div className={classes.flex}>
          {/* Here we create navbar brand, based on route name */}
          <Button color="transparent" className={classes.title}>
            {makeBrand()}{''}
          </Button>
        </div>
        <Hidden mdUp implementation="css">
          <Badge variant='dot' color='secondary' invisible={hideBadge} classes={{ dot: classes.headerBurgerBadge }}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={props.handleDrawerToggle}>
              <Menu />
            </IconButton>
          </Badge>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
}

Header.propTypes = {
  classes: PropTypes.object.isRequired,
  color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger"]),
  hideBadge: PropTypes.bool,
};

export default withStyles(customStyles)(Header);
