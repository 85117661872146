import firebase from "firebase";
import AuthService from "components/Services/AuthService";

let firebaseConfig = {
  apiKey: "AIzaSyCikjDKpSEyaacewY9FdZ4AWjms_3sbPpY",
  authDomain: "city-59fe8.firebaseapp.com",
  databaseURL: "https://city-59fe8.firebaseio.com",
  projectId: "city-59fe8",
  storageBucket: "city-59fe8.appspot.com",
  messagingSenderId: "1082518631562",
};

if (process.env.REACT_APP_NODE_ENV === "production") {
  firebaseConfig = {
    apiKey: "AIzaSyDtvD99SRZaP_4Gdv1s_ScgP4cFu1o39_4",
    authDomain: "cityapp-1cd61.firebaseapp.com",
    databaseURL: "https://cityapp-1cd61.firebaseio.com",
    projectId: "cityapp-1cd61",
    storageBucket: "cityapp-1cd61.appspot.com",
    messagingSenderId: "530637949869",
  };
}

// eslint-disable-next-line no-console
console.log(process.env.REACT_APP_NODE_ENV);

firebase.initializeApp(firebaseConfig);

export default firebase;
export const config = firebaseConfig;
export const auth = firebase.auth();
export const storage = firebase.storage();

const authService = new AuthService();

// ((256B * 1024B = 256KB) * 4 = 1MB) * 10 = 10MB
const chunkSize = 256 * 1024 * 4 * 10;

export const uploadFile = async (filePath, file) => {
  console.log('Uploading whole file...');

  const formData = new FormData();
  formData.append('file', file);
  formData.append('path', filePath);

  const response = await authService.authFetch('/admin/util/file/upload', {
    method: 'POST',
    body: formData,
    headers: {
      'Content-Type': null,
    },
  });

  return response.result;
};

export const uploadFileChunks = (filePath, file, options) => {
  const fileSize = file.size;

  if (fileSize < chunkSize)
    return uploadFile(filePath, file);

  if (options.hooks && options.hooks.beforeRequest)
    try {
      options.hooks.beforeRequest.callback(file, options.hooks.beforeRequest.payload);
    }
    catch (ex) { console.error('Hook "beforeRequest" failed internally:', ex); }

  console.log('Uploading file in chunks...');

  const chunkSender = (start, end, extra = {}) => new Promise((resolve, reject) => {
    if (end > fileSize)
      end = fileSize;

    let cancelUpload;
    const chunk = file.slice(start, end);

    const formData = new FormData();
    formData.append('path', filePath);
    formData.append('mimeType', file.type);
    formData.append('start', start);
    formData.append('end', end);
    formData.append('totalSize', fileSize);

    if (extra.uploadToken)
      formData.append('uploadToken', extra.uploadToken);

    if (extra.cancelUpload)
      formData.append('cancelUpload', true);
    else
      formData.append('file', chunk);

    authService.authFetch('/admin/util/file/upload/resumable', {
      method: 'POST',
      body: formData,
      headers: {
        'Content-Type': null,
      },
    }).then(resp => {
      if (options.hooks && options.hooks.afterEveryRequest)
        try {
          const afterHookResponse = options.hooks.afterEveryRequest.callback(file, resp, options.hooks.afterEveryRequest.payload);
          cancelUpload = afterHookResponse && afterHookResponse.cancelUpload === true;
        }
        catch (ex) { console.error('Hook "afterEveryRequest" failed internally:', ex); }

      if (resp.result.canceled)
        return reject(null);

      if (resp.result.fileURI)
        console.log('Finished uploading chunks');
      else
        console.log('Chunk uploaded:', resp.result.range);

      const lastByte = resp.result.range ? +resp.result.range.match(/\d+$/)[0] : null;

      if (end < fileSize) {
        const startingPoint = (lastByte !== null ? lastByte : start + chunkSize) + 1;
        chunkSender(startingPoint, startingPoint + chunkSize, {
          uploadToken: resp.result.uploadToken,
          cancelUpload: cancelUpload,
        })
          .then(result => {
            resolve(result);
          })
          .catch(reason => {
            reject(reason);
          });
      }
      else
        resolve(resp.result.fileURI);
    }).catch(reason => {
      console.log('Failed uploading chunk:', reason);
      reject(reason);
    });
  });

  return chunkSender(0, chunkSize);
};

export const getFileURL = async fileURI => {
  return storage.refFromURL(fileURI)
    .getDownloadURL();
};

export const removeFile = async fileURI => {
  const response = await authService.authFetch('/admin/util/file/delete', {
    method: 'DELETE',
    body: JSON.stringify({ URI: fileURI }),
  });

  return response.result;
};