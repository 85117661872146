import React from "react";
import { Grid, withStyles, TextField, Snackbar, FormControl, FormLabel, FormGroup, FormControlLabel, Checkbox } from "@material-ui/core";

import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import Button from "components/CustomButtons/Button.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import Select from "components/Select/Select.jsx";
import CustomFileSelector from "components/CustomInput/CustomFileSelector";
import CustomScheduleSelector from "components/CustomInput/CustomScheduleSelector";
import CustomMap from "components/CustomMap/CustomMap";
import { ProgressLoader } from "components/CustomLoader/Loaders";
// import CustomMaskedInput from "components/CustomInput/CustomMaskedInput";
import CircularProgress from "@material-ui/core/CircularProgress";
import CheckIcon from '@material-ui/icons/Check';
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

import { GENERIC_STATUS } from "variables/general";
import { primaryColor } from "assets/jss/material-dashboard-react.jsx";
import not_found_sm from "assets/img/not_found_sm.png";
import AuthService from "components/Services/AuthService";
import { uploadFileChunks, getFileURL, removeFile } from "utils/Firebase";


const styles = theme => ({
  rootContent: {
    position: "relative",
  },
  coverLoading: {
    position: "absolute",
    height: "100%",
    width: "100%",
    zIndex: 1,
    backgroundColor: "#ddd",
    opacity: 0.3,
  },
  progress: {
    color: primaryColor,
    position: "absolute",
    top: "calc(50% - 4em)",
    left: "calc(50% - 4em)",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: '100%',
    boxSizing: 'border-box',
    paddingRight: theme.spacing.unit * 2,
  },
  selectContent: {
    margin: `${theme.spacing.unit * 3}px ${theme.spacing.unit}px
    ${theme.spacing.unit * 3}px ${theme.spacing.unit}px`,
  },
  dividerSecction: {
    height: "48px",
    borderBottom: "1px solid rgba(224, 224, 224, 1)",
    backgroundColor: "#eeeeee50",
    padding: `0 ${theme.spacing.unit * 3}px ${theme.spacing.unit}px ${theme.spacing.unit * 3}px`,
    marginBottom: theme.spacing.unit,
  },
  title: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
    marginBottom: theme.spacing.unit,
    paddingTop: theme.spacing.unit * 3,
    color: primaryColor,
  },
  notiSnack: {
    backgroundColor: '#eee',

    '& #noti-message': {
      display: 'flex',
      color: 'black',

      '& #noti-message-icon': {
        fontSize: 20,
        opacity: 0.9,
        marginRight: theme.spacing.unit,
        color: 'green',
      }
    }
  },
  close: {
    width: theme.spacing.unit * 4,
    height: theme.spacing.unit * 4,
    color: 'darkgray',
  },
  servicesArea: {
    width: '100%',
    marginTop: theme.spacing.unit * 5,
  },
  servicesAreaTitle: {
    marginBottom: theme.spacing.unit * 2,
  },
  servicesAreaContent: {
    marginBottom: theme.spacing.unit * 5,
  },
});

class ManagePlaces extends React.Component {
  constructor(props) {
    super(props);

    this.AuthService = new AuthService();
    this.action = props.location.pathname.includes('create') ? 'create' : 'edit';
    this.placeID = props.match.params.id;

    this.loadedAsyncBoard = {
      categories: false,
      place: this.action === 'create',
      media: this.action === 'create',
    };

    this.serviceMapper = {
      bicycleZone: 'Ciclovía',
      exerciseZone: 'Zona de ejercicios',
      childrensZone: 'Zona de niños',
      family: 'Zona familiar',
      security: 'Zona segura',
      toilets: 'Zona con baños',
      cleaning: 'Zona limpia',
      restaurant: 'Zona con restaurante',
    };

    this.defaultLocation = { lat: 18.483402, lng: -69.929611 };
    this.firebaseURIMapper = {};

    this.state = {
      loading: true,
      snackError: {
        open: false,
        payload: null,
      },
      notiSnack: {
        open: false,
        payload: null,
      },
      place: {},
      categories: [],
      images: [],
      videos: [],
      form: {
        name: '',
        address: '',
        phoneNumber: '',
        category: null,
        aboutTitle: '',
        about: '',
        status: true,
        services: {
          bicycleZone: false,
          exerciseZone: false,
          childrensZone: false,
          family: false,
          security: false,
          toilets: false,
          cleaning: false,
          restaurant: false,
        },
        schedule: [],
      },
      formError: {
        name: false,
        address: false,
        category: false,
        schedule: false,
      },
      mapDialog: {
        open: false,
        location: null,
      },
      selectedImages: [],
      selectedVideo: null,
      uploadProgress: {
        images: [],
        videos: [],
        cancel: false,
      },
    };
  }

  componentDidMount() {
    const getCategories = () => this.AuthService.authFetch("/admin/places/categories?showDisabled=false")
      .then(res => {
        this.loadedAsyncBoard.categories = true;
        const { place } = this.state;

        const newState = {
          categories: res.result.map(m => ({
            value: m.id,
            label: m.headerTitle,
          })),
        };

        if (this.allAsyncProcessesDone())
          newState.loading = false;

        if (this.action === 'edit') {
          newState.form = {
            name: place.name,
            address: place.address,
            phoneNumber: place.phoneNumber || '',
            category: newState.categories
              .find(f => f.value === place.category),
            aboutTitle: place.aboutTitle,
            about: place.about,
            services: { ...place.services },
            schedule: place.schedule,
            status: place.status === GENERIC_STATUS.ACTIVE,
          };

          newState.mapDialog = {
            open: false,
            location: {
              lat: place.coordinates.latitude,
              lng: place.coordinates.longitude,
            },
          };
        }

        this.setState(newState);
      })
      .catch(reason => {
        console.log('Failed to load categories:', reason);
        this.loadedAsyncBoard.categories = true;

        const newState = {
          snackError: {
            open: true,
            payload: 'Ocurrió un error cargando las categorías de los lugares... intente nuevamente más tarde',
          },
        };

        if (this.allAsyncProcessesDone())
          newState.loading = false;

        this.setState(newState);
      });

    if (this.action === 'edit')
      this.AuthService.authFetch(`/admin/places/${this.placeID}`)
        .then(res => {
          this.loadedAsyncBoard.place = true;

          const newState = {
            place: res.result,
          };

          if (res.result.videoUrl && res.result.videoUrl.indexOf('/') !== -1) {
            const fileName = res.result.videoUrl.substr(res.result.videoUrl.lastIndexOf('/') + 1);
            let pseudoVideoName;

            if (fileName.match(/^\d+_\d+_.+/))
              pseudoVideoName = fileName.replace(/^\d+_\d+_/, '');
            else
              pseudoVideoName = fileName;

            newState.selectedVideo = {
              file: {
                name: pseudoVideoName,
              }
            };
          }

          this.setState(newState);

          getCategories();
          this.getImagesURLs();
        })
        .catch(reason => {
          console.log('Failed to load place:', reason);
          this.loadedAsyncBoard.place = true;

          this.setState({
            loading: false,
            snackError: {
              open: true,
              payload: 'Ocurrió un error cargando el lugar... intente nuevamente más tarde',
            },
          });
        });
    else
      getCategories();
  }

  getImagesURLs = (initialRequest = true) => {
    const { place } = this.state;

    Promise.all((place.images || []).map(m => new Promise((resolve, reject) => {
      getFileURL(m)
        .then(url => {
          resolve({
            URI: m,
            URL: url,
            found: true,
          });
        })
        .catch(reason => {
          if (reason.code === 'storage/object-not-found')
            resolve({
              URI: m,
              URL: m,
              found: false,
            });

          reject(reason);
        });
    })))
      .then(obj => {
        this.loadedAsyncBoard.media = true;

        const newState = {
          selectedImages: obj.map(m => ({
            url: m.found ? m.URL : not_found_sm,
          })),
        };

        if (!initialRequest || this.allAsyncProcessesDone())
          newState.loading = false;

        this.firebaseURIMapper = {};

        obj.forEach(f => {
          if (f.found)
            this.firebaseURIMapper[f.URL] = f.URI;
          else if (this.firebaseURIMapper[not_found_sm])
            this.firebaseURIMapper[not_found_sm].push({
              [f.URL]: f.URI,
            })
          else
            this.firebaseURIMapper[not_found_sm] = [{
              [f.URL]: f.URI,
            }];
        });

        this.setState(newState);
      })
      .catch(reason => {
        console.log('Failed to load images\' URLs:', reason);
        this.loadedAsyncBoard.media = true;

        const newState = {
          snackError: {
            open: true,
            payload: 'Ocurrió un error cargando las imágenes del lugar... intente nuevamente más tarde',
          },
        };

        if (!initialRequest || this.allAsyncProcessesDone())
          newState.loading = false;

        this.setState(newState);
      });
  };

  allAsyncProcessesDone = () => {
    return Object.keys(this.loadedAsyncBoard).every(e => this.loadedAsyncBoard[e]);
  }

  handleInputChange = name => event => {
    const { form, formError } = { ...this.state };
    const notEventfulFields = ['category', 'status', 'schedule'];

    if (name.includes('.')) {
      const namespace = name.split('.');
      form[namespace[0]][namespace[1]] = !!event.target.checked;
    }
    else if (notEventfulFields.includes(name))
      form[name] = event;
    else
      form[name] = (event.target.value || '');

    if (Object.keys(formError).includes(name))
      formError[name] = !form[name];

    const newState = { form, formError };

    this.setState(newState);
  };

  validation = () => {
    const { form, formError, images, mapDialog, selectedImages } = { ...this.state };
    let payloadMsg;

    const newState = {};

    const fieldName = {
      name: 'Nombre',
      address: 'Dirección',
      category: 'Categoría',
      schedule: 'Horarios',
    };

    Object.keys(form).forEach(f => {
      if (typeof form[f] === 'string')
        form[f] = form[f].trim();

      if (Object.keys(formError).includes(f))
        formError[f] = !form[f];
    });

    newState.form = form;
    newState.formError = formError;

    const someError = Object.keys(formError).some(s => {
      if (formError[s])
        payloadMsg = `El campo "${fieldName[s] || s}" debe tener un valor válido`;

      return formError[s];
    }) || ['location', 'images'].some(s => {
      switch (s) {
        case 'location':
          if (!mapDialog.location) {
            payloadMsg = 'Debe asignar la ubicación del lugar';
            return true;
          }
          break;

        case 'images':
          if (!images.length && !selectedImages.length) {
            payloadMsg = 'Debe agregar al menos una imagen';
            return true;
          }
          break;

        default:
          payloadMsg = 'El formulario está incompleto';
          return true;
      }

      return false;
    });

    if (someError)
      newState.snackError = {
        open: true,
        payload: payloadMsg,
      };
    else
      newState.loading = true;

    this.setState(newState);

    return someError;
  };

  savePlace = payload => {
    this.AuthService.authFetch(this.action === 'create' ?
      '/admin/places' :
      `/admin/places/${this.placeID}?booleanResponse=false`, {
        method: this.action === 'create' ? 'POST' : 'PUT',
        body: JSON.stringify(payload),
      })
      .then(res => {
        if (res.result === true || Object.keys(res.result).length) {
          if (this.action === 'create')
            this.props.history.push('/places/entries');
          else {
            const { categories } = this.state;
            const newState = {
              place: { ...res.result },
              notiSnack: {
                open: true,
                payload: 'El lugar fue actualizado',
              },
              form: {
                name: res.result.name,
                address: res.result.address,
                phoneNumber: res.result.phoneNumber || '',
                category: categories
                  .find(f => f.value === res.result.category),
                aboutTitle: res.result.aboutTitle,
                about: res.result.about,
                services: { ...res.result.services },
                schedule: res.result.schedule,
                status: res.result.status === GENERIC_STATUS.ACTIVE,
              },
              uploadProgress: {
                images: [],
                videos: [],
                cancel: false,
              },
            };

            const newImagesFound = res.result.images.some(s => !Object.values(this.firebaseURIMapper).includes(s));

            if (!newImagesFound) {
              newState.loading = false;

              const reverseFirebaseMapper = Object.keys(this.firebaseURIMapper)
                .reduce((o, e) => {
                  if (e === not_found_sm)
                    Object.keys(this.firebaseURIMapper[e]).forEach(f => o[f] = e);
                  else
                    o[this.firebaseURIMapper[e]] = e;

                  return o;
                }, {});

              newState.selectedImages = res.result.images
                .map(m => ({
                  url: reverseFirebaseMapper[m],
                }));

              this.firebaseURIMapper = {};
              res.result.images.forEach(f => {
                if (reverseFirebaseMapper[f] !== not_found_sm)
                  this.firebaseURIMapper[reverseFirebaseMapper[f]] = f;
                else if (this.firebaseURIMapper[not_found_sm])
                  this.firebaseURIMapper[not_found_sm].push({
                    [f]: f,
                  });
                else
                  this.firebaseURIMapper[not_found_sm] = [{
                    [f]: f,
                  }];
              });
            }

            if (res.result.videoUrl && res.result.videoUrl.indexOf('/') !== -1) {
              const fileName = res.result.videoUrl.substr(res.result.videoUrl.lastIndexOf('/') + 1);
              let pseudoVideoName;

              if (fileName.match(/^\d+_\d+_.+/))
                pseudoVideoName = fileName.replace(/^\d+_\d+_/, '');
              else
                pseudoVideoName = fileName;

              newState.selectedVideo = {
                file: {
                  name: pseudoVideoName,
                }
              };
            }

            this.setState(newState);

            if (newImagesFound)
              this.getImagesURLs(false);
          }
        }
        else {
          const { images, selectedImages, videos, selectedVideo } = this.state;

          this.setState({
            loading: false,
            uploadProgress: {
              images: [],
              videos: [],
              cancel: false,
            },
            snackError: {
              open: true,
              payload: 'Ocurrió un error guardando el lugar... intente nuevamente más tarde',
            },
            selectedImages: images.length ? images
              .filter(f => !(f instanceof File))
              .concat(
                images
                  .filter(f => f instanceof File)
                  .map(m => ({
                    url: URL.createObjectURL(m),
                  }))
              ) : selectedImages,
            selectedVideo: videos.length ? videos
              .reduce((o, e) => {
                o.file = {
                  name: e.name,
                };

                return o;
              }, {}) :
              selectedVideo,
          });
        }
      })
      .catch(reason => {
        console.log('Failed to save place:', reason);
        const { images, selectedImages, videos, selectedVideo } = this.state;

        this.setState({
          loading: false,
          uploadProgress: {
            images: [],
            videos: [],
            cancel: false,
          },
          snackError: {
            open: true,
            payload: `Ocurrió un error ${{ create: 'creando', edit: 'editando' }[this.action]} el lugar... intente nuevamente más tarde`,
          },
          selectedImages: images.length ? images
            .filter(f => !(f instanceof File))
            .concat(
              images
                .filter(f => f instanceof File)
                .map(m => ({
                  url: URL.createObjectURL(m),
                }))
            ) : selectedImages,
          selectedVideo: videos.length ? videos
            .reduce((o, e) => {
              o.file = {
                name: e.name,
              };

              return o;
            }, {}) :
            selectedVideo,
        });
      });
  };

  handleSave = () => {
    const { form, images, videos, mapDialog, place, selectedVideo } = { ...this.state };

    const foundErrors = this.validation();

    if (foundErrors)
      return;

    const payload = {
      ...form,
      coordinates: [
        mapDialog.location.lat,
        mapDialog.location.lng,
      ],
      phoneNumber: form.phoneNumber || undefined,
      about: form.about || undefined,
      aboutTitle: form.aboutTitle || undefined,
      category: form.category.value,
    };

    const fileBundle = images
      // .filter(f => f instanceof File)
      .map((m, i) => ({
        group: 'images',
        file: m instanceof File ? m : typeof this.firebaseURIMapper[m.url] === 'string' ? this.firebaseURIMapper[m.url] : null,
        promise: null,
        callback: url => {
          if (!payload.images) {
            payload.images = [];

            for (let x = 0; x < images.length; x++)
              payload.images.push(null);
          }

          payload.images[i] = url;
        },
      }))
      .concat(
        videos
          .filter(f => f instanceof File)
          .map(m => ({
            group: 'videos',
            file: m,
            promise: null,
            callback: url => {
              payload.videoUrl = url;

              this.setState({
                videos: [{
                  name: m.name,
                  fileURI: url,
                }]
              });
            },
          }))
      )
      .map((m, i) => ({
        ...m,
        path: `placesImages/${form.name}/${i}_${Date.now()}_${m.file.name}`,
        beforeStartCallback: (file, payload) => {
          const { uploadProgress } = { ...this.state };
          const entry = {
            name: file.name,
            percentage: 0,
          };

          uploadProgress[payload.group].push(entry);

          this.setState({
            uploadProgress,
          });
        },
        progressCallback: (file, resp, payload) => {
          const { uploadProgress } = { ...this.state };
          const entry = {
            name: file.name,
            percentage: resp.result.percentage,
          };

          const existingEntry = uploadProgress[payload.group].find(f => f.name === entry.name);

          if (existingEntry)
            existingEntry.percentage = entry.percentage;
          else
            uploadProgress[payload.group].push(entry);

          this.setState({
            uploadProgress,
          });

          return {
            cancelUpload: uploadProgress.cancel,
          };
        },
      }))
      .sort((current, next) => {
        if ((next.file || {}).size > (current.file || {}).size)
          return 1;
        else if ((next.file || {}).size < (current.file || {}).size)
          return -1;

        return 0;
      });

    if (this.action === 'edit') {
      if (!images.length)
        payload.images = place.images;
      // else
      //   payload.images = images
      //     .filter(f => !(f instanceof File))
      //     .map(m => typeof this.firebaseURIMapper[m.url] === 'string' ? this.firebaseURIMapper[m.url] : null)
      //     .filter(f => f);

      if (!videos.length) {
        if (place.videoUrl && selectedVideo)
          payload.videoUrl = place.videoUrl;
      }
      else if (!(videos[0] instanceof File))
        payload.videoUrl = videos[0].fileURI;

      // if (!payload.images.length)
      //   return this.setState({
      //     loading: false,
      //     snackError: {
      //       open: true,
      //       payload: 'Debe agregar una imagen válida',
      //     },
      //   });
    }

    const promiseQueue = (array, inx) => new Promise((resolve2, reject2) => {
      if (inx >= array.length)
        return resolve2();

      const item = array[inx];

      if (!(item.file instanceof File)) {
        item.callback(item.file);

        if (inx < array.length)
          promiseQueue(array, inx + 1)
            .then(() => {
              resolve2();
            })
            .catch(reason => {
              reject2(reason);
            });
        else
          resolve2();

        return;
      }

      item.promise = new Promise((resolve, reject) => {
        uploadFileChunks(item.path, item.file, {
          hooks: {
            afterEveryRequest: {
              callback: item.progressCallback,
              payload: { group: item.group },
            },
            beforeRequest: {
              callback: item.beforeStartCallback,
              payload: { group: item.group },
            },
          },
        })
          .then(url => {
            item.callback(url);
            resolve();
          })
          .catch(reason => {
            reject(reason);
          });
      });

      item.promise
        .then(() => {
          if (inx < array.length)
            promiseQueue(array, inx + 1)
              .then(() => {
                resolve2();
              })
              .catch(reason => {
                reject2(reason);
              });
          else
            resolve2();
        })
        .catch(reason => reject2(reason));
    });

    promiseQueue(fileBundle, 0)
      .then(() => {
        if (this.action === 'edit') {
          const { selectedVideo, selectedImages, images, place } = this.state;

          payload.images = payload.images.filter(f => f);

          if (!payload.images.length)
            return this.setState({
              loading: false,
              snackError: {
                open: true,
                payload: 'No hay imágenes que guardar... si el problema persiste, recargue la página',
              },
            });

          const perseveredImages = selectedImages.length ? selectedImages : images.filter(f => !(f instanceof File));
          const removedURIs = place.images
            .filter(f => perseveredImages.findIndex(item => this.firebaseURIMapper[item.url] === f) === -1)
            .concat(!selectedVideo && place.videoUrl ? [place.videoUrl] : []);

          Promise.all(removedURIs.map(m => removeFile(m)))
            .then(() => {
              console.log('Unnecessary files removed');
            })
            .catch(reason => {
              console.log('Failed removing unnecessary files:', reason);
            });
        }

        this.savePlace(payload);
      })
      .catch(reason => {
        console.log('Failed saving place:', reason !== null ? reason : 'User canceled');
        const { images, videos, selectedImages, selectedVideo } = this.state;

        this.setState({
          loading: false,
          uploadProgress: {
            images: [],
            videos: [],
            cancel: false,
          },
          snackError: {
            open: true,
            payload: reason !== null ?
              `Ocurrió un problema ${{ create: 'creando', edit: 'editando' }[this.action]} el lugar... intente nuevamente más tarde` :
              'Se canceló la carga de archivos',
          },
          selectedImages: images.length ? images
            .filter(f => !(f instanceof File))
            .concat(
              images
                .filter(f => f instanceof File)
                .map(m => ({
                  url: URL.createObjectURL(m),
                }))
            ) : selectedImages,
          selectedVideo: videos.length ? videos
            .reduce((o, e) => {
              o.file = {
                name: e.name,
              };

              return o;
            }, {}) :
            selectedVideo,
        });
      });
  };

  onNotiSnackClose = (_event, reason) => {
    if (reason === "clickaway")
      return;

    this.setState({
      notiSnack: {
        open: false,
        payload: null,
      }
    });
  };

  render() {
    const {
      loading,
      form,
      formError,
      categories,
      snackError,
      notiSnack,
      images,
      mapDialog,
      selectedImages,
      selectedVideo,
      uploadProgress,
    } = this.state;

    const { classes } = this.props;

    if (loading)
      return (
        <div>
          <CircularProgress className={classes.progress} size={50} />

          <ProgressLoader
            open={!!(uploadProgress.images.length || uploadProgress.videos.length)}
            payload={uploadProgress.images.concat(uploadProgress.videos)}
            disabledCancelButton={uploadProgress.cancel}
            onCancelClicked={() => {
              this.setState({
                uploadProgress: {
                  ...uploadProgress,
                  cancel: true,
                }
              });
            }}
          />
        </div>
      );

    return (
      <Grid container>
        <Grid item xs={12} sm={12} md={12}>
          <Grid container>
            <Grid item xs={12} sm={12} md={12}>
              <Card>
                <CardHeader color="primary">
                  <h4 className={classes.cardTitleWhite}>{{ create: 'Agregar lugar', edit: 'Editar lugar' }[this.action] || this.action}</h4>
                </CardHeader>

                <CardBody>
                  <div className={classes.dividerSecction}>
                    <h4 className={classes.title}>Información general</h4>
                  </div>

                  <Grid container>
                    <Grid item xs={12} sm={12} md={12}>
                      <TextField
                        id="name"
                        className={classes.textField}
                        label="Nombre"
                        required
                        margin="normal"
                        error={formError.name}
                        value={form.name}
                        onChange={this.handleInputChange("name")}
                        autoComplete='off'
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={12}>
                      <TextField
                        id="address"
                        className={classes.textField}
                        label="Dirección"
                        required
                        margin="normal"
                        error={formError.address}
                        value={form.address}
                        onChange={this.handleInputChange("address")}
                        autoComplete='off'
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={12}>
                      <TextField
                        id="phoneNumber"
                        className={classes.textField}
                        label="Teléfono"
                        margin="normal"
                        value={form.phoneNumber}
                        onChange={this.handleInputChange("phoneNumber")}
                        autoComplete='off'
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={12}>
                      <div className={classes.selectContent}>
                        <Select
                          options={categories}
                          onChange={this.handleInputChange('category')}
                          error={formError.category}
                          value={form.category}
                          placeholder='Seleccione la categoría'
                        />
                      </div>
                    </Grid>

                    <Grid item xs={12} sm={12} md={12}>
                      <TextField
                        id="aboutTitle"
                        className={classes.textField}
                        label="Título del detalle"
                        margin="normal"
                        value={form.aboutTitle || ''}
                        onChange={this.handleInputChange("aboutTitle")}
                        autoComplete='off'
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={12}>
                      <TextField
                        id="about"
                        className={classes.textField}
                        label="Detalle"
                        rowsMax={5}
                        margin="normal"
                        value={form.about || ''}
                        onChange={this.handleInputChange("about")}
                        autoComplete='off'
                        multiline
                      />
                    </Grid>

                    <FormControl className={classes.servicesArea}>
                      <FormLabel className={classes.servicesAreaTitle}>
                        Seleccione los servicios
                      </FormLabel>

                      <FormGroup className={classes.servicesAreaContent}>
                        <Grid container>
                          {Object.keys(form.services).map((m, i) => (
                            <Grid key={i} item xs={12} sm={6} md={6}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={form.services[m]}
                                    onChange={this.handleInputChange(`services.${m}`)}
                                    color="primary"
                                    inputProps={{
                                      'aria-label': this.serviceMapper[m],
                                    }}
                                  />
                                }
                                label={this.serviceMapper[m]}
                              />
                            </Grid>
                          ))}
                        </Grid>
                      </FormGroup>
                    </FormControl>

                    <Grid item xs={12} sm={12} md={12}>
                      <Button
                        color={mapDialog.location ? 'primary' : undefined}
                        onClick={() => {
                          this.setState({
                            mapDialog: {
                              ...this.state.mapDialog,
                              open: true,
                            }
                          });
                        }}>
                        {mapDialog.location ? 'Cambiar la ubicación' : 'Seleccione la ubicación'}
                      </Button>

                      {mapDialog.open && (
                        <CustomMap
                          title="Seleccione la ubicación del lugar"
                          open
                          defaultLocation={mapDialog.location || this.defaultLocation}
                          closeDialog={location => {
                            this.setState({
                              mapDialog: {
                                open: false,
                                location: location,
                              }
                            });
                          }}
                          handleCancel={() => {
                            this.setState({
                              mapDialog: {
                                ...this.state.mapDialog,
                                open: false,
                              }
                            });
                          }}
                        />
                      )}
                    </Grid>

                    <Grid item xs={12} sm={12} md={12}>
                      <FormControl className={classes.servicesArea}>
                        <FormLabel className={classes.servicesAreaTitle}>
                          Seleccione los horarios
                        </FormLabel>

                        <FormGroup className={classes.servicesAreaContent}>
                          <CustomScheduleSelector
                            initialValue={form.schedule}
                            days={[
                              'monday',
                              'tuesday',
                              'wednesday',
                              'thursday',
                              'friday',
                              'saturday',
                              'sunday',
                            ]}
                            daysLabelMapper={{
                              monday: 'Lunes',
                              tuesday: 'Martes',
                              wednesday: 'Miércoles',
                              thursday: 'Jueves',
                              friday: 'Viernes',
                              saturday: 'Sábado',
                              sunday: 'Domingo',
                            }}
                            onChange={this.handleInputChange('schedule')}
                          />
                        </FormGroup>
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={12} md={12}>
                      <br />
                      <hr style={{ color: 'lightgray' }} />

                      <CustomFileSelector
                        buttonText='Agregar imágenes'
                        variant='grid'
                        accept='image/*'
                        buttonColor={images.length || selectedImages.length ? undefined : 'default'}
                        selectedMask={selectedImages}
                        multiple
                        showMainFile
                        onFileChange={files => {
                          const newState = { images: files };

                          if (this.state.selectedImages.length)
                            newState.selectedImages = [];

                          this.setState(newState);
                        }}
                      />
                      <br />
                    </Grid>

                    <Grid item xs={12} sm={12} md={12}>
                      <hr style={{ color: 'lightgray' }} />

                      <CustomFileSelector
                        buttonText='Video de muestra'
                        variant='text'
                        accept='video/mp4'
                        selectedMask={selectedVideo}
                        onFileChange={files => {
                          const newState = { videos: files };

                          if (this.state.selectedVideo)
                            newState.selectedVideo = null;

                          this.setState(newState);
                        }}
                      />
                      <br />
                    </Grid>
                  </Grid>

                  <FormControl>
                    <FormLabel className={classes.servicesAreaTitle}>
                      Visibilidad del lugar
                      </FormLabel>

                    <FormGroup className={classes.servicesAreaContent}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={form.status}
                            onChange={event => {
                              this.handleInputChange(`status`)(event.target.checked);
                            }}
                            color="primary"
                            inputProps={{
                              'aria-label': 'Visible al público',
                            }}
                          />
                        }
                        label={'Visible al público'}
                      />
                    </FormGroup>
                  </FormControl>
                </CardBody>

                <CardFooter style={{ justifyContent: "normal" }}>
                  <Button color="primary" style={{ marginRight: "8px" }} onClick={this.handleSave}>
                    Guardar
                  </Button>

                  <Button
                    onClick={() => {
                      this.props.history.push('/places/entries');
                    }}>
                    Cancelar
                  </Button>
                </CardFooter>
              </Card>
            </Grid>
          </Grid>
        </Grid>

        {snackError.open && (
          <Snackbar
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            open
            onClose={() => {
              this.setState({
                snackError: {
                  open: false,
                  payload: null,
                }
              });
            }}
            ContentProps={{
              "aria-describedby": "error-message-id",
            }}
            message={<span id="error-message-id">{snackError.payload}</span>}
          />
        )}

        {notiSnack.open && (
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            open
            onClose={this.onNotiSnackClose}
            ContentProps={{
              "aria-describedby": "noti-message",
              className: classes.notiSnack,
            }}
            style={{ marginTop: '10px' }}
            message={
              <span id="noti-message">
                <CheckIcon id="noti-message-icon" />
                <span>{notiSnack.payload}</span>
              </span>
            }
            action={[
              <IconButton key="close" aria-label="Close" color="inherit" className={classes.close} onClick={this.onNotiSnackClose}>
                <CloseIcon />
              </IconButton>,
            ]}
          />
        )}
      </Grid>
    );
  }
}


export default withStyles(styles)(ManagePlaces);
