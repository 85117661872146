import React from "react";
import PropTypes from "prop-types";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import { Checkbox, TextField, FormControlLabel } from "@material-ui/core";
import Button from "@material-ui/core/Button";

import AuthService from "components/Services/AuthService";

const styles = theme => ({
  button: {
    margin: theme.spacing.unit,
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 400,
  },
});

class IncidentPriorityForm extends React.Component {
  constructor(props) {
    super(props);
    let initialForm = {
      name: null,
      description: null,
      categories: [],
    };

    let isEdit = false;
    if (props.form) {
      isEdit = true;
      initialForm = props.form;
    }

    this.state = {
      form: initialForm,
      categories: [],
      selectedCategories: initialForm.categories.map(c => {
        return c.id;
      }),
      errorForm: {
        name: false,
      },
      isEditForm: isEdit,
    };

    this.AuthService = new AuthService();
  }

  handleTextFieldChange = event => {
    const target = event.target;
    const name = target.name;
    const updatedForm = {
      ...this.state.form,
    };
    updatedForm[name] = target.value;

    const updatedErrorForm = {
      ...this.state.errorForm,
    };
    updatedErrorForm[name] = !target.value || target.value.length === 0;

    this.setState({
      form: updatedForm,
      errorForm: updatedErrorForm,
    });
  };

  handleCheckBoxChange = event => {
    const target = event.target;
    const name = target.name;
    const updatedForm = {
      ...this.state.form,
    };
    updatedForm[name] = target.checked;

    this.setState({
      form: updatedForm,
    });
  };

  handleSubmit = event => {
    event.preventDefault();
    if (this.isFormValid()) {
      const result = {
        ...this.state.form,
        categories: this.state.selectedCategories,
      };
      this.props.submitHandler(result);
    }
  };

  selectAll = event => {
    event.preventDefault();
    this.setState({
      selectedCategories: this.state.categories.map(cat => {
        return cat.id;
      }),
    });
  };

  selectNone = event => {
    event.preventDefault();
    this.setState({
      selectedCategories: [],
    });
  };

  isFormValid = () => {
    const form = this.state.form;
    const updatedErrorForm = {
      ...this.state.errorForm,
    };
    updatedErrorForm.name = !form.name || form.name.length === 0;
    updatedErrorForm.name = !form.description || form.description.length === 0;

    this.setState({
      errorForm: updatedErrorForm,
    });
    return !updatedErrorForm.name;
  };

  handleCategoryToggle = value => () => {
    const { selectedCategories } = this.state;
    const currentIndex = selectedCategories.indexOf(value);
    const newCategories = [...selectedCategories];

    if (currentIndex === -1) {
      newCategories.push(value);
    } else {
      newCategories.splice(currentIndex, 1);
    }

    this.setState({
      selectedCategories: newCategories,
    });
  };

  componentDidMount() {
    this.setState({ loading: true });
    this.AuthService.authFetch("/admin/categories")
      .then(response => {
        this.setState({
          loading: false,
          categories: response.result,
        });
      })
      .catch(error => this.setState({ loading: false, error: error }));
  }

  render() {
    const { classes } = this.props;
    const submitButtonText = this.state.isEditForm ? "Salvar" : "Crear";

    return (
      <div>
        <form className={classes.container} noValidate autoComplete="off" onSubmit={this.handleSubmit}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <TextField
                name="name"
                label="Nombre"
                required
                error={this.state.errorForm.name}
                className={classes.textField}
                value={this.state.form.name}
                onChange={this.handleTextFieldChange}
                margin="normal"
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <TextField
                name="description"
                label="Descripción"
                required
                error={this.state.errorForm.name}
                className={classes.textField}
                value={this.state.form.description}
                onChange={this.handleTextFieldChange}
                margin="normal"
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <h6 style={{ marginBottom: "0" }}>Categorias</h6>
              <p style={{ marginBottom: "0" }}>
                Seleccionar:{" "}
                <Button onClick={this.selectAll}>
                  TODO
                </Button>
                {" "}/{" "}
                <Button onClick={this.selectNone}>
                  NADA
                </Button>
              </p>
              <List>
                {this.state.categories.map(cat => (
                  <ListItem key={cat.id} role={undefined} dense button onClick={this.handleCategoryToggle(cat.id)}>
                    <Checkbox checked={this.state.selectedCategories.indexOf(cat.id) !== -1} tabIndex={-1} disableRipple />
                    <ListItemText primary={cat.name} />
                  </ListItem>
                ))}
              </List>
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <FormControlLabel control={<Checkbox name="isDeleted" checked={this.state.form.isDeleted} onChange={this.handleCheckBoxChange} />} label="Marcar como borrado" />
            </GridItem>
          </GridContainer>
          <Button color="primary" className={classes.button} style={{ marginTop: "30px" }} type="submit">
            {submitButtonText}
          </Button>
          <Button color="secondary" className={classes.button} onClick={this.props.cancelHandler} style={{ marginTop: "30px" }}>
            Cancelar
          </Button>
        </form>
      </div>
    );
  }
}

IncidentPriorityForm.propTypes = {
  history: PropTypes.object,
  form: PropTypes.object,
  cancelHandler: PropTypes.func.isRequired,
  submitHandler: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(IncidentPriorityForm);
