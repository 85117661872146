// @material-ui/icons
import Dashboard from "@material-ui/icons/Dashboard";
import Person from "@material-ui/icons/Person";
import Notifications from "@material-ui/icons/Notifications";

// core components/views
import GarbagePickupContainer from "views/GarbagePickupDashboard/GarbagePickup/GarbagePickupContainer";
import GarbagePickupRangesContainer from "views/GarbagePickupDashboard/GarbagePickupRanges/GarbagePickupRangesContainer";
import Users from "views/GarbagePickupDashboard/Users/Users";

const garbagePickups = [
  {
    path: "/garbage-pickup/pickups",
    sidebarName: "Recogida de Basura",
    navbarName: "Recogida de Basura",
    icon: Dashboard,
    component: GarbagePickupContainer,
  },
  {
    path: "/garbage-pickup/ranges",
    sidebarName: "Rango de Horas de Recogida",
    navbarName: "Rango de Horas de Recogida",
    icon: "content_paste",
    component: GarbagePickupRangesContainer,
  },
  {
    path: "/garbage-pickup/users",
    sidebarName: "Usuarios",
    navbarName: "Usuarios",
    icon: Person,
    component: Users,
    adminRestricted: true,
  },
  {
    path: "#notifications",
    sidebarName: "Notificaciones",
    navbarName: "Notificaciones",
    icon: Notifications,
    adminRestricted: false,
  },
  {
    redirect: true,
    path: "/garbage-pickup",
    to: "/garbage-pickup/pickups",
    navbarName: "Redirect",
  },
];
export default garbagePickups;
