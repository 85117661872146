export function dateTimeFormat(value) {
  return new Intl.DateTimeFormat("en-GB", {
    year: "numeric",
    month: "numeric",
    day: "2-digit",
    hour12: "true",
    hour: "numeric",
    minute: "numeric",
    second: "numeric"
  }).format(new Date(value));
}


export function getTimeLapse(date, dueDate = new Date()) {
  if (typeof date === 'string')
    date = new Date(date);

  if (typeof dueDate === 'string')
    dueDate = new Date(dueDate);

  // Remove miliseconds
  let dateDiff = (dueDate - date) / 1000;

  const prefix = dateDiff < 0 ? 'En' : 'Hace';

  if (dateDiff < 0)
    dateDiff *= -1;

  const orderedList = ['año', 'mes', 'día', 'hora', 'minuto', 'segundo'];
  const timeLapses = {};

  timeLapses['segundo'] = Math.floor(dateDiff % 60);
  // Remove seconds
  dateDiff /= 60;
  timeLapses['minuto'] = Math.floor(dateDiff % 60);
  // Remove minutos
  dateDiff /= 60;
  timeLapses['hora'] = Math.floor(dateDiff % 24);
  // Remove hours
  dateDiff /= 24;
  timeLapses['día'] = Math.floor(dateDiff % 30);
  // Remove days
  dateDiff /= 30;
  timeLapses['mes'] = Math.floor(dateDiff % 12);
  // Remove months
  dateDiff /= 12;
  timeLapses['año'] = Math.floor(dateDiff);

  for (let a of orderedList)
    if (timeLapses[a] > 0)
      return `${prefix} ${timeLapses[a]} ${a}${timeLapses[a] === 1 ? '' : (a.endsWith('s') ? 'es' : 's')}`;

  return `${prefix} 0 segundos`;
}
