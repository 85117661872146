// @material-ui/icons
import Dashboard from "@material-ui/icons/Dashboard";
import Person from "@material-ui/icons/Person";
import ListAlt from "@material-ui/icons/ListAlt";
import Notifications from "@material-ui/icons/Notifications";
import List from "@material-ui/icons/List";

// core components/views
import IncidentsContainer from "views/IncidentsDashboard/Incidents/IncidentsContainer";
import CategoriesContainer from "views/IncidentsDashboard/Categories/CategoriesContainer";
import PrioritiesContainer from "views/IncidentsDashboard/Priorities/PrioritiesContainer";
import Users from "views/IncidentsDashboard/Users/Users";
import TemplatesContainer from "../views/IncidentsDashboard/Templates/TemplatesContainer";

const incidentsRoutes = [
  {
    path: "/incidents/incidents",
    sidebarName: "Incidentes",
    navbarName: "Incidentes",
    icon: Dashboard,
    component: IncidentsContainer,
    adminRestricted: false,
  },
  {
    path: "/incidents/categories",
    sidebarName: "Categorias",
    navbarName: "Categorias",
    icon: "content_paste",
    component: CategoriesContainer,
    adminRestricted: true,
  },
  {
    path: "/incidents/priorities",
    sidebarName: "Prioridades",
    navbarName: "Prioridades",
    icon: List,
    component: PrioritiesContainer,
    adminRestricted: true,
  },
  {
    path: "/incidents/users",
    sidebarName: "Usuarios",
    navbarName: "Usuarios",
    icon: Person,
    component: Users,
    adminRestricted: true,
  },
  {
    path: "/incidents/templates",
    sidebarName: "Plantillas",
    navbarName: "Plantillas",
    icon: ListAlt,
    component: TemplatesContainer,
    adminRestricted: true,
  },
  {
    path: "#notifications",
    sidebarName: "Notificaciones",
    navbarName: "Notificaciones",
    icon: Notifications,
    adminRestricted: false,
  },
  {
    redirect: true,
    path: "/incidents",
    to: "/incidents/incidents",
    navbarName: "Redirect",
    adminRestricted: false,
  },
];

export default incidentsRoutes;
