import React from "react";
import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

import Button from "@material-ui/core/Button";
import PaginatedTable2 from "components/CustomTable/PaginatedTable2";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";

import CardBody from "components/Card/CardBody.jsx";

import AuthService from "components/Services/AuthService";
import { GLOBAL_ROLES, MODULES_INFO } from "../../variables/general";
import { Snackbar } from "@material-ui/core";

const styles = () => ({
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  menuStyle: {
    marginLeft: "25px",
  },
});

class UsersTable extends React.Component {
  constructor(props) {
    super(props);
    this.AuthService = new AuthService();
    this.user = this.AuthService.getProfile().user;

    this.state = {
      anchorEl: null,
      navigateToCreate: false,
      navigateToEdit: null,
      snackWarning: {
        payload: null,
        open: false
      },
      totalCount : 0,
      users : [],
      loading: false
    };
  }

  get allowedSuperAdmin() {
    return 'ismael@techandpeople.com';
  }

  componentDidMount(){
    this.getData();
  }

  getData = (page = 0, count = 10, filter = '', sort = 'name', order = 'asc') => {

    this.setState({
      loading: true
    });
    
    this.AuthService.authFetch(`/account/users/pagination?page=${page}&count=${count}&filter=${filter}&sort=${sort}&order=${order}`)
    .then(res => {
      const { users, totalCount } = res.result;

      this.setState({
        users,
        totalCount,
        loading: false
      });
    })
    .catch(error => {
      console.error("error here: ", error); 
    });

  }

  render() {
    const { users, totalCount, loading } = this.state;
    const { snackWarning, navigateToEdit, navigateToCreate } = this.state;

    if (navigateToCreate)
      return (<Redirect push to="/users/users/create" />);

    if (navigateToEdit)
      return (
        <Redirect
          push
          to={{
            pathname: "/users/users/edit",
            state: { user: navigateToEdit },
          }}
        />
      );

    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Button
            variant="outlined"
            style={{ margin: "10px" }}
            onClick={() => {
              this.setState({ navigateToCreate: true });
            }}>
            Agregar
          </Button>
        </GridItem>

        <GridItem xs={12} sm={12} md={12}>
          <PaginatedTable2
            headerList={[
              'Nombre',
              'Email',
              'Rol',
              'Módulos',
              'Estatus'
            ]}
            getData={this.getData}
            totalCount={totalCount} 
            loading={loading}
            tableContent={users
              .filter(user => GLOBAL_ROLES.isSuperAdmin(this.user.role) || !GLOBAL_ROLES.isSuperAdmin(user.role))
              .map(user => {
                return {
                  id: user.id,
                  name: user.displayName,
                  email: user.email,
                  role: GLOBAL_ROLES[user.role].name,
                  modules: (
                    <ul style={{ paddingLeft: "0", listStyle: "none" }}>
                      {!GLOBAL_ROLES.isAdmin(user.role) ? user.modules.map(_module => (
                        <li key={_module.name}>
                          {MODULES_INFO[_module.name].displayName
                            + ' - '
                            + MODULES_INFO[_module.name].roles[_module.localRole].displayName}
                        </li>
                      )) : (<li><i>TODOS</i></li>)}
                    </ul>
                  ),
                  status: { ACTIVE: 'ACTIVO', INACTIVE: 'INACTIVO' }[user.status]
                }
              })}
            tableContentProps={[
              'name', 'email',
              'role', 'modules',
              'status'
            ]}
            rowKeyPropName='id'
            onRowClick={row => {
              const user = users.find(f => f.id === row.id);
              if (GLOBAL_ROLES.isSuperAdmin(user.role) && this.user.email !== this.allowedSuperAdmin)
                this.setState({
                  snackWarning: {
                    payload: 'No se puede editar el perfil de un SuperAdmin',
                    open: true
                  }
                });
              else
                this.setState({ navigateToEdit: user });
            }}
            fillEmptyRows
          />
        </GridItem>

        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          open={snackWarning.open}
          onClose={() => {
            this.setState({
              snackWarning: {
                payload: null,
                open: false
              }
            });
          }}
          ContentProps={{
            "aria-describedby": "message-id",
          }}
          message={<span id="message-id">{snackWarning.payload}</span>}
        />
      </GridContainer>
    );
  }
}

class Users extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      page: 1,
      totalCount : 0
    };

    this.AuthService = new AuthService();
  }

  componentDidMount() {
  }

  render() {
    const { classes } = this.props;
    const { users } = this.state;

    return (
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="primary">
                <h4 className={classes.cardTitleWhite}>Listado de usuarios</h4>
              </CardHeader>

              <CardBody>
                <UsersTable users={users} classes={classes} />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

UsersTable.propTypes = {
  users: PropTypes.array.isRequired,
};

Users.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Users);
