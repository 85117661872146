import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";

import AuthService from "components/Services/AuthService";
import PaginatedTable from "../../components/CustomTable/PaginatedTable";

import { dateTimeFormat } from "utils/DateUtil.jsx";
import { PAYMENT_STATUS_MASK } from "variables/general";
import { CardContent, Typography, CircularProgress, Snackbar } from "@material-ui/core";
import { primaryColor } from "assets/jss/material-dashboard-react.jsx";

const styles = () => ({
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  subCardTitle: {
    marginBottom: '25px',
    paddingBottom: '10px',
    boxShadow: '0 2px 3px -3px black',
    fontSize: '14pt',
  },
  subCardContent: {
    fontSize: '12pt',
    marginBottom: '5px',
  },
  progress: {
    color: primaryColor,
    position: "absolute",
    top: "calc(50% - 4em)",
    left: "calc(50% - 4em)",
  }
});

class PaymentDetails extends React.Component {
  constructor(props) {
    super(props);

    const { payment } = localStorage;
    let entry;

    try {
      entry = JSON.parse(payment);
      delete localStorage.payment;
    }
    catch (ex) { }

    this.AuthService = new AuthService();

    this.state = {
      entry: entry,
      isLoaded: !!entry,
      openErrorSnack: false
    };
  }

  componentDidMount() {
    if (this.state.entry)
      return;

    if (!this.AuthService)
      this.AuthService = new AuthService();

    const { id } = this.props.match.params;

    this.AuthService.authFetch(`/payments/show/${id.toLowerCase()}`)
      .then(res => {
        this.setState({
          entry: res.result,
          isLoaded: true
        });
      })
      .catch(error => {
        console.log(error);
        this.setState({
          isLoaded: true,
          openErrorSnack: true
        });

        setTimeout(() => {
          this.props.history.push('/');
        }, 3000);
      });
  }

  render() {
    const { classes } = this.props;
    const { entry, isLoaded, openErrorSnack } = this.state;

    if (!isLoaded)
      return (<CircularProgress className={classes.progress} size={50} />);
    else if (openErrorSnack)
      return (
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          open
          ContentProps={{
            "aria-describedby": "message-id",
          }}
          message={<span id="message-id">Información de pago no encontrada... redirigiendo...</span>}
        />
      );

    const invoiceDetail = entry.invoiceADN.map(m => ({
      ...m,
      date: m.date && m.date.length === 8 ?
        `${m.date.substr(6)}/${m.date.substr(4, 2)}/${m.date.substr(0, 4)}` :
        (m.date || 'N/A'),
      paymentID: (entry.receiptsADN.find(f => f.invoiceID === m.id) || {}).paymentID || 'N/A',
      amount: `$ ${m.amount}`,
      balance: `$ ${m.balance}`,
    }));

    return (
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="primary">
                <h4 className={classes.cardTitleWhite}>Detalles de pago</h4>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <h2>
                      <b>Código pago:</b> {entry.id.toUpperCase()}
                    </h2>
                  </GridItem>
                </GridContainer>

                <Card>
                  <CardContent>
                    <Typography className={classes.subCardTitle} color="textSecondary" gutterBottom>
                      Información de cuenta
                    </Typography>

                    <GridContainer>
                      <GridItem className={classes.subCardContent} xs={12} sm={6} md={4} lg={4}>
                        <b>Código sistema:</b> {entry.sysCode}
                      </GridItem>

                      <GridItem className={classes.subCardContent} xs={12} sm={6} md={4} lg={4}>
                        <b>Nombre cuenta:</b> {entry.account}
                      </GridItem>

                      <GridItem className={classes.subCardContent} xs={12} sm={6} md={4} lg={4}>
                        <b>Referencia pago:</b> {entry.reference.name}
                      </GridItem>

                      <GridItem className={classes.subCardContent} xs={12} sm={6} md={4} lg={4}>
                        <b>Correo referencia:</b> {entry.reference.email}
                      </GridItem>
                    </GridContainer>
                  </CardContent>
                </Card>

                <Card>
                  <CardContent>
                    <Typography className={classes.subCardTitle} color="textSecondary" gutterBottom>
                      Información de pago
                    </Typography>

                    <GridContainer>
                      <GridItem className={classes.subCardContent} xs={12} sm={6} md={4} lg={4}>
                        <b>Fecha registrada:</b> {dateTimeFormat(new Date(entry.processDateISO).toString())}
                      </GridItem>

                      <GridItem className={classes.subCardContent} xs={12} sm={6} md={4} lg={4}>
                        <b>Monto:</b> ${entry.amount}
                      </GridItem>

                      <GridItem className={classes.subCardContent} xs={12} sm={6} md={4} lg={4}>
                        <b>Estado:</b> {entry.status ? PAYMENT_STATUS_MASK[entry.status] || entry.status : 'INDEFINIDO'}
                      </GridItem>
                    </GridContainer>
                  </CardContent>
                </Card>

                <Card>
                  <CardContent>
                    <Typography className={classes.subCardTitle} color="textSecondary" gutterBottom>
                      Información de factura
                    </Typography>

                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12}>
                        <PaginatedTable
                          headerList={[
                            'ID',
                            'NCF',
                            'Periodo',
                            'Cantidad',
                            'Balance',
                            'ID Pago',
                            'Fecha',
                          ]}
                          tableContent={invoiceDetail}
                          tableContentProps={[
                            'id', 'ncf',
                            'period', 'amount',
                            'balance', 'paymentID',
                            'date'
                          ]}
                          rowKeyPropName='id'
                        />
                      </GridItem>
                    </GridContainer>
                  </CardContent>
                </Card>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div >
    );
  }
}

export default withStyles(styles)(PaymentDetails);
