import React from "react";
import { Route } from "react-router-dom";
import Users from "./Users";
import EditUser from "./EditUser";
import NewUser from "./NewUser";

const UsersContainer = () => {
  return (
    <div>
      <Route path="/users/users" exact component={Users} />
      <Route path="/users/users/edit" exact component={EditUser} />
      <Route path="/users/users/create" exact component={NewUser} />
    </div>
  );
};

export default UsersContainer;
