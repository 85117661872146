import React from "react";
// @material-ui/core components
//import withStyles from "@material-ui/core/styles/withStyles";
// core components
import Grid from "@material-ui/core/Grid";

import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Paper from "@material-ui/core/Paper";
import IncidentCategories from "./IncidentCategories";
// core components
import ClosedCategories from "./ClosedCategories";

class CategoriesTab extends React.Component {
  state = {
    value: 0
  };

  handleChange = (event, value) => {
    this.setState({ value });
  };

  render() {
    const { value } = this.state;

    return (
      <div>
        <Paper>
          <Grid container>
            <Grid item xs={12} sm={12} md={12}>
              <Tabs
                value={value}
                onChange={this.handleChange}
                indicatorColor="primary"
                textColor="primary"
                centered
              >
                <Tab label="Categorias Incidentes" />
                <Tab label="Categorias de Cierre" />
              </Tabs>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              {value === 0 && <IncidentCategories />}
              {value === 1 && <ClosedCategories />}
            </Grid>
          </Grid>
        </Paper>
      </div>
    );
  }
}

export default CategoriesTab;
