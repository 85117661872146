import React from "react";

// import Editor from "@ckeditor/ckeditor5-react";
// import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, ContentState } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import { withStyles } from "@material-ui/core";
import DraftPasteProcessor from "draft-js/lib/DraftPasteProcessor";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const styles = () => ({
  toolbar: {
    '& a': {
      color: 'black',
    }
  }
});

class CustomEditor extends React.Component {
  constructor(props) {
    super(props);

    let editorState;

    if (props.initialValue) {
      const processedHTML = DraftPasteProcessor.processHTML(props.initialValue);
      const contentState = ContentState.createFromBlockArray(processedHTML);

      editorState = EditorState.createWithContent(contentState);
      editorState = EditorState.moveFocusToEnd(editorState);
    }
    else
      editorState = EditorState.createEmpty();

    this.state = {
      editorState,
      imageMapper: {},
    };
  }

  onChange = editorState => {
    const { onValueChange } = this.props;
    const { imageMapper } = this.state;

    this.setState({
      editorState,
    });

    if (onValueChange)
      onValueChange(stateToHTML(editorState.getCurrentContent()), { editorState, imageMapper });
  };

  render() {
    const { editorState } = this.state;
    const { classes } = this.props;

    return (
      <Editor
        localization={{
          locale: 'es',
        }}
        editorState={editorState}
        onEditorStateChange={this.onChange}
        toolbarClassName={classes.toolbar}
        toolbar={{
          image: {
            uploadCallback: async file => {
              const { imageMapper } = this.state;

              const fileURL = URL.createObjectURL(file);
              const newEntry = {};

              newEntry[fileURL] = file;

              this.setState({
                imageMapper: {
                  ...imageMapper,
                  ...newEntry,
                }
              });

              return {
                data: {
                  link: fileURL,
                }
              };
            },
          },

        }}
      />
    );
  }
}

export default withStyles(styles)(CustomEditor);