import React from "react";

import propTypes from "prop-types";
import { withStyles, Table, TableHead, TableRow, TableCell, TableBody, TablePagination, TextField, TableSortLabel, Button, IconButton } from "@material-ui/core";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { defaultFont } from "assets/jss/material-dashboard-react.jsx";
import CloseIcon from "@material-ui/icons/Close";
import CircularProgress from "@material-ui/core/CircularProgress";

const styles = theme => ({
  table: {
    marginBottom: "0",
    width: "100%",
    maxWidth: "100%",
    backgroundColor: "transparent",
    borderSpacing: "0",
    borderCollapse: "collapse",
  },
  tableHeadCell: {
    color: "inherit",
    ...defaultFont,
    fontSize: "1em",
  },
  tableCell: {
    ...defaultFont,
    lineHeight: "1.42857143",
    padding: "12px 8px",
    verticalAlign: "middle",
  },
  tableResponsive: {
    width: "100%",
    marginTop: theme.spacing.unit * 3,
    overflowX: "auto",
  },
  filterButton: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    width: '100%',
    height: '100%',
  },
  customBadge: {
    marginTop: theme.spacing.unit / 4,
    color: 'white',
    fontWeight: 'bold',
    backgroundColor: '#2a61ad',
    padding: '5px 10px',
    borderRadius: '20px',
    marginBottom: '10px',
    display: 'inline-flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    boxSizing: 'border-box',
    flex: '0 0 auto',
    maxHeight: 34,
    '&:not(:first-child)': {
      marginLeft: '3px'
    },
  },
  badgeHolder: {
    display: 'flex',
    flex: 1,
    textAlign: 'left',
    overflowX: 'auto',
  },
  badgeCloser: {
    fontSize: '15pt',
    marginLeft: '2px',
    cursor: 'pointer',
    padding: '0 6px',
    borderRadius: '20px',
    transition: 'background-color ease-in-out .1s',
    '&:hover': {
      backgroundColor: '#295189'
    },
    '&:active': {
      backgroundColor: '#274877'
    }
  },
  [theme.breakpoints.down('sm')]: {
    filterField: {
      width: '100%',
      marginBottom: '10px'
    }
  }
});

const minifyStr = str => str
  .trim()
  .toLowerCase()
  .replace(/á/g, 'a')
  .replace(/é/g, 'e')
  .replace(/í/g, 'i')
  .replace(/ó/g, 'o')
  .replace(/[úü]/g, 'u')
  .replace(/\s/g, ' ')
  .replace(/ {2,}/g, ' ');

class PaginatedTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: props.loading || false,
      headerList: props.headerList || [],
      tableContent: props.tableContent || [],
      tableContentProps: props.tableContentProps || [],
      rowsPerPage: props.rowsPerPage || 10,
      page: props.page || 0,
      filter: {
        value: '',
        fixed: [],
        result: [],
        fixedResultCache: [],
      },
      order: 'asc',
      orderBy: '',
      compact: !!props.compact,
      orderable: typeof props.orderable === 'boolean' ? props.orderable : true,
    };
  }

  componentWillReceiveProps(nextProps) {
    const nextState = {};

    if (nextProps.headerList !== this.props.headerList)
      nextState.headerList = [...nextProps.headerList];

    if (nextProps.tableContent !== this.props.tableContent)
      nextState.tableContent = [...nextProps.tableContent];

    if (nextProps.tableContentProps !== this.props.tableContentProps)
      nextState.tableContentProps = [...nextProps.tableContentProps];

    if (nextProps.rowsPerPage !== this.props.rowsPerPage)
      nextState.rowsPerPage = nextProps.rowsPerPage;

    if (nextProps.page !== this.props.page)
      nextState.page = nextProps.page;

    if (nextProps.hideFilter !== this.props.hideFilter)
      nextState.hideFilter = nextProps.hideFilter;

    if (nextProps.loading !== this.props.loading)
      nextState.loading = nextProps.loading;

    if (nextProps.disableDeepFilterCheck !== this.props.disableDeepFilterCheck)
      nextState.disableDeepFilterCheck = nextProps.disableDeepFilterCheck;

    this.setState(nextState);
  }

  handleChangePage = (_event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };

  //////////////////////////////////

  createSortHandler = property => () => {
    const { order, orderBy } = this.state;
    const isDesc = orderBy === property && order === 'desc';

    this.setState({
      order: isDesc ? 'asc' : 'desc',
      orderBy: property,
    });
  };

  desc = (current, next, orderBy) => {
    let currentVal = current[orderBy];
    let nextVal = next[orderBy];

    if (typeof currentVal === typeof nextVal) {
      // eslint-disable-next-line
      switch (typeof currentVal) {
        case 'string':
          const customDateTimeRegex = /^\d{1,2}\/\d{1,2}\/\d{4}, \d{1,2}:\d{1,2}:\d{1,2} (am|pm)$/;
          const customDateRegex = /^\d{2}\/\d{2}\/\d{4}$/;
          const numberRegex = /^((\$|#) ?)?\d+(\.\d+)*$/;

          currentVal = currentVal.trim();
          nextVal = nextVal.trim();

          if (customDateTimeRegex.test(currentVal) && customDateTimeRegex.test(nextVal)) {
            const currentPart = currentVal.split(',');
            const nextPart = nextVal.split(',');

            currentVal = new Date(`${currentPart[0].substr(currentPart[0].lastIndexOf('/') + 1)}/${
              currentPart[0].substring(currentPart[0].indexOf('/') + 1, currentPart[0].lastIndexOf('/'))}/${
              currentPart[0].substr(0, currentPart[0].indexOf('/'))
              } ${currentPart[1].trim()}`).getTime();

            nextVal = new Date(`${nextPart[0].substr(nextPart[0].lastIndexOf('/') + 1)}/${
              nextPart[0].substring(nextPart[0].indexOf('/') + 1, nextPart[0].lastIndexOf('/'))}/${
              nextPart[0].substr(0, nextPart[0].indexOf('/'))
              } ${nextPart[1].trim()}`).getTime();

            break;
          }
          if (customDateRegex.test(currentVal) && customDateRegex.test(nextVal)) {
            currentVal = new Date(`${currentVal.substr(currentVal.lastIndexOf('/') + 1)}/${
              currentVal.substring(currentVal.indexOf('/') + 1, currentVal.lastIndexOf('/'))}/${
              currentVal.substr(0, currentVal.indexOf('/'))}`).getTime();

            nextVal = new Date(`${nextVal.substr(nextVal.lastIndexOf('/') + 1)}/${
              nextVal.substring(nextVal.indexOf('/') + 1, nextVal.lastIndexOf('/'))}/${
              nextVal.substr(0, nextVal.indexOf('/'))}`).getTime();

            break;
          }
          else if (numberRegex.test(currentVal) && numberRegex.test(nextVal)) {
            currentVal = +currentVal.replace(/(#|\$)/, '');
            nextVal = +nextVal.replace(/(#|\$)/, '');

            break;
          }

          currentVal = minifyStr(currentVal);
          nextVal = minifyStr(nextVal);
          break;
      }
    }

    if (nextVal < currentVal)
      return -1;
    if (nextVal > currentVal)
      return 1;

    return 0;
  }

  getSorting = (order, orderBy) => {
    const multiplier = order === 'desc' ? 1 : -1;
    return (a, b) => multiplier * this.desc(a, b, orderBy);
  }

  stableSort = (array, compare) => {
    const stabilizedThis = array.map((m, i) => [m, i]);
    stabilizedThis.sort((current, next) => {
      const order = compare(current[0], next[0]);
      if (order !== 0) return order;
      return current[1] - next[1];
    });
    return stabilizedThis.map(el => el[0]);
  }

  //////////////////////////////////

  filterAlgorithm = (collection, filterVal) => {
    const { disableDeepFilterCheck } = this.props;
    const { tableContentProps } = this.state;

    let filteredResult = collection;

    if (filterVal)
      filteredResult = collection.filter(row => {
        const depthScrapper = value => {
          let result = '';

          if (typeof value === 'string' || typeof value === 'number' || typeof value === 'boolean')
            result = `${value}`;
          // If not dealing with a simple string:
          else if (!disableDeepFilterCheck && typeof value === 'object' && value.$$typeof && value.$$typeof.toString() === Symbol('react.element').toString()) {
            let deepElem = value.props.children;
            do {
              if (Array.isArray(deepElem))
                // eslint-disable-next-line
                deepElem.forEach(f => {
                  const innerResult = depthScrapper(f);
                  if (innerResult)
                    result += innerResult + ' ';
                });
              else if (!deepElem.props)
                result += deepElem + ' ';
            }
            while ((deepElem = deepElem.props ? deepElem.props.children : null));
          }

          return result.trim();
        };

        let result;

        const rowIsArray = Array.isArray(row);
        const collection = rowIsArray ? row : tableContentProps;
        const elementHandler = cb => elem => cb(rowIsArray ? elem : row[elem]);

        result = collection.some(elementHandler(s => minifyStr(depthScrapper(s) || '').indexOf(minifyStr(filterVal)) !== -1));

        // If the criteria starts with a "sharp" character and contains a valid number afterwards
        if (!result && filterVal.startsWith('#') && /^#-?\d+((\.|,)\d+)?!?$/.test(filterVal))
          result = collection.some(elementHandler(s => {
            const field = minifyStr(depthScrapper(s) || '');

            // If the field is not a valid number:
            if (!/^-?\d+((\.|,)\d+)?$/.test(field))
              return false;

            if (filterVal.indexOf('!') !== -1)
              return field === minifyStr(filterVal.replace('#', '').replace('!', ''));
            else
              return field.indexOf(minifyStr(filterVal.replace('#', ''))) !== -1;
          }));

        return result;
      });

    return filteredResult;
  };

  handleFilterChange = (filterVal, dropFixed = false) => {
    const { filter, tableContent } = this.state;
    const newVal = filterVal.trimLeft();

    if (!dropFixed && newVal === filter.value)
      return;

    const filteredResult = this.filterAlgorithm(!dropFixed && filter.fixed.length ? filter.fixedResultCache : tableContent, newVal);

    this.setState({
      filter: {
        value: newVal,
        fixed: dropFixed ? [] : [...filter.fixed],
        result: filteredResult,
        fixedResultCache: dropFixed ? [] : filter.fixedResultCache
      }
    });
  };

  fixFilter = removeID => {
    const { filter, tableContent } = { ...this.state };
    let setVal;
    let tableContentCopy = tableContent;

    if (typeof removeID === 'number') {
      setVal = filter.value;

      filter.fixed = filter.fixed.filter((_f, i) => i !== removeID);
    }
    else {
      setVal = '';

      if (filter.value && !filter.fixed.some(s => minifyStr(s) === minifyStr(filter.value)))
        filter.fixed.push(filter.value);
      else
        return;
    }

    let filterResult = tableContentCopy;

    filter.fixed.forEach(entry => {
      filterResult = this.filterAlgorithm(filterResult, entry);
    });

    this.setState({
      filter: {
        value: setVal,
        fixed: filter.fixed,
        fixedResultCache: filterResult,
        result: this.filterAlgorithm(filterResult, setVal),
      }
    });
  };

  render() {
    const { classes, fillEmptyRows, onRowClick, hideFilter } = this.props;
    const { headerList, tableContent, tableContentProps, rowsPerPage, page, filter, compact, orderable, order, orderBy, loading } = this.state;

    const collectionToShow = !!filter.value || !!filter.fixed.length ? filter.result : tableContent;

    const rowKeyName = this.props.rowKeyPropName || '__id';
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, collectionToShow.length - page * rowsPerPage);
    const filterAreaStyles = loading || hideFilter ? { display: 'none' } : {};

    return (
      <div className={classes.tableResponsive}>
        {loading && (
          <GridContainer style={{ textAlign: 'right', maxWidth: '100%' }}>
            <GridItem xs={12} sm={12} md={12} lg={12}>
              <CircularProgress size={20} />
            </GridItem>
          </GridContainer>
        )}

        <GridContainer style={{ textAlign: 'right', maxWidth: '100%', ...filterAreaStyles }}>
          <GridItem xs={12} sm={12} md={12} lg={8}>
            <div style={{ display: filter.fixed.length ? 'flex' : 'none' }}>
              <div className={classes.badgeHolder}>
                {filter.fixed.map((m, i) => (
                  <p className={classes.customBadge} key={i}>
                    {m}
                    <span
                      className={classes.badgeCloser}
                      onClick={() => {
                        this.fixFilter(i);
                      }}>
                      &times;
                      </span>
                  </p>
                ))}
              </div>

              <IconButton
                style={{ width: '50px', height: '50px' }}
                key="close-filters"
                aria-label="Close"
                onClick={() => {
                  const { filter } = this.state;

                  this.handleFilterChange(filter.value, true);
                }}>
                <CloseIcon />
              </IconButton>
            </div>
          </GridItem>

          <GridItem xs={9} sm={10} md={10} lg={3}>
            <TextField
              label="Filtro rápido"
              className={classes.filterField}
              value={filter.value}
              onChange={event => {
                try {
                  this.handleFilterChange(event.target.value);
                }
                catch (ex) {
                  console.log('Failed filtering table:', ex);
                }
              }}
              onKeyDown={event => {
                if (event.keyCode === 13 && event.target.value)
                  this.fixFilter();
              }}
              margin="normal"
              style={{ minWidth: '50%' }}
            />
          </GridItem>

          <GridItem xs={3} sm={2} md={2} lg={1}>
            <div className={classes.filterButton}>
              <Button
                onClick={this.fixFilter}>
                Fijar
              </Button>
            </div>
          </GridItem>
        </GridContainer>
        <Table className={classes.table} size={compact ? 'small' : 'medium'}>
          <TableHead>
            <TableRow>
              {headerList.map((m, i) => {
                const identifier = tableContentProps ? tableContentProps[i] : i;
                return (
                  <TableCell
                    style={{ fontWeight: 'bold' }}
                    className={classes.tableCell + " " + classes.tableHeadCell}
                    key={`header_${identifier}`}>
                    {orderable && tableContent.length && !(tableContent[0][identifier].$$typeof &&
                      tableContent[0][identifier].$$typeof.toString() === Symbol('react.element').toString()) ? (
                        <TableSortLabel
                          active={orderBy === identifier}
                          direction={order}
                          onClick={this.createSortHandler(identifier)}>
                          {m}
                        </TableSortLabel>
                      ) : m}
                  </TableCell>
                )
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {this.stableSort(collectionToShow, this.getSorting(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map(row => {
                if (Array.isArray(row))
                  return (<TableRow
                    hover
                    style={!!onRowClick ? { cursor: 'pointer' } : {}}
                    onClick={() => { if (onRowClick) onRowClick(row); }}>
                    {row.map((column, i) => (
                      <TableCell
                        className={classes.tableCell}
                        key={`${row[rowKeyName]}_${i}`}>
                        {column}
                      </TableCell>
                    ))}
                  </TableRow>)

                return (
                  <TableRow
                    key={row[rowKeyName]}
                    hover
                    style={!!onRowClick ? { cursor: 'pointer' } : {}}
                    onClick={() => { if (onRowClick) onRowClick(row); }}>
                    {tableContentProps.map((column, i) => (
                      <TableCell
                        className={classes.tableCell}
                        key={`${row[rowKeyName]}_${i}`}>
                        {row[column]}
                      </TableCell>
                    ))}
                  </TableRow>
                );
              })}
            {fillEmptyRows && emptyRows > 0 && (
              <TableRow style={{ height: 49 * emptyRows }}>
                <TableCell colSpan={8} />
              </TableRow>
            )}
          </TableBody>
        </Table>
        <TablePagination
          component="div"
          count={collectionToShow.length}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{
            "aria-label": "Previous Page",
          }}
          nextIconButtonProps={{
            "aria-label": "Next Page",
          }}
          labelDisplayedRows={info => `${info.from}-${info.to} de ${info.count}`}
          labelRowsPerPage='Filas por pág'
          onChangePage={this.handleChangePage}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}
        />
      </div>
    );
  }
}

PaginatedTable.propTypes = {
  classes: propTypes.object.isRequired,
  fillEmptyRows: propTypes.bool,
  onRowClick: propTypes.func,
  rowKeyPropName: propTypes.string,
  headerList: propTypes.array,
  tableContent: propTypes.array,
  tableContentProps: propTypes.array,
  rowsPerPage: propTypes.number,
  page: propTypes.number,
  hideFilter: propTypes.bool,
  disableDeepFilterCheck: propTypes.bool,
  orderable: propTypes.bool,
  compact: propTypes.bool,
  loading: propTypes.bool,
};

export default withStyles(styles)(PaginatedTable);