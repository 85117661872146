import React from "react";
import PropTypes from "prop-types";

import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import { Snackbar } from "@material-ui/core";
import { IncidentCategoriesTypes } from "./IncidentCategories";
import IncidentCategoryForm from "./IncidentCategoryForm";
import AuthService from "components/Services/AuthService";

const styles = theme => ({
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  },
  button: {
    margin: theme.spacing.unit
  },
  container: {
    /*     display: 'flex',
             flexWrap: 'wrap',*/
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 400
  }
});
class CreateIncidentCategory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      navigateCancel: false,
      showSnackbar: false,
      snackBarText: "",
      form: {
        name: null,
        addressRequired: false,
        type: IncidentCategoriesTypes.REGULAR.id,
        hidden: false,
      }
    };
    this.AuthService = new AuthService();
  }

  submitHandler = form => {
    this.AuthService.authFetch("/admin/categories", {
      method: "POST",
      body: JSON.stringify(form)
    })
      .then(() => {
        this.props.history.goBack();
      })
      .catch(() =>
        this.setState({
          snackBarText: "Error creando categoria",
          showSnackbar: true
        })
      );
  };
  cancelHandler = () => {
    this.props.history.goBack();
  };

  handleClose = (_event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({ showSnackbar: false });
  };

  render() {
    const { classes } = this.props;

    return (
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="primary">
                <h4 className={classes.cardTitleWhite}>
                  Crear categoría de Incidente
                </h4>
              </CardHeader>
              <CardBody>
                <IncidentCategoryForm
                  form={this.state.form}
                  submitHandler={this.submitHandler}
                  cancelHandler={this.cancelHandler}
                />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left"
          }}
          open={this.state.showSnackbar}
          onClose={this.handleClose}
          autoHideDuration={6000}
          ContentProps={{
            "aria-describedby": "message-id"
          }}
          message={<span id="message-id">{this.state.snackBarText}</span>}
        />
      </div>
    );
  }
}
CreateIncidentCategory.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  classes: PropTypes.object
};
export default withStyles(styles)(CreateIncidentCategory);
