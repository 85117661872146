import React from "react";
import PropTypes from "prop-types";

import withStyles from "@material-ui/core/styles/withStyles";
import Button from "@material-ui/core/Button";
import PaginatedTable2 from "components/CustomTable/PaginatedTable2";
import SelectAsync from "components/Select/SelectAsync.jsx";
import Select from "components/Select/Select.jsx";
import { FormControl } from "@material-ui/core";

// core components
import Grid from "@material-ui/core/Grid";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";

import CardBody from "components/Card/CardBody.jsx";
import { GLOBAL_ROLES, MODULES_INFO } from "variables/general";

import AuthService from "components/Services/AuthService";
import ConfirmDialog from "components/CustomDialogs/ConfirmDialog";
import ConfirmOptionDialog from "components/CustomDialogs/ConfirmOptionDialog";

const styles = () => ({
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  menuStyle: {
    marginLeft: "25px",
  },
  formControlSelect: {
    transform: "none",
  },
  formControl: {
    zIndex: 10
  },
});

const userRoles = Object.keys(MODULES_INFO.GARBAGE_PICKUP_MODULE.roles)
  .map(m => ({
    id: MODULES_INFO.GARBAGE_PICKUP_MODULE.roles[m].id,
    value: MODULES_INFO.GARBAGE_PICKUP_MODULE.roles[m].displayName
  }));

class AddUser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedUser: null,
      selectedRole: null,
      totalCount : 0,
      users : [],
      loading: false
    };

    this.AuthService = new AuthService();
  }

  handleUserChange = entry => {
    this.setState({
      selectedUser: entry,
    });
  };

  handleUserRoleChange = entry => {
    this.setState({
      selectedRole: entry,
    });
  };

  componentDidMount(){
  }

  loadOptions = (filter = '', callback) => {

    if(filter.length >= 2){
      this.setState({
        loading: true
      });
      
      this.AuthService.authFetch(`/account/users/pagination?page=${0}&count=${10}&filter=${filter}&sort=${'name'}&order=${'asc'}&role=${GLOBAL_ROLES.REGULAR.id}&module_not=${MODULES_INFO.GARBAGE_PICKUP_MODULE.id}`)
      .then(res => {
        let { users } = res.result;

        users = users.map((u) => {
          u.value = u.id;
          u.label = u.displayName;
          return u;
        });

        this.setState(users);

        callback(users);
      })
      .catch(error => {
        console.error("error here: ", error); 
      });
    }
    else{
      callback([]);
    }

  }

  render() {
    const { classes } = this.props;
    const { selectedUser, selectedRole, users } = this.state;

    return (
      <Card>
        <CardHeader color="primary">
          <h4 className={classes.cardTitleWhite}>Agregar usuario</h4>
        </CardHeader>
        <CardBody>
          <form
            className={classes.container}
            noValidate
            autoComplete="off"
            onSubmit={event => {
              event.preventDefault();

              if (!selectedUser || !selectedRole)
                return;

              this.props.handleSubmit(selectedUser, selectedRole.value);

              setTimeout(() => {
                this.setState({
                  selectedUser: null,
                  selectedRole: null
                });
              }, 0);
            }}>
            <Grid container>
              <Grid>
                <FormControl className={classes.formControl} style={{ width: "300px", margin: "10px" }}>
                  <SelectAsync
                    value={selectedUser}
                    onChange={this.handleUserChange}
                    placeholder="Buscar usuario"
                    options={users}
                    loadOptions={this.loadOptions}
                    id="Users"
                  />
                </FormControl>
                <FormControl className={classes.formControl} style={{ width: "300px", margin: "10px" }}>
                  <Select
                    value={selectedRole}
                    onChange={this.handleUserRoleChange}
                    placeholder="Seleccionar rol"
                    options={userRoles
                      .map(m => ({
                        value: m.id,
                        label: m.value
                      }))}
                  />

                </FormControl>
              </Grid>
              <Grid>
                <Button variant="outlined" style={{ margin: "10px" }} type="submit">
                  Agregar
                </Button>
              </Grid>
            </Grid>
          </form>
        </CardBody>
      </Card>
    );
  }
}

class UsersTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
      dialog: {
        open: false,
        payload: null
      },
      optionDialog: {
        open: false,
        payload: null
      },
      totalCount : 0,
      users : [],
      loading: false
    };

    this.AuthService = new AuthService();
  }

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  handleEdit = (payload, newValue) => {
    if (payload.role.internalName !== newValue.internalName)
      this.props.handleEdit(payload, newValue);

    this.setState({
      optionDialog: {
        open: false,
        payload: null
      }
    });
  }

  componentDidMount(){
    this.getData();
  }

  getData = (page = 0, count = 10, filter = '', sort = 'name', order = 'asc') => {

    this.setState({
      loading: true
    });
    
    this.AuthService.authFetch(`/account/users/pagination?page=${page}&count=${count}&filter=${filter}&sort=${sort}&order=${order}&role=${GLOBAL_ROLES.REGULAR.id}&module=${MODULES_INFO.GARBAGE_PICKUP_MODULE.id}`)
    .then(res => {
      const { users, totalCount } = res.result;

      this.setState({
        users,
        totalCount,
        loading: false
      });
    })
    .catch(error => {
      console.error("error here: ", error); 
    });

  }

  render() {
    const { users, totalCount, loading } = this.state;

    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <PaginatedTable2
            disableDeepFilterCheck
            headerList={[
              'Nombre',
              'Email',
              'Rol',
              'Acciones'
            ]}
            getData={this.getData}
            totalCount={totalCount} 
            loading={loading}
            tableContent={users
              .filter(f => !GLOBAL_ROLES.isAdmin(f.role) && f.modules.some(m => m.name === MODULES_INFO.GARBAGE_PICKUP_MODULE.id))
              .map(user => {
                const localRole = user.modules.find(f => MODULES_INFO.GARBAGE_PICKUP_MODULE.id === f.name).localRole;
                const roleDisplayName = MODULES_INFO.GARBAGE_PICKUP_MODULE.roles[localRole].displayName;

                return {
                  id: user.id,
                  name: user.displayName,
                  email: user.email,
                  role: roleDisplayName,
                  action: (
                    <div>
                      <Button
                        variant="contained"
                        style={{ margin: "5px" }}
                        onClick={() => {
                          this.setState({
                            optionDialog: {
                              open: true,
                              payload: {
                                user,
                                role: {
                                  internalName: localRole,
                                  publicName: roleDisplayName
                                }
                              }
                            }
                          });
                        }}>
                        Editar
                      </Button>
                      <Button
                        variant="contained"
                        color="secondary"
                        style={{ margin: "5px" }}
                        onClick={() => {
                          this.setState({
                            dialog: {
                              open: true,
                              payload: user
                            }
                          });
                        }}>
                        Remover
                      </Button>
                    </div>
                  )
                }
              })}
            tableContentProps={[
              'name', 'email',
              'role', 'action'
            ]}
            rowKeyPropName='id'
            fillEmptyRows
          />

          <ConfirmDialog
            open={this.state.dialog.open}
            payload={this.state.dialog.payload}
            contentText={this.state.dialog.payload ?
              `¿Desea remover a ${this.state.dialog.payload.firstName.toUpperCase()} de este módulo?` :
              ''}
            acceptBtnText="Remover"
            onAcceptBtnClick={payload => {
              this.props.handleRemove(payload);
              this.setState({
                dialog: {
                  open: false,
                  payload: null
                }
              });
            }}
            onCancelBtnClick={() => {
              this.setState({
                dialog: {
                  open: false,
                  payload: null
                }
              });
            }}
          />

          <ConfirmOptionDialog
            open={this.state.optionDialog.open}
            name="localRoleVal"
            value={this.state.optionDialog.payload ? this.state.optionDialog.payload.role : {}}
            title={this.state.optionDialog.payload ?
              `Modificando rol de ${this.state.optionDialog.payload.user.firstName.toUpperCase()}` :
              ''}
            acceptBtnText="Modificar"
            collection={userRoles.map(m => ({ internalName: m.id, publicName: m.value }))}
            onClose={newValue => {
              this.handleEdit(this.state.optionDialog.payload, newValue);
            }}
          />
        </GridItem>
      </GridContainer>
    );
  }
}
class Users extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
    };

    this.AuthService = new AuthService();
    this.child = React.createRef();
  }

  modifyGarbagePickupUser = (userID, modules) => {
    const self = this;

    self.AuthService.authFetch(`/account/update/${userID}`, {
      method: "PUT",
      body: JSON.stringify({ modules }),
    }).then(() => {
      self.child.current.getData();
    }).catch(() =>
      self.setState({
        snackBarText: "Error actualizando usuario",
        showSnackbar: true,
      })
    );
  }

  handleEditUser = (payload, newValue) => {
    const modules = payload.user.modules.slice();
    modules
      .filter(f => f.localRole === payload.role.internalName)
      .forEach(f => f.localRole = newValue.internalName);

    this.modifyGarbagePickupUser(payload.user.id, modules);
  }

  handleRemoveUserClick = user => {
    const modules = user.modules
      .slice()
      .filter(f => f.name !== MODULES_INFO.GARBAGE_PICKUP_MODULE.id);

    this.modifyGarbagePickupUser(user.id, modules);
  };

  handleAddUserSubmit = (userId, userRole) => {
    const self = this;

    if (userId && userRole) {
      const user = userId;

      if (user) {
        user.modules.push({
          name: MODULES_INFO.GARBAGE_PICKUP_MODULE.id,
          localRole: userRole,
        });
        this.AuthService.authFetch(`/account/update/${userId.id}`, {
          method: "PUT",
          body: JSON.stringify({ modules: user.modules }),
        })
          .then(() => {
            self.child.current.getData();
          })
          .catch(() =>
            this.setState({
              snackBarText: "Error actualizando usuario",
              showSnackbar: true,
            })
          );
      }
    }
  };

  componentDidMount() {
  }

  render() {
    const { classes } = this.props;

    return (
      <div>
        <Grid container>
          <Grid item xs={12} sm={12} md={12}>
            <AddUser classes={classes} handleSubmit={this.handleAddUserSubmit} />
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="primary">
                <h4 className={classes.cardTitleWhite}>Listado de usuarios</h4>
              </CardHeader>
              <CardBody>
                <UsersTable
                  ref={this.child}
                  classes={classes}
                  handleRemove={this.handleRemoveUserClick}
                  handleEdit={this.handleEditUser}
                />
              </CardBody>
            </Card>
          </Grid>
        </Grid>
      </div>
    );
  }
}
UsersTable.propTypes = {
};

Users.propTypes = {
  classes: PropTypes.object.isRequired,
};

AddUser.propTypes = {
  classes: PropTypes.object,
  handleSubmit: PropTypes.func.isRequired,
};

export default withStyles(styles)(Users);
