import React from "react";
import PropTypes from "prop-types";
import { withScriptjs, withGoogleMap, GoogleMap } from "react-google-maps";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";

import ping from "assets/img/pingMap.png";

import { config } from "utils/Firebase";
import { mapStyle } from "variables/general";

const CustomSkinMap = withScriptjs(
  withGoogleMap(props => (
    <GoogleMap
      defaultZoom={15.5}
      defaultCenter={props.defaultLocation}
      onCenterChanged={props.onCenterChanged}
      ref={map => props.onMapMounted(map)}
      defaultOptions={{
        scrollwheel: false,
        streetViewControl: false,
        mapTypeControl: false,
        fullscreenControl: false,
        zoomControl: true,
        styles: mapStyle,
      }}
    />
  ))
);

class IncidentMap extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      location: props.defaultLocation,
      map: undefined,
    };
  }

  onMapMounted = map => {
    this.map = map;
  };

  componentWillUnmount() {
    this.map = undefined;
  }

  handleClose = () => {
    this.props.closeDialog(this.state.location);
  };

  onCenterChanged() {
    const center = this.map.getCenter();
    const location = { lat: center.lat(), lng: center.lng() };
    this.setState({ location: location });
  }

  render() {
    const { open, title, defaultLocation } = this.props;

    return (
      <Dialog open={open} onClose={this.handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent
          style={{
            minHeight: "400px",
            minWidth: "500px",
          }}>
          <CustomSkinMap
            googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${config.apiKey}`}
            loadingElement={<div style={{ height: `100%` }} />}
            containerElement={<div style={{ height: `400px` }} />}
            mapElement={<div style={{ height: `100%` }} />}
            defaultLocation={defaultLocation}
            onMapMounted={this.onMapMounted}
            onCenterChanged={this.onCenterChanged.bind(this)}
          />
          <img
            src={ping}
            alt="pingMap"
            style={{
              position: "absolute",
              top: "calc(50% - 23px)",
              left: "calc(50% - 15px)",
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleClose} color="primary" autoFocus>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

IncidentMap.propTypes = {
  closeDialog: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  defaultLocation: PropTypes.object.isRequired,
};

export default IncidentMap;
