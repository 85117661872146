import React from "react";
import CategoriesTab from "./CategoriesTab";
import { Route } from "react-router-dom";
import CreateIncidentCategory from "./CreateIncidentCategory";
import CreateClosedCategory from "./CreateClosedCategory";
import EditIncidentCategory from "./EditIncidentCategory";
import EditClosedCategory from "./EditClosedCategory";

function CategoriesContainer() {
  return (
    <div>
      <Route path="/incidents/categories" exact component={CategoriesTab} />
      <Route
        path="/incidents/categories/incident/create"
        exact
        component={CreateIncidentCategory}
      />
      <Route
        path="/incidents/categories/incident/edit"
        exact
        component={EditIncidentCategory}
      />
      <Route
        path="/incidents/categories/closed/create"
        exact
        component={CreateClosedCategory}
      />
      <Route
        path="/incidents/categories/closed/edit"
        exact
        component={EditClosedCategory}
      />
    </div>
  );
}

export default CategoriesContainer;
