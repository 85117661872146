// ##############################
// // // Tasks for TasksCard - see Dashboard view
// #############################

const sessionStorageKeys = {
  id_token: "id_token",
  refresh_token: "refresh_token",
  user_object: "user_object"
};

const incidentRoles = {
  INCIDENT_ADMIN: "INCIDENT_ADMIN",
  INCIDENT_HANDLER: "INCIDENT_HANDLER",
  INCIDENT_SOLVER: "INCIDENT_SOLVER",
};

const mapStyle = [
  {
    elementType: "geometry",
    stylers: [
      {
        color: "#f5f5f5",
      },
    ],
  },
  {
    elementType: "labels.icon",
    stylers: [
      {
        visibility: "on",
      },
    ],
  },
  {
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#616161",
      },
    ],
  },
  {
    elementType: "labels.text.stroke",
    stylers: [
      {
        color: "#f5f5f5",
      },
    ],
  },
  {
    featureType: "administrative.land_parcel",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#bdbdbd",
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "geometry",
    stylers: [
      {
        color: "#eeeeee",
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#757575",
      },
    ],
  },
  {
    featureType: "poi.park",
    elementType: "geometry",
    stylers: [
      {
        color: "#e5e5e5",
      },
    ],
  },
  {
    featureType: "poi.park",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#9e9e9e",
      },
    ],
  },
  {
    featureType: "road",
    elementType: "geometry",
    stylers: [
      {
        color: "#ffffff",
      },
    ],
  },
  {
    featureType: "road.arterial",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#757575",
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "geometry",
    stylers: [
      {
        color: "#dadada",
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#616161",
      },
    ],
  },
  {
    featureType: "road.local",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#9e9e9e",
      },
    ],
  },
  {
    featureType: "transit.line",
    elementType: "geometry",
    stylers: [
      {
        color: "#e5e5e5",
      },
    ],
  },
  {
    featureType: "transit.station",
    elementType: "geometry",
    stylers: [
      {
        color: "#eeeeee",
      },
    ],
  },
  {
    featureType: "water",
    elementType: "geometry",
    stylers: [
      {
        color: "#c9c9c9",
      },
    ],
  },
  {
    featureType: "water",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#9e9e9e",
      },
    ],
  },
];

const incidentStatus = {
  OPEN: "OPEN",
  WITH_HANDLER: "WITH_HANDLER",
  WITH_SOLVER: "WITH_SOLVER",
  IN_PROGRESS: "IN_PROGRESS",
  SOLUTION_COMPLETED: "SOLUTION_COMPLETED",
  CLOSED: "CLOSED",
};

const INCIDENT_STATUS = {
  OPEN: "ABIERTO",
  WITH_HANDLER: "CON MANEJADOR",
  WITH_SOLVER: "CON SOLUCIONADOR",
  IN_PROGRESS: "EN PROCESO",
  SOLUTION_COMPLETED: "SOLUCIÓN COMPLETADA",
  CLOSED: "CERRADO"
};

const SOLVER_STATUS = {
  TODO: 'PENDIENTE',
  IN_PROGRESS: 'EN PROCESO',
  DONE: 'TERMINADO'
}

const imageType = {
  NORMAL: "NORMAL",
  CLOSED: "CLOSED",
};

const commentType = {
  INTERNAL: "INTERNAL",
  EXTERNAL: "EXTERNAL",
  SOLUTION_COMPLETED: "SOLUTION_COMPLETED",
  RESOLVED: "RESOLVED",
  LOG: "LOG",
};

const GLOBAL_ROLES = {
  SUPERADMIN: {
    id: "SUPERADMIN",
    name: "Super",
  },
  ADMIN: {
    id: "ADMIN",
    name: "Administrador",
  },
  REGULAR: {
    id: "REGULAR",
    name: "Regular",
  },
  isAdmin(roleID) {
    return roleID === this.SUPERADMIN.id || roleID === this.ADMIN.id
  },
  isSuperAdmin(roleID) {
    return roleID === this.SUPERADMIN.id
  }
};

const MODULES_INFO = {
  INCIDENT_MODULE: {
    id: 'INCIDENT_MODULE',
    displayName: 'Incidentes',
    roles: {
      INCIDENT_ADMIN: {
        id: 'INCIDENT_ADMIN',
        displayName: 'Administrador'
      },
      INCIDENT_HANDLER: {
        id: 'INCIDENT_HANDLER',
        displayName: 'Manejador'
      },
      INCIDENT_SOLVER: {
        id: 'INCIDENT_SOLVER',
        displayName: 'Solucionador'
      },
    },
  },
  GARBAGE_PICKUP_MODULE: {
    id: "GARBAGE_PICKUP_MODULE",
    displayName: "Recogida de Basura",
    roles: {
      GARBAGE_PICKUP_ADMIN: {
        id: 'GARBAGE_PICKUP_ADMIN',
        displayName: 'Administrador'
      },
    },
  },
  USERS_MODULE: {
    id: "USERS_MODULE",
    displayName: "Usuarios",
    roles: {
      USERS_ADMIN: {
        id: 'USERS_ADMIN',
        displayName: 'Administrador'
      },
    },
  },
  NEWS_MODULE: {
    id: "NEWS_MODULE",
    displayName: "Noticias",
    roles: {
      NEWS_ADMIN: {
        id: 'NEWS_ADMIN',
        displayName: 'Administrador'
      },
    },
  },
  PAYMENTS_MODULE: {
    id: "PAYMENTS_MODULE",
    displayName: "Pagos",
    roles: {
      PAYMENTS_ADMIN: {
        id: 'PAYMENTS_ADMIN',
        displayName: 'Administrador'
      },
    },
  },
  PLACES_MODULE: {
    id: "PLACES_MODULE",
    displayName: "Lugares",
    roles: {
      PLACES_ADMIN: {
        id: 'PLACES_ADMIN',
        displayName: 'Administrador'
      },
    },
  },
};

const PAYMENT_STATUS_MASK = {
  OK: 'APROBADO',
  NOT_AUTHORIZED: 'NO AUTORIZADO',
  BANK: 'EN BANCO',
  PENDING: 'PENDIENTE',
  CAPTURED: 'CAPTURADO',
  CREATED: 'CREADO',
  EXPIRED: 'EXPIRADO',
  FAILED: 'FALLO',
};

const NOTIFICATION_TYPES = {
  INCIDENT_NEW: {
    id: 'INCIDENT_NEW',
    displayName: 'Nuevo incidente',
  },
  INCIDENT_EDITED: {
    id: 'INCIDENT_EDITED',
    displayName: 'Incidente modificado',
  },
  INCIDENT_COMMENT_NEW: {
    id: 'INCIDENT_COMMENT_NEW',
    displayName: 'Nuevo comentario',
  },
  INCIDENT_COMMENT_EDITED: {
    id: 'INCIDENT_COMMENT_EDITED',
    displayName: 'Comentario modificado',
  },
  INCIDENT_HANDLER_NEW: {
    id: 'INCIDENT_HANDLER_NEW',
    displayName: 'Nuevo manejador',
  },
  INCIDENT_HANDLER_CHANGED: {
    id: 'INCIDENT_HANDLER_CHANGED',
    displayName: 'Cambio de manejador',
  },
  INCIDENT_SOLVER_NEW: {
    id: 'INCIDENT_SOLVER_NEW',
    displayName: 'Nuevo solucionador',
  },
  INCIDENT_SOLVER_CHANGED: {
    id: 'INCIDENT_SOLVER_CHANGED',
    displayName: 'Cambio de solucionador',
  },
};

const POST_STATUS = {
  publish: 'PUBLICADA',
  draft: 'BORRADOR',
  private: 'PRIVADA'
};

const EMAIL_REGEX = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;

const SORT_ASC = field => (current, next) => {
  if (current[field] > next[field])
    return 1;
  else if (current[field] < next[field])
    return -1;

  return 0;
};

const SORT_DESC = field => (current, next) => {
  if (next[field] > current[field])
    return 1;
  else if (next[field] < current[field])
    return -1;

  return 0;
}

const GENERIC_STATUS = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE'
};

module.exports = {
  sessionStorageKeys,
  incidentRoles,
  mapStyle,
  incidentStatus,
  INCIDENT_STATUS,
  SOLVER_STATUS,
  imageType,
  commentType,
  GLOBAL_ROLES,
  MODULES_INFO,
  EMAIL_REGEX,
  PAYMENT_STATUS_MASK,
  SORT_ASC,
  SORT_DESC,
  NOTIFICATION_TYPES,
  POST_STATUS,
  GENERIC_STATUS,
};
