import React from "react";
import { Route } from "react-router-dom";
import Incidents from "./Incidents";
import IncidentCreate from "./Create/IncidentCreate";
import IncidentDetails from "./Details/IncidentDetails";

function IncidentsContainer() {
  return (
    <div>
      <Route path="/incidents/incidents" exact component={Incidents} />
      <Route
        path="/incidents/incidents/create"
        exact
        component={IncidentCreate}
      />
      <Route
        path="/incidents/incidents/details/:id"
        exact
        component={IncidentDetails}
      />
    </div>
  );
}

export default IncidentsContainer;
