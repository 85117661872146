import React from "react";

// @material-ui/core components
import PaginatedTable from "components/CustomTable/PaginatedTable";
import { primaryColor } from "assets/jss/material-dashboard-react.jsx";
import CircularProgress from "@material-ui/core/CircularProgress";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";

// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import AuthService from "components/Services/AuthService";
import { Snackbar, withStyles, Button } from "@material-ui/core";

import { dateTimeFormat } from "utils/DateUtil.jsx";
import { POST_STATUS } from "variables/general.jsx";


const styles = () => ({
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  progress: {
    color: primaryColor,
    position: "absolute",
    top: "calc(50% - 4em)",
    left: "calc(50% - 4em)",
  },
});


class News extends React.Component {
  constructor(props) {
    super(props);
    this.AuthService = new AuthService();

    this.rawPostsResponse = {};
    this.postsCollectionBuffer = [];

    this.state = {
      loading: true,
      ajaxLoading: true,
      posts: [],
      snackError: {
        open: false,
        payload: null,
      },
    };
  }

  componentDidMount() {
    const fetchPosts = (amount_per_page, page) => {
      this.AuthService.authFetch(`/admin/news/get/all?amount_per_page=${amount_per_page}&page=${page}`)
        .then(resp => {
          this.rawPostsResponse = resp.result;

          const needToLoadMore = this.rawPostsResponse.currentPage < this.rawPostsResponse.totalPages;

          this.postsCollectionBuffer = this.postsCollectionBuffer
            .concat(this.rawPostsResponse.collection)
            .filter((e, i, o) => o.findIndex(f => f.id === e.id) === i);

          if (needToLoadMore)
            fetchPosts(amount_per_page, this.rawPostsResponse.currentPage + 1);

          const newState = {
            posts: this.postsCollectionBuffer,
          };

          if (!needToLoadMore)
            newState.ajaxLoading = false;

          if (this.state.loading)
            newState.loading = false;

          this.setState(newState);
        })
        .catch(reason => {
          console.error("Failed loading posts:", reason);
          let failMessage;

          if (this.postsCollectionBuffer.length) {
            if (this.postsCollectionBuffer.length < this.rawPostsResponse.totalEntries)
              failMessage = `Ocurrió un error cargando ${this.rawPostsResponse.totalEntries - this.postsCollectionBuffer.length} entradas`;
            else
              failMessage = 'Ocurrió un error cargando las noticias';
          }
          else
            failMessage = 'No se pudieron cargar las noticias... intente nuevamente más tarde';

          this.setState({
            loading: false,
            ajaxLoading: false,
            posts: this.postsCollectionBuffer,
            snackError: {
              open: true,
              payload: failMessage,
            },
          });
        });
    };

    // 100 is the max amount of results allowed per response
    fetchPosts(100, 1);
  }

  render() {
    const { posts, snackError, loading, ajaxLoading } = this.state;
    const { classes } = this.props;

    if (loading)
      return (<CircularProgress className={classes.progress} size={50} />);

    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>Lista de noticias</h4>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <Button
                    variant="outlined"
                    style={{ margin: "10px" }}
                    onClick={() => {
                      this.props.history.push('/news/create');
                    }}>
                    Agregar
                  </Button>
                </GridItem>

                <GridItem xs={12} sm={12} md={12}>
                  <PaginatedTable
                    loading={ajaxLoading}
                    headerList={[
                      'ID',
                      'Título',
                      'Contenido',
                      'Estado',
                      'Modificación'
                    ]}
                    tableContent={posts
                      .map(post => {
                        return {
                          ...post,
                          modDate: dateTimeFormat(
                            post.modDate
                              && post.modDate.includes('T')
                              && !post.modDate.endsWith('Z') ? `${post.modDate}Z` : post.modDate),
                          sumContent: post.sumContent
                            .replace(/<[^>]*>/g, '')
                            .replace(/&[^;]+;/g, ''),
                          status: POST_STATUS[post.status] || post.status,
                        }
                      })}
                    tableContentProps={[
                      'id', 'title', 'sumContent',
                      'status', 'modDate',
                    ]}
                    rowKeyPropName='id'
                    onRowClick={row => {
                      window.open(`/news/edit/${row.id}`, '_blank');
                    }}
                    fillEmptyRows
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>

        {snackError.open && (
          <Snackbar
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            open
            onClose={() => {
              this.setState({
                snackError: {
                  open: false,
                  payload: null,
                }
              });
            }}
            ContentProps={{
              "aria-describedby": "error-message-id",
            }}
            message={<span id="error-message-id">{snackError.payload}</span>}
          />
        )}
      </GridContainer>
    );
  }
}

export default withStyles(styles)(News);
