import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
//import CircularProgress from "@material-ui/core/CircularProgress";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";

import AuthService from "components/Services/AuthService";
import PaginatedTable2 from "../../components/CustomTable/PaginatedTable2";

import { primaryColor } from "assets/jss/material-dashboard-react.jsx";

import { dateTimeFormat } from "utils/DateUtil.jsx";
import { Button } from "@material-ui/core";

const styles = theme => ({
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  progress: {
    color: primaryColor,
    position: "absolute",
    top: "calc(50% - 4em)",
    left: "calc(50% - 4em)",
  },
  close: {
    width: theme.spacing.unit * 4,
    height: theme.spacing.unit * 4,
  },
});

class PlacesCategories extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      categories: [],
      loading: false,
      warningSnack: {
        open: false,
        payload: null
      }
    };

    this.AuthService = new AuthService();
  }

  handleErrorSnackbarClose = (_event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    this.setState({
      warningSnack: {
        open: false,
        payload: null
      }
    });
  };

  
  componentDidMount(){
    this.getData();
  }

  getData = (page = 0, count = 10, filter = '', sort = 'name', order = 'asc') => {

    this.setState({
      loading: true
    });

    this.AuthService.authFetch(`/admin/places/categoriespagination?page=${page}&count=${count}&filter=${filter}&sort=${sort}&order=${order}`)
      .then(res => {
        const { categories, totalCount } = res.result;
        this.setState({
          categories,
          totalCount,
          loading: false
        });
      })
      .catch(error => {
        console.error("error here: ", error); 
      });

  }

  render() {
    const { classes } = this.props;
    const { warningSnack, loading, categories, totalCount } = this.state;

    return (
      <div>
        {warningSnack.open && (
          <Snackbar
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            open
            onClose={this.handleErrorSnackbarClose}
            ContentProps={{
              "aria-describedby": "message-id",
            }}
            message={<span id="message-id">{warningSnack.payload}</span>}
            action={[
              <IconButton key="close" aria-label="Close" color="inherit" className={classes.close} onClick={this.handleErrorSnackbarClose}>
                <CloseIcon />
              </IconButton>,
            ]}
          />
        )}

        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="primary">
                <h4 className={classes.cardTitleWhite}>Lista de categorías</h4>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <Button
                      variant="outlined"
                      style={{ margin: "10px" }}
                      onClick={() => {
                        this.props.history.push('/places/categories/create');
                      }}>
                      Agregar
                    </Button>
                  </GridItem>

                  <GridItem xs={12} sm={12} md={12}>
                    <PaginatedTable2
                      headerList={[
                        'Nombre',
                        'Alias',
                        'Descripción',
                        'Fecha',
                        'Estado',
                      ]}
                      getData={this.getData}
                      totalCount={totalCount} 
                      loading={loading}
                      tableContent={categories.map(m => ({
                        ...m,
                        date: dateTimeFormat(new Date(m.date).toString()),
                        status: { ACTIVE: 'ACTIVO', INACTIVE: 'INACTIVO' }[m.status] || m.status,
                      }))}
                      tableContentProps={[
                        'name', 'headerTitle',
                        'description', 'date', 'status'
                      ]}
                      rowKeyPropName='id'
                      fillEmptyRows
                      onRowClick={row => {
                        window.open(`/places/categories/edit/${row.id}`, '_blank');
                      }}
                    />
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

export default withStyles(styles)(PlacesCategories);
