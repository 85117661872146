import React from "react";
import PropTypes from "prop-types";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import GridListTileBar from "@material-ui/core/GridListTileBar";
import CircularProgress from "@material-ui/core/CircularProgress";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import DeleteForeverRoundedIcon from "@material-ui/icons/DeleteForeverRounded";

import ImageDialog from "components/ImageDialog/ImageDialog.jsx";
import Button from "components/CustomButtons/Button.jsx";

import { storage } from "utils/Firebase";

import { imageType, sessionStorageKeys } from "variables/general";
import { primaryColor } from "assets/jss/material-dashboard-react.jsx";

const styles = () => ({
  wrapper: {
    width: "100px",
    position: "relative",
  },
  input: {
    display: "flex",
    padding: 0,
  },
  rootContent: {
    position: "relative",
  },
  coverLoading: {
    position: "absolute",
    height: "100%",
    width: "100%",
    zIndex: 1,
    backgroundColor: "#ddd",
    opacity: 0.3,
  },
  progress: {
    color: primaryColor,
    position: "absolute",
    top: "calc(50% - 4em)",
    left: "calc(50% - 4em)",
  },
});

class IncidentImagesGridList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      images: props.images,
      imagesUrls: [],
      currentImage: null,
      dialogOpen: false,
      updateError: null,
      updateLoading: false,
      errorSnackbarOpen: false,
      files: [],
      token: sessionStorage.getItem(sessionStorageKeys.token),
    };
  }

  static getDerivedStateFromProps(props) {
    return {
      images: props.images,
    };
  }

  componentDidMount() {
    this.getImage();
  }

  componentDidUpdate(prevProps) {
    if (this.props.images !== prevProps.images) {
      this.getImage();
    }
  }

  handleErrorSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    this.setState({ errorSnackbarOpen: false });
  };

  handleClickOpen = image => () => {
    this.setState({ dialogOpen: true, currentImage: image });
  };

  async asyncForEach(array, callback) {
    for (let index = 0; index < array.length; index++) {
      await callback(array[index]);
    }
  }

  getImage() {
    const { images } = this.state;

    if (images && typeof images !== "undefined") {
      const urlPromises = images.map(image => {
        return storage
          .refFromURL(image)
          .getDownloadURL()
          .then(url => {
            return url;
          });
      });

      Promise.all(urlPromises).then(imagesUrls =>
        this.setState({
          imagesUrls,
        })
      );
    }
  }

  handleAddFile = event => {
    var files = this.state.files;

    Array.from(event.target.files).forEach(file => {
      files.push(file);
    });

    this.setState({ files: files });
  };

  handleRemoveFile = fileToRemove => () => {
    var files = this.state.files.filter(file => file !== fileToRemove);

    this.setState({ files: files });
  };

  handleSaveImages = () => {
    this.props.uploadImagesHandler([...this.state.files]);

    this.setState({ files: [] });
  };

  render() {
    const { classes, canAttach } = this.props;
    const { imagesUrls, dialogOpen, currentImage, updateError, updateLoading, files } = this.state;

    if (imagesUrls.length > 0) {
      return (
        <div className={classes.rootContent}>
          {updateError && (
            <Snackbar
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              open={this.state.errorSnackbarOpen}
              onClose={this.handleErrorSnackbarClose}
              ContentProps={{
                "aria-describedby": "message-id",
              }}
              message={<span id="message-id">{updateError.message}</span>}
              action={[
                <IconButton key="close" aria-label="Close" color="inherit" className={classes.close} onClick={this.handleErrorSnackbarClose}>
                  <CloseIcon />
                </IconButton>,
              ]}
            />
          )}
          {updateLoading && <div className={classes.coverLoading} />}
          <GridList cellHeight={160} className={classes.gridList} cols={4}>
            {imagesUrls.map(img => (
              <GridListTile key={img} cols={1}>
                <img src={img} alt={img} onClick={this.handleClickOpen(img)} />
                <GridListTileBar
                  actionIcon={
                    <IconButton
                      onClick={() => {
                        this.props.deleteImageHandler(img);
                      }}>
                      <DeleteForeverRoundedIcon color="error" />
                    </IconButton>
                  }
                />{" "}
              </GridListTile>
            ))}
          </GridList>
          <ImageDialog open={dialogOpen} image={currentImage} closeDialog={() => this.setState({ dialogOpen: false })} />
          {canAttach && (
            <div>
              <input accept="image/*" className={classes.input} id="contained-button-file-tab" multiple type="file" style={{ display: "none" }} onChange={this.handleAddFile} />
              <label htmlFor="contained-button-file-tab">
                <Button variant="contained" component="span" color="primary" disabled={updateLoading} className={classes.button}>
                  Seleccionar imagenes
                </Button>
              </label>
              <List>
                {files.map((file, index) => (
                  <ListItem key={index}>
                    <ListItemText primary={file.name} />
                    <ListItemSecondaryAction>
                      <IconButton aria-label="Delete" color="secondary" onClick={this.handleRemoveFile(file)}>
                        <CloseIcon />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
              </List>
              {files.length > 0 && (
                <Button variant="contained" component="span" color="primary" disabled={updateLoading} className={classes.button} onClick={this.handleSaveImages}>
                  Salvar imagenes
                </Button>
              )}
            </div>
          )}
          {updateLoading && <CircularProgress className={classes.progress} size={50} thickness={5} />}
        </div>
      );
    } else {
      return (
        <div
          style={{
            position: "relative",
            width: "100%",
            textAlign: "center",
            margin: "auto",
          }}
          className={classes.rootContent}>
          {updateError && (
            <Snackbar
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              open={this.state.errorSnackbarOpen}
              onClose={this.handleErrorSnackbarClose}
              ContentProps={{
                "aria-describedby": "message-id",
              }}
              message={<span id="message-id">{updateError.message}</span>}
              action={[
                <IconButton key="close" aria-label="Close" color="inherit" className={classes.close} onClick={this.handleErrorSnackbarClose}>
                  <CloseIcon />
                </IconButton>,
              ]}
            />
          )}
          {updateLoading && <div className={classes.coverLoading} />}
          <span style={{ padding: "16px" }}>No hay imágenes adjuntas.</span>
          {canAttach && (
            <div>
              <input accept="image/*" className={classes.input} id="contained-button-file-tab" multiple type="file" style={{ display: "none" }} onChange={this.handleAddFile} />
              <label htmlFor="contained-button-file-tab">
                <Button variant="contained" component="span" color="primary" disabled={updateLoading} className={classes.button}>
                  Seleccionar imagenes
                </Button>
              </label>
              <List>
                {files.map((file, index) => (
                  <ListItem key={index}>
                    <ListItemText primary={file.name} />
                    <ListItemSecondaryAction>
                      <IconButton aria-label="Delete" color="secondary" onClick={this.handleRemoveFile(file)}>
                        <CloseIcon />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
              </List>
              {files.length > 0 && (
                <Button variant="contained" component="span" color="primary" disabled={updateLoading} className={classes.button} onClick={this.handleSaveImages}>
                  Salvar imagenes
                </Button>
              )}
            </div>
          )}
          {updateLoading && <CircularProgress className={classes.progress} style={{ marginTop: "16px" }} size={50} thickness={5} />}
        </div>
      );
    }
  }
}

IncidentImagesGridList.propTypes = {
  classes: PropTypes.object.isRequired,
  canAttach: PropTypes.bool.isRequired,
  incidentId: PropTypes.number.isRequired,
  type: PropTypes.oneOf(Object.keys(imageType)),
  images: PropTypes.array,
  onUploaded: PropTypes.func,
  deleteImageHandler: PropTypes.func,
  uploadImagesHandler: PropTypes.func,
};

export default withStyles(styles)(IncidentImagesGridList);
