import React from "react";
import PropTypes from "prop-types";
import uuid from "uuid";

import Table from "@material-ui/core/Table";
import withStyles from "@material-ui/core/styles/withStyles";
import Grid from "@material-ui/core/Grid";
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Button from "components/CustomButtons/Button.jsx";

import CircularProgress from "@material-ui/core/CircularProgress";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { Redirect } from "react-router-dom";

import AuthService from "components/Services/AuthService";

const styles = theme => ({
  progress: {
    margin: theme.spacing.unit * 2,
  },
});

const LOCALIZED_DAYS = {
  monday: "Lunes",
  tuesday: "Martes",
  wednesday: "Miércoles",
  thursday: "Jueves",
  friday: "Viernes",
  saturday: "Sábado",
  sunday: "Domingo",
};

class GarbagePickupRanges extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      navigateToCreate: false,
      navigateToEdit: null,
      garbagePickupRanges: null,
      error: null,
    };
    this.AuthService = new AuthService();
  }

  componentDidMount() {
    this.setState({ loading: true });
    this.AuthService.authFetch("/admin/garbage-pickup-range")
      .then(response => {
        const dayCheck = {
          monday: [],
          tuesday: [],
          wednesday: [],
          thursday: [],
          friday: [],
          saturday: [],
          sunday: [],
        };

        response.result.forEach(res => {
          dayCheck[res.name].push(res);
        });

        this.setState({
          loading: false,
          garbagePickupRanges: dayCheck,
        });
      })
      .catch(error => this.setState({ loading: false, error: error }));
  }

  groupBuild(day, groups) {
    const group = groups[day];
    return (
      <Table key={uuid()}>
        <TableHead>
          <TableRow>
            <TableCell>
              <h3>{LOCALIZED_DAYS[day]}</h3>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {group.map(garbagePickupRange => {
            return (
              <TableRow
                key={garbagePickupRange.id}
                hover
                onClick={() => {
                  this.setState({ navigateToEdit: garbagePickupRange });
                }}>
                <TableCell>
                  {garbagePickupRange.startTime} - {garbagePickupRange.endTime}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    );
  }

  render() {
    const { classes } = this.props;
    const { garbagePickupRanges } = this.state;
    const orderedNames = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"];

    if (this.state.navigateToCreate === true) {
      return <Redirect push to="/garbage-pickup/ranges/create" />;
    }

    if (this.state.navigateToEdit !== null) {
      return (
        <Redirect
          push
          to={{
            pathname: "/garbage-pickup/ranges/edit",
            state: { garbagePickupRange: this.state.navigateToEdit },
          }}
        />
      );
    }
    if (this.state.loading === true) {
      return (
        <Grid container spacing={8} justify="center">
          <Grid item xs={3} sm={3} md={3}>
            <CircularProgress className={classes.progress} size={50} />
          </Grid>
        </Grid>
      );
    }

    return (
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="primary">
                <h4 className={classes.cardTitleWhite}>Listado de rangos de recogida de basura</h4>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <Button
                      color="primary"
                      onClick={() => {
                        this.setState({
                          navigateToCreate: true,
                        });
                      }}>
                      Crear
                    </Button>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                    {orderedNames.map(day => {
                      return this.groupBuild(day, garbagePickupRanges);
                    })}
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}
GarbagePickupRanges.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(GarbagePickupRanges);
