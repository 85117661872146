import React, { Component } from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";

import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { CircularLoader } from "../components/CustomLoader/Loaders";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";

import logo from "../assets/img/logo.png";
import backgroundImage from "../assets/img/login_background.jpg";

import AuthService from "components/Services/AuthService";
import { auth } from "../utils/Firebase";
import { primaryColor } from "assets/jss/material-dashboard-react.jsx";

const styles = theme => ({
  root: {
    background: primaryColor,
    backgroundImage: `url(${backgroundImage})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    height: "100vh",
    position: "relative",
  },
  paperContent: {
    paddingTop: theme.spacing.unit * 8,
  },
  paperRoot: {
    maxWidth: "480px",
    margin: "0 auto",
    alignItems: "center",
    padding: `${theme.spacing.unit * 4}px`,
  },
  logoImage: {
    textAlign: "center",
    marginBottom: "60px",
    height: "50px",
    color: "transparent",
  },
  img: {
    width: "90px",
  },
  form: {
    width: "100%",
    marginTop: theme.spacing.unit,
  },
  submit: {
    marginTop: theme.spacing.unit * 3,
    background: "#63c76a",
    height: "45px",
    "&:hover": {
      background: "#59bd60",
    },
  },
  forgot: {
    marginTop: theme.spacing.unit * 2,
    fontSize: '10pt'
  }
});

class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      errorSnackbarOpen: false,
      error: null,
      formData: {
        email: "",
        password: "",
      },
    };

    this.AuthService = new AuthService();
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleErrorSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    this.setState({ errorSnackbarOpen: false });
  };

  handleChange = event => {
    const { formData } = this.state;
    formData[event.target.name] = event.target.value;
    this.setState({ formData });
  };

  handleSubmit = event => {
    event.preventDefault();

    this.setState({
      loading: true,
      error: null,
    });

    const formData = this.state.formData;

    this.AuthService.login(formData.email, formData.password)
      .then(res => {
        const jwt = res.result.jwt;

        this.setState({
          loading: false,
          error: null,
        });

        auth
          .signInWithCustomToken(jwt.token.replace("JWT ", ""))
          .then(() => {
            this.props.history.push("/");
          })
          .catch(error => {
            throw error;
          });
      })
      .catch(error => {
        const message = error.response.status === 401 ? "Nombre de usuario o contraseña inválido" : "Ups!!! Ocurrió un problema intentando hacer login.";

        this.setState({
          loading: false,
          errorSnackbarOpen: true,
          error: {
            message: message,
          },
        });
      });
  };

  render() {
    if (this.AuthService.loggedIn()) {
      this.props.history.push("/");
    }

    const { classes } = this.props;
    const { formData } = this.state;

    return (
      <div className={classes.root}>
        {this.state.error && (
          <Snackbar
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            open={this.state.errorSnackbarOpen}
            onClose={this.handleErrorSnackbarClose}
            ContentProps={{
              "aria-describedby": "message-id",
            }}
            message={<span id="message-id">{this.state.error.message}</span>}
            action={[
              <IconButton key="close" aria-label="Close" color="inherit" className={classes.close} onClick={this.handleErrorSnackbarClose}>
                <CloseIcon />
              </IconButton>,
            ]}
          />
        )}
        {this.state.loading && <CircularLoader />}
        <div className={classes.paperContent}>
          <Paper className={classes.paperRoot} elevation={1}>
            <div className={classes.logoImage}>
              <img src={logo} alt="logo" className={classes.img} />
            </div>
            <ValidatorForm className={classes.form} onSubmit={this.handleSubmit}>
              <TextValidator
                name="email"
                label="Correo"
                value={formData.email}
                onChange={this.handleChange}
                validators={["required", "isEmail"]}
                errorMessages={["campo requerido", "email no valido"]}
                fullWidth
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
                type="email"
                autoComplete="email"
                autoFocus
              />
              <br />
              <TextValidator
                name="password"
                label="Clave"
                value={formData.password}
                onChange={this.handleChange}
                validators={["required"]}
                errorMessages={["campo requerido"]}
                fullWidth
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
                type="password"
                autoComplete="current-password"
              />
              <br />
              <div style={{ textAlign: 'center' }}>
                <Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit}>
                  Iniciar sesión
                </Button>
                <Button color="primary" className={classes.forgot} onClick={() => {
                  this.props.history.push('/forgot');
                }}>
                  ¿Olvidó su clave?
                </Button>
              </div>
            </ValidatorForm>
          </Paper>
        </div>
      </div>
    );
  }
}

Login.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Login);
