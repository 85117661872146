import { storage } from "./Firebase";
import { asyncForEach } from "./HubUtil";
import AuthService from "components/Services/AuthService";

const authService = new AuthService();

export async function uploadFirebase(images, incidentId) {
  const urls = [];

  await asyncForEach(images, async image => {
    const fileRoute = `incidentsImages/${incidentId}__${Date.now()}__.jpg`;
    const storageRef = storage.ref().child(fileRoute);

    await storageRef.put(image);

    const url = storageRef.toString();
    urls.push(url);
  });

  return urls;
}

export async function deleteFromFirebase(image, incident) {
  const imageURL = window.decodeURIComponent(image);
  const firstIndex = imageURL.indexOf("incidentsImages/");
  const lastIndex = imageURL.indexOf("__.");
  const imageId = imageURL.substring(firstIndex, lastIndex);

  // Create a reference to the file to delete
  const incidentObj = {
    pictureUrl: [],
    categoryClosedPictures: [],
  };

  if (incident.pictureUrl.length > 0) {
    incidentObj.pictureUrl = incident.pictureUrl.filter(picture => {
      return picture.indexOf(imageId) === -1;
    });
  } else {
    incidentObj.pictureUrl = incident.pictureUrl;
  }

  if (incident.categoryClosedPictures.length > 0) {
    incidentObj.categoryClosedPictures = incident.categoryClosedPictures.filter(picture => {
      return picture.indexOf(imageId) === -1;
    });
  } else {
    incidentObj.categoryClosedPictures = incident.categoryClosedPictures;
  }

  return authService
    .authFetch(`/admin/incidents/incident/${incident.id}`, {
      method: "PUT",
      body: JSON.stringify(incidentObj),
    })
    .then(res => {
      const storageRef = storage.ref().child(`${imageId}__.jpg`);
      storageRef.delete();
      return { error: null, incident: res.result };
    })
    .catch(error => {
      return { error, incident: null };
    });
}
