import React from "react";
import { Redirect } from "react-router-dom";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
//import CircularProgress from "@material-ui/core/CircularProgress";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import CoreButton from "@material-ui/core/Button";

// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Button from "components/CustomButtons/Button.jsx";

// incident components
import IncidentFilter from "./IncidentFilter.jsx";

import AuthService from "components/Services/AuthService";

import { primaryColor, defaultFont } from "assets/jss/material-dashboard-react.jsx";
import InfoIcon from '@material-ui/icons/Info';

import { INCIDENT_STATUS, GLOBAL_ROLES, SORT_DESC } from "variables/general";
import { dateTimeFormat } from "../../../utils/DateUtil.jsx";

import PaginatedTable2 from "components/CustomTable/PaginatedTable2";

const styles = theme => ({
  container: {
    width: "100%",
  },
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  expandableTitle: {
    fontWeight: "300",
    color: "#000",
  },
  expandableSummary: {
    "& div": {
      margin: "0 !important",
    },
  },
  incidentTableHeader: {
    color: primaryColor,
  },
  table: {
    marginBottom: "0",
    width: "100%",
    maxWidth: "100%",
    backgroundColor: "transparent",
    borderSpacing: "0",
    borderCollapse: "collapse",
  },
  tableHeadCell: {
    color: "inherit",
    ...defaultFont,
    fontSize: "1em",
  },
  tableCell: {
    ...defaultFont,
    lineHeight: "1.42857143",
    padding: "12px 8px",
    verticalAlign: "middle",
  },
  tableResponsive: {
    width: "100%",
    marginTop: theme.spacing.unit * 3,
    overflowX: "auto",
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: "100%",
  },
  formControl: {
    margin: theme.spacing.unit,
    width: "100%",
  },
  progress: {
    color: primaryColor,
    position: "absolute",
    top: "calc(50% - 4em)",
    left: "calc(50% - 4em)",
  },
  close: {
    width: theme.spacing.unit * 4,
    height: theme.spacing.unit * 4,
  },
  infoSnack: {
    backgroundColor: theme.palette.primary.dark,

    '& #refresh-popup': {
      display: 'flex',

      '& #refresh-popup-icon': {
        fontSize: 20,
        opacity: 0.9,
        marginRight: theme.spacing.unit
      }
    }
  }
});

class Incidents extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {
      navigateToCreate: false,
      errorSnackbarOpen: true,
      incidents: [],
      error: null,
      isLoaded: false,
      notiSnack: {
        open: false,
        paylaod: null,
        ignoredValue: 0,
        currentValue: 0,
        disabledButton: false,
      },
      warningSnack: {
        open: false,
        payload: null,
      },
      filteringResults: false,
      totalCount: 0,
      loading: false,
      handlerList : [],
      solverList : [],
      filterData: null
    };

    this.AuthService = new AuthService();
  }

  statusMapper = status => ({
    OPEN: "En espera de revisión",
    WITH_HANDLER: "En manos de manejador",
    WITH_SOLVER: "En manos de solucionador",
    IN_PROGRESS: "En progreso",
    SOLUTION_COMPLETED: "Solución completada",
    CLOSED: "Completado",
  }[status] || 'N/A');

  getData = (page = 0, count = 10, filter = '', sort = 'createdAt', order = 'desc') => {

    this.setState({
      loading: true
    });

    this.AuthService.authFetch(`/admin/incidents/pagination?page=${page}&count=${count}&filter=${filter}&sort=${sort}&order=${order}&filterData=${this.state.filterData}`)
      .then(res => {
        const { incidents, totalCount } = res.result;
        this.setState({
          incidents,
          totalCount,
          loading: false
        });
      })
      .catch(error => {
        console.error("error here: ", error); 
      });

  }

  handleSnackbarClose = snack => (_event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    const { notiSnack } = this.state;

    const newState = {};

    if (snack === 'new')
      newState.notiSnack = {
        open: false,
        payload: null,
        ignoredValue: notiSnack.currentValue,
        currentValue: 0,
        disabledButton: false,
      };
    else if (snack === 'warn')
      newState.warningSnack = {
        open: false,
        payload: null,
      };
    else
      newState.errorSnackbarOpen = false;

    this.setState(newState);
  };

  onDownloadClicked = () => {

    this.AuthService.authFetch(`/admin/incidents/pagination?filterData=${this.state.filterData}`)
      .then(res => {
        const { incidents } = res.result;
        
        const currentDate = new Date();

        const dateStr = `${(currentDate.getMonth() < 9 ? '0' : '')
          + (currentDate.getMonth() + 1)}-${(currentDate.getDate() < 10 ? '' : '')
          + currentDate.getDate()}-${currentDate.getFullYear()}`;

        const timeStr = `${(currentDate.getHours() < 10 ? '0' : '')
          + currentDate.getHours()}_${(currentDate.getMinutes() < 10 ? '0' : '')
          + currentDate.getMinutes()}`;

        /////////////////////

        let counter = 0;
        const columnCollection = [
          'ID',
          'Título',
          'Fecha de creación',
          'Categoría',
          'Reportado por',
          'Manejador',
          'Solucionador',
          'Estatus',
          'Categoría de cierre',
          'Fecha de cierre',
        ];

        const getCol = () => columnCollection[(counter++) % columnCollection.length];

        const data = [columnCollection.map(m => `"${m}"`).join(',')]
          .concat(incidents
            .map(m => ({
              [getCol()]: `"${m.id}"`,
              [getCol()]: `"${m.title || 'N/A'}"`,
              [getCol()]: `"${dateTimeFormat(m.createdAt)}"`,
              [getCol()]: `"${m.category.name || 'N/A'}"`,
              [getCol()]: `"${m.user.displayName || 'N/A'}"`,
              [getCol()]: `"${!m.handlers.length ? 'N/A' : m.handlers.map(s => s.displayName).join(', ')}"`,
              [getCol()]: `"${!m.solver.length ? 'N/A' : m.solver.map(s => s.displayName).join(', ')}"`,
              [getCol()]: `"${INCIDENT_STATUS[m.status] || 'N/A'}"`,
              [getCol()]: `"${m.categoryClosed.name || 'N/A'}"`,
              [getCol()]: `"${m.closedDate ? dateTimeFormat(m.closedDate) : 'N/A'}"`,
            })).map(m => columnCollection.map(field => m[field]).join(',')))
          .join('\n');

        const dataBinary = new Blob([data], { type: 'text/csv' });
        const dataURL = URL.createObjectURL(dataBinary);

        /////////////////////

        const downloadElemID = 'pseudo-download-filter';
        let a = document.querySelector(`a#${downloadElemID}`);

        if (!a) {
          a = document.createElement('a');
          a.id = downloadElemID;
          a.style.display = 'none';
          document.body.appendChild(a);
        }

        a.download = `reporte-incidentes-${dateStr}-${timeStr}.csv`;
        a.href = dataURL;

        a.click();

        setTimeout(() => {
          window.URL.revokeObjectURL(dataURL);
        }, 0);

    })
    .catch(error => {
      console.error("error here: ", error); 
    });
  };

  handleFilterIncidents = filterData => {
    this.setState({
      filterData : encodeURIComponent(JSON.stringify(filterData))
    },() => {
      this.getData();
    });
  };

  cleanFilter = () => {
    this.setState({
      filterData : null
    }, () => {
      this.getData();
    });
  }

  handleRefreshIncidents = () => {
    const { notiSnack } = this.state;

    this.setState({
      notiSnack: { ...notiSnack, disabledButton: true }
    })

    this.AuthService.authFetch(`/admin/incidents/last/${notiSnack.currentValue}`)
      .then(res => {
        const { incidents } = this.state;
        const newIncidents = res.result
          .concat(incidents)
          .filter((e, i, o) => o.findIndex(f => f.id === e.id) === i)
          .sort(SORT_DESC('id'));

        this.setState({
          incidents: newIncidents,
          notiSnack: {
            open: false,
            paylaod: null,
            ignoredValue: 0,
            currentValue: 0,
            disabledButton: false,
          }
        })
      })
      .catch(reason => {
        console.log('Failed getting last incidents', reason);
        this.setState({
          warningSnack: {
            open: true,
            payload: 'Ocurrió un problema cargando los nuevos incidentes... intente recargar la página si el problema persiste',
          },
          notiSnack: {
            open: false,
            paylaod: null,
            ignoredValue: notiSnack.currentValue,
            currentValue: 0,
            disabledButton: false,
          }
        })
      });
  }

  componentDidMount() {
    const intervalProcess = () => {
      this.AuthService.authFetch("/admin/incidents/amount")
        .then(res => {
          const { notiSnack, incidents } = this.state;
          let newEntries = res.result - incidents.length;
          newEntries = newEntries < 0 ? 0 : newEntries;
          const text = newEntries === 1 ? ' registro nuevo' : ' registros nuevos';

          if (newEntries > notiSnack.currentValue && notiSnack.ignoredValue !== newEntries)
            this.setState({
              notiSnack: {
                open: true,
                paylaod: (<span>Hay <b>{newEntries}</b> {text}!</span>),
                ignoredValue: 0,
                currentValue: newEntries,
                disabledButton: false,
              }
            })
        })
        .catch(reason => {
          console.log('Failed getting incident count', reason);
        });
    }

    this.getData();

    this.AuthService.authFetch("/admin/incidents/extradata")
    .then(res => {
      const { handlers, solver } = res.result;
      
      const sortList = (prev, next) => {
        const prevLabel = prev.label.toLowerCase();
        const nextLabel = next.label.toLowerCase();
        if (prevLabel > nextLabel)
          return 1;
        else if (prevLabel < nextLabel)
          return -1;
        else
          return 0;
      };

      this.setState({
        handlerList : handlers.map(m => ({ label: m.displayName, value: m.id })).sort(sortList),
        solverList : solver.map(m => ({ label: m.displayName, value: m.id })).sort(sortList)
      });

    })
    .catch(reason => {
      console.log('Failed getting incident count', reason);
    });

    const profile = this.AuthService.getProfile();
    if (!document.__verifyIncidentAmount && GLOBAL_ROLES.isAdmin(profile.user.role)) {
      document.__verifyIncidentAmount = true;

      setInterval(() => {
        if (window.location.pathname === '/incidents/incidents'
          && this.state.isLoaded
          && !this.state.filteringResults)
          intervalProcess();
      }, 30000);
    }
  }

  render() {
    const { classes } = this.props;
    const { error, incidents, totalCount, loading, navigateToCreate, notiSnack, warningSnack } = this.state;

    if (navigateToCreate === true) {
      return <Redirect push to="/incidents/incidents/create" />;
    }

    if (error) {
      return (
        <div>
          <Snackbar
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            open={this.state.errorSnackbarOpen}
            onClose={this.handleSnackbarClose('error')}
            ContentProps={{
              "aria-describedby": "message-id",
            }}
            message={<span id="message-id">{error.message}</span>}
            action={[
              <IconButton key="close" aria-label="Close" color="inherit" className={classes.close} onClick={this.handleSnackbarClose('error')}>
                <CloseIcon />
              </IconButton>,
            ]}
          />
        </div>
      );
    } else {
      return (
        <div>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <IncidentFilter
                classes={classes}
                downloadIncidentsHandler={this.onDownloadClicked}
                filterIncidentsHandler={this.handleFilterIncidents}
                handlerList={this.state.handlerList}
                solverList={this.state.solverList}
                cleanFilter={this.cleanFilter}
              />
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader color="primary">
                  <h4 className={classes.cardTitleWhite}>Listado de incidentes</h4>
                </CardHeader>
                <CardBody>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <Button
                        color="primary"
                        onClick={() => {
                          this.setState({
                            navigateToCreate: true,
                          });
                        }}>
                        Crear
                      </Button>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                      <PaginatedTable2
                        headerList={[
                          '#',
                          'Título',
                          'Categoría',
                          'Descripción',
                          'Fecha',
                          'Reporte',
                          'Manejador',
                          'Solucionador',
                          'Estatus'
                        ]}
                        initialOrder={'desc'}
                        getData={this.getData}
                        totalCount={totalCount} 
                        loading={loading}
                        tableContent={incidents
                          .map(incident => ({
                            id: (incident.id) ? incident.id : "",
                            title: (incident.title) ? incident.title : "",
                            category: (incident.category) ? (incident.category.name) ? incident.category.name : "" : "",
                            desc: (incident.description) ? incident.description.substring(0, 50).trim() + (incident.description.length >= 50 ? "..." : "") : "",
                            date: (incident.createdAt) ? dateTimeFormat(incident.createdAt) : "",
                            reporter: (incident.user) ? (incident.user.displayName) ? incident.user.displayName : "" : "",
                            handlers: incident.handlers && incident.handlers.length ?
                              incident.handlers.map(m => m.displayName).join(', ') :
                              'N/A',
                            solvers: incident.solver && incident.solver.length ?
                              incident.solver.map(m => m.displayName).join(', ') :
                              'N/A',
                            status: (incident.status) ? this.statusMapper(incident.status) : ""
                          }))}
                        onRowClick={(row) => {
                          window.open(`/incidents/incidents/details/${row.id}`, '_blank')
                        }}
                        tableContentProps={[
                          'id', 'title', 'category',
                          'desc', 'date', 'reporter',
                          'handlers', 'solvers', 'status',
                        ]}
                        rowKeyPropName='id'
                        fillEmptyRows
                      />
                    </GridItem>
                  </GridContainer>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>

          {warningSnack.open && (
            <Snackbar
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              open
              onClose={this.handleSnackbarClose('warn')}
              ContentProps={{
                "aria-describedby": "warning-popup",
              }}
              style={{ marginTop: '10px' }}
              message={<span id="warning-popup">{warningSnack.payload}</span>}
              action={[
                <IconButton key="close" aria-label="Close" color="inherit" className={classes.close} onClick={this.handleSnackbarClose('warn')}>
                  <CloseIcon />
                </IconButton>,
              ]}
            />
          )}

          {notiSnack.open && (
            <Snackbar
              anchorOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              open
              onClose={this.handleSnackbarClose('new')}
              ContentProps={{
                "aria-describedby": "refresh-popup",
                className: classes.infoSnack
              }}
              style={{ marginTop: '10px' }}
              message={
                <span id="refresh-popup">
                  <InfoIcon id="refresh-popup-icon" />
                  <span>{notiSnack.paylaod}</span>
                </span>
              }
              action={[
                <CoreButton variant="text" key="load" color="inherit" size="small" onClick={this.handleRefreshIncidents} disabled={notiSnack.disabledButton}>
                  Cargar
                </CoreButton>,
                <IconButton key="close" aria-label="Close" color="inherit" className={classes.close} onClick={this.handleSnackbarClose('new')}>
                  <CloseIcon />
                </IconButton>,
              ]}
            />
          )}
        </div>
      );
    }
  }
}

export default withStyles(styles)(Incidents);
