import React from "react";
import { Route } from "react-router-dom";
import GarbagePickups from "./GarbagePickups";
import GarbagePickupEdit from "./GarbagePickupEdit";

function GarbagePickupContainer() {
  return (
    <div>
      <Route path="/garbage-pickup/pickups" exact component={GarbagePickups} />
      <Route path="/garbage-pickup/pickups/edit" exact component={GarbagePickupEdit} />
    </div>
  );
}

export default GarbagePickupContainer;
