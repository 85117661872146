import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

// @material-ui/core components
import Grid from "@material-ui/core/Grid";

import Button from "components/CustomButtons/Button.jsx";

const styles = theme => ({
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 400
  }
});

class IncidentImage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      files: []
    };
  }

  handleAddFile = event => {
    var files = this.state.files;

    Array.from(event.target.files).forEach(file => {
      files.push(file);
    });

    this.setState({ files: files });
    this.props.onFileChange(files);
  };

  handleRemoveFile = fileToRemove => () => {
    var files = this.state.files.filter(file => file !== fileToRemove);

    this.setState({ files: files });
    this.props.onFileChange(files);
  };

  render() {
    const { files } = this.state;
    const { classes } = this.props;

    return (
      <Grid container alignItems="center">
        <Grid item xs={12} sm={12} md={6}>
          <div
            style={{
              position: "relative",
              width: "100%",
              margin: "auto"
            }}
          >
            <input
              accept="image/*"
              className={classes.input}
              id="contained-button-file"
              multiple
              type="file"
              style={{ display: "none" }}
              onChange={this.handleAddFile}
            />
            <label htmlFor="contained-button-file">
              <Button
                variant="contained"
                component="span"
                color="primary"
                className={classes.button}
              >
                Seleccionar imagenes
              </Button>
            </label>
            <List>
              {files.map((file, index) => (
                <ListItem key={index}>
                  <ListItemText primary={file.name} />
                  <ListItemSecondaryAction>
                    <IconButton
                      aria-label="Delete"
                      color="secondary"
                      onClick={this.handleRemoveFile(file)}
                    >
                      <CloseIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </List>
          </div>
        </Grid>
      </Grid>
    );
  }
}

IncidentImage.propTypes = {
  classes: PropTypes.object.isRequired,
  onFileChange: PropTypes.func.isRequired
};

export default withStyles(styles)(IncidentImage);
