import React from "react";
import { CircularProgress, withStyles, Snackbar } from "@material-ui/core";
import { primaryColor } from "assets/jss/material-dashboard-react.jsx";

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Button from "components/CustomButtons/Button.jsx";
import PaginatedTable from "../../../components/CustomTable/PaginatedTable";

import AuthService from "components/Services/AuthService";
import { incidentRoles, GLOBAL_ROLES } from "variables/general";
import Redirect from "react-router-dom/Redirect";

const styles = {
  progress: {
    color: primaryColor,
    position: "absolute",
    top: "calc(50% - 4em)",
    left: "calc(50% - 4em)"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  }
};

class Template extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      templates: [],
      goToCreate: false,
      editEntry: null,
      isLoaded: false,
      page: 0,
      rowsPerPage: 10,
      snackWarning: {
        open: false,
        payload: null
      }
    };

    this.AuthService = new AuthService();
  }

  componentDidMount() {
    this.AuthService.authFetch("/admin/template")
      .then(res => {
        const profile = this.AuthService.getProfile();
        var templates = [];

        const incidentModule = profile.user.modules.find(m => m.name === "INCIDENT_MODULE");

        if (GLOBAL_ROLES.isAdmin(profile.user.role)
          || (incidentModule && incidentModule.localRole === incidentRoles.INCIDENT_ADMIN))
          templates = res.result;

        this.setState({
          isLoaded: true,
          templates
        });
      })
      .catch(error => {
        this.setState({
          isLoaded: true,
          snackWarning: {
            open: true,
            payload: error.message
          },
        });
      });
  }

  render() {
    const { classes } = this.props;
    const { page, rowsPerPage } = this.state;

    if (!this.state.isLoaded)
      return (<CircularProgress className={classes.progress} size={50} />);
    else if (this.state.goToCreate)
      return <Redirect push to="/incidents/templates/create" />;
    else if (this.state.editEntry)
      return <Redirect push to={{
        pathname: '/incidents/templates/edit/',
        state: { template: this.state.editEntry }
      }} />;

    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>Listado de plantillas</h4>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <Button
                    color="primary"
                    onClick={() => {
                      this.setState({ goToCreate: true });
                    }}>
                    Crear
                  </Button>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <PaginatedTable
                    headerList={[
                      'Nombre',
                      'Contenido',
                      'Propietario',
                      'Estado'
                    ]}
                    tableContent={this.state.templates.map(m => ({
                      id: m.id,
                      name: m.name,
                      description: m.description.substr(0, 50) + (m.description.length > 50 ? '...' : ''),
                      user: m.user.displayName,
                      status: { ACTIVE: 'Habilitada', INACTIVE: 'Deshabilitada' }[m.status]
                    }))}
                    tableContentProps={[
                      'name', 'description',
                      'user', 'status'
                    ]}
                    rowKeyPropName='id'
                    fillEmptyRows
                    page={page}
                    rowsPerPage={rowsPerPage}
                    onRowClick={(row) => {
                      this.setState({ editEntry: this.state.templates.find(f => f.id === row.id) });
                    }}
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>

        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          open={this.state.snackWarning.open}
          onClose={() => {
            this.setState({
              snackWarning: {
                payload: null,
                open: false
              }
            });
          }}
          ContentProps={{
            "aria-describedby": "message-id",
          }}
          message={<span id="message-id">{this.state.snackWarning.payload}</span>}
        />
      </GridContainer>
    );
  }
}

export default withStyles(styles)(Template);