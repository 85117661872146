/* eslint-disable */
import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import Header from "components/Header/Header";
import Footer from "components/Footer/Footer";
import Sidebar from "components/Sidebar/Sidebar";
import AuthService from "components/Services/AuthService";

import incidentsDashboardRoutes from "routes/incidentsDashboard";

import dashboardStyle from "assets/jss/material-dashboard-react/layouts/dashboardStyle";

import { incidentRoles, GLOBAL_ROLES } from "variables/general";

import logo from "assets/img/cityapplogo.png";

class IncidentsDashboard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      mobileOpen: false,
      hideBadge: true,
    };

    this.AuthService = new AuthService();
    this.filteredRoutes = incidentsDashboardRoutes;
    const profile = this.AuthService.getProfile();

    const incidentModule = profile.user.modules.find(m => m.name === "INCIDENT_MODULE");

    if (!GLOBAL_ROLES.isAdmin(profile.user.role)
      && incidentModule && incidentModule.localRole !== incidentRoles.INCIDENT_ADMIN)
      this.filteredRoutes = incidentsDashboardRoutes.filter(prop => !prop.adminRestricted);

    this.switchRoutes = (
      <Switch>
        {this.filteredRoutes.map((prop, key) => {
          if (prop.redirect)
            return <Redirect from={prop.path} to={prop.to} key={key} />;

          return <Route path={prop.path} component={prop.component} key={key} />;
        })}
      </Switch>
    );

    this.resizeFunction = this.resizeFunction.bind(this);
  }

  handleDrawerToggle = () => {
    this.setState({ mobileOpen: !this.state.mobileOpen });
  };

  resizeFunction() {
    if (window.innerWidth >= 960) {
      this.setState({ mobileOpen: false });
    }
  }

  componentDidMount() {
    if (navigator.platform.indexOf("Win") > -1) {
      new PerfectScrollbar(this.refs.mainPanel);
    }
    window.addEventListener("resize", this.resizeFunction);
  }

  componentDidUpdate(e) {
    if (e.history.location.pathname !== e.location.pathname) {
      this.refs.mainPanel.scrollTop = 0;
      if (this.state.mobileOpen) {
        this.setState({ mobileOpen: false });
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeFunction);
  }

  hideNotificationBadge = value => {
    if (!!value !== this.state.hideBadge)
      this.setState({ hideBadge: !!value });
  }

  render() {
    const { classes, ...rest } = this.props;
    const { hideBadge } = this.state;

    return (
      <div className={classes.wrapper}>
        <Sidebar
          hideNotificationBadge={this.hideNotificationBadge}
          routes={this.filteredRoutes}
          logo={logo}
          handleDrawerToggle={this.handleDrawerToggle}
          open={this.state.mobileOpen}
          color="blue"
          {...rest}
        />
        <div className={classes.mainPanel} ref="mainPanel">
          <Header routes={this.filteredRoutes} handleDrawerToggle={this.handleDrawerToggle} hideBadge={hideBadge} {...rest} />
          <div className={classes.content}>
            <div className={classes.container}>{this.switchRoutes}</div>
          </div>
          <Footer />
        </div>
      </div>
    );
  }
}

export default withStyles(dashboardStyle)(IncidentsDashboard);
