import React from "react";
import { withStyles } from "@material-ui/core/styles";

import Grid from "@material-ui/core/Grid";
import Snackbar from "@material-ui/core/Snackbar";
import CircularProgress from "@material-ui/core/CircularProgress";

// core components
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import Button from "components/CustomButtons/Button.jsx";

import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";

import AuthService from "components/Services/AuthService";

import { primaryColor } from "assets/jss/material-dashboard-react.jsx";
import TemplateForm from "./TemplateForm";

const styles = theme => ({
  rootContent: {
    position: "relative",
  },
  coverLoading: {
    position: "absolute",
    height: "100%",
    width: "100%",
    zIndex: 1,
    backgroundColor: "#ddd",
    opacity: 0.3,
  },
  progress: {
    color: primaryColor,
    position: "absolute",
    top: "calc(50% - 4em)",
    left: "calc(50% - 4em)",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  dividerSecction: {
    height: "48px",
    borderBottom: "1px solid rgba(224, 224, 224, 1)",
    backgroundColor: "#eeeeee50",
    padding: `0 ${theme.spacing.unit * 3}px ${theme.spacing.unit}px ${theme.spacing.unit * 3}px`,
    marginBottom: theme.spacing.unit,
  },
  title: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
    marginBottom: theme.spacing.unit,
    paddingTop: theme.spacing.unit * 3,
    color: primaryColor,
  }
});

class TemplateEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      saveForm: false,
      categories: [],
      isLoaded: false,
      warningSnack: {
        open: false,
        payload: null
      }
    };

    this.template = props.location.state.template;

    this.formContent = {
      name: this.template.name,
      description: this.template.description,
      categories: this.template.categories,
      status: this.template.status
    };

    this.AuthService = new AuthService();
  }

  componentDidMount() {
    this.getCategories();
  }

  handleSave = (form, validForm) => {
    if (validForm) {
      this.setState({
        isLoaded: false,
        saveForm: false
      });

      let templateResquest = {
        ...form,
        categories: form.categories.map(m => m.id)
      };

      this.AuthService.authFetch(`/admin/template/${this.template.id}`, {
        method: "PUT",
        body: JSON.stringify(templateResquest)
      }).then(() => {
        this.props.history.goBack();
      }).catch(() => {
        this.setState({
          isLoaded: true,
          warningSnack: {
            open: true,
            payload: 'Ocurrió un problema editando la plantilla'
          }
        });
      });
    }
    else
      this.setState({
        saveForm: false
      });
  };

  getCategories = () => {
    this.AuthService.authFetch("/admin/categories/regular")
      .then(res => {
        this.setState({
          isLoaded: true,
          categories: res.result
        });
      })
      .catch(() => {
        this.setState({
          isLoaded: true,
          warningSnack: {
            open: true,
            payload: 'Ocurrió un problema cargando las categorías'
          }
        });
      });
  };

  render() {
    const { classes } = this.props;
    const { categories } = this.state;

    return (
      <div className={classes.rootContent}>
        {this.state.warningSnack.open && (
          <Snackbar
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            open={this.state.warningSnack.open}
            onClose={() => {
              this.setState({
                warningSnack: {
                  open: false,
                  payload: null
                }
              });
            }}
            ContentProps={{
              "aria-describedby": "message-id",
            }}
            message={<span id="message-id">{this.state.warningSnack.payload}</span>}
          />
        )}
        {!this.state.isLoaded && <div className={classes.coverLoading} />}
        <Grid container>
          <Grid item xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="primary">
                <h4 className={classes.cardTitleWhite}>Editar Plantilla</h4>
              </CardHeader>

              <CardBody>
                <TemplateForm
                  saveForm={this.state.saveForm}
                  onFormSubmit={this.handleSave}
                  categories={categories}
                  form={this.formContent}
                />
              </CardBody>

              <CardFooter style={{ justifyContent: "normal" }}>
                <Button
                  color="primary"
                  style={{ marginRight: "8px" }}
                  onClick={() => {
                    this.setState({ saveForm: true });
                  }}>
                  Guardar
                </Button>

                <Button
                  onClick={() => {
                    this.props.history.goBack();
                  }}>
                  Cancelar
                </Button>
              </CardFooter>
            </Card>
          </Grid>
        </Grid>
        {!this.state.isLoaded && <CircularProgress className={classes.progress} size={50} thickness={5} />}
      </div>
    );
  }
}

export default withStyles(styles)(TemplateEdit);
