import React from "react";
import { Route } from "react-router-dom";
import GarbagePickupRanges from "./GarbagePickupRanges";
import GarbagePickupRangesCreate from "./GarbagePickupRangesCreate";
import GarbagePickupRangesEdit from "./GarbagePickupRangesEdit";

function GarbagePickupRangesContainer() {
  return (
    <div>
      <Route path="/garbage-pickup/ranges" exact component={GarbagePickupRanges} />
      <Route path="/garbage-pickup/ranges/create" exact component={GarbagePickupRangesCreate} />
      <Route path="/garbage-pickup/ranges/edit" exact component={GarbagePickupRangesEdit} />
    </div>
  );
}

export default GarbagePickupRangesContainer;
