import React from "react";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import withStyles from "@material-ui/core/styles/withStyles";
import { TextField, Button } from "@material-ui/core";
import { IncidentCategoriesTypes } from "./IncidentCategories";

const styles = theme => ({
  button: {
    margin: theme.spacing.unit
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 400
  }
});

class ClosedCategoryForm extends React.Component {
  constructor(props) {
    super(props);
    let initialForm = {
      name: null,
      type: IncidentCategoriesTypes.CLOSED.id
    };

    let isEdit = false;
    if (props.form) {
      isEdit = true;
      initialForm = props.form;
    }
    this.state = {
      form: initialForm,
      errorForm: {
        name: false
      },
      isEditForm: isEdit
    };
  }
  handleTextFieldChange = event => {
    const target = event.target;
    const name = target.name;
    const updatedForm = {
      ...this.state.form
    };
    updatedForm[name] = target.value;

    const updatedErrorForm = {
      ...this.state.errorForm
    };
    updatedErrorForm[name] = !target.value || target.value.length === 0;

    this.setState({
      form: updatedForm,
      errorForm: updatedErrorForm
    });
  };

  handleSubmit = event => {
    event.preventDefault();
    if (this.isFormValid()) {
      const result = {
        ...this.state.form
      };
      this.props.submitHandler(result);
    }
  };

  isFormValid = () => {
    const form = this.state.form;
    const updatedErrorForm = {
      ...this.state.errorForm
    };
    updatedErrorForm.name = !form.name || form.name.length === 0;

    this.setState({
      errorForm: updatedErrorForm
    });
    return !updatedErrorForm.name;
  };

  render() {
    const { classes } = this.props;
    const submitButtonText = this.state.isEditForm ? "Salvar" : "Crear";

    return (
      <div>
        <form
          className={classes.container}
          noValidate
          autoComplete="off"
          onSubmit={this.handleSubmit}
        >
          <Grid container>
            <Grid item xs={12} sm={12} md={12}>
              <TextField
                name="name"
                label="Nombre"
                required
                error={this.state.errorForm.name}
                className={classes.textField}
                value={this.state.form.name}
                onChange={this.handleTextFieldChange}
                margin="normal"
              />
            </Grid>
          </Grid>
          <Button
            color="primary"
            className={classes.button}
            style={{ marginTop: "30px" }}
            type="submit"
          >
            {submitButtonText}
          </Button>
          <Button
            color="secondary"
            className={classes.button}
            onClick={this.props.cancelHandler}
            style={{ marginTop: "30px" }}
          >
            Cancelar
          </Button>
        </form>
      </div>
    );
  }
}

ClosedCategoryForm.propTypes = {
  history: PropTypes.object,
  form: PropTypes.object,
  cancelHandler: PropTypes.func.isRequired,
  submitHandler: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired
};
export default withStyles(styles)(ClosedCategoryForm);
