import React from "react";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import { Snackbar } from "@material-ui/core";
import IncidentPriorityForm from "./IncidentPriorityForm";
import AuthService from "components/Services/AuthService";

const styles = theme => ({
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  button: {
    margin: theme.spacing.unit,
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 400,
  },
});

class EditIncidentPriority extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showSnackbar: false,
      snackBarText: "",
      form: this.props.location.state.priority,
    };
    this.AuthService = new AuthService();
  }

  submitHandler = form => {
    const id = form.id;
    var body = form;
    delete body.id;
    this.AuthService.authFetch("/admin/priorities/" + id, {
      method: "PUT",
      body: JSON.stringify(body),
    })
      .then(() => {
        this.props.history.goBack();
      })
      .catch(() =>
        this.setState({
          snackBarText: "Error guardando prioridad",
          showSnackbar: true,
        })
      );
  };

  cancelHandler = () => {
    this.props.history.goBack();
  };

  handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    this.setState({ showSnackbar: false });
  };

  render() {
    const { classes } = this.props;

    return (
      <div>
        <Grid container>
          <Grid item xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="primary">
                <h4 className={classes.cardTitleWhite}>Editar Prioridad</h4>
              </CardHeader>
              <CardBody>
                <IncidentPriorityForm form={this.state.form} submitHandler={this.submitHandler} cancelHandler={this.cancelHandler} />
              </CardBody>
            </Card>
          </Grid>
        </Grid>
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          open={this.state.showSnackbar}
          onClose={this.handleClose}
          autoHideDuration={6000}
          ContentProps={{
            "aria-describedby": "message-id",
          }}
          message={<span id="message-id">{this.state.snackBarText}</span>}
        />
      </div>
    );
  }
}

EditIncidentPriority.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  classes: PropTypes.object,
};
export default withStyles(styles)(EditIncidentPriority);
