import Group from "@material-ui/icons/Group";
import Notifications from "@material-ui/icons/Notifications";

// core components/views
import UsersContainer from "views/usersDashboard/UsersContainer";

const usersRoutes = [
  {
    path: "/users/users",
    sidebarName: "Usuarios",
    navbarName: "Usuarios",
    icon: Group,
    component: UsersContainer,
  },
  {
    path: "#notifications",
    sidebarName: "Notificaciones",
    navbarName: "Notificaciones",
    icon: Notifications,
    adminRestricted: false,
  },
  {
    redirect: true,
    path: "/users",
    to: "/users/users",
    navbarName: "Redirect",
  },
];

export default usersRoutes;
