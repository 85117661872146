import React from "react";
import PropTypes from "prop-types";

import EditUserForm from "./EditUserForm";
import AuthService from "components/Services/AuthService";

class NewUser extends React.Component {
  constructor(props) {
    super(props);
    this.AuthService = new AuthService();
    this.currentProfile = this.AuthService.getProfile().user;
  }

  submitHandler = form => new Promise((_resolve, reject) => {
    this.AuthService.authFetch(`/account/create`, {
      method: "POST",
      body: JSON.stringify({ ...form, status: "ACTIVE" }),
    })
      .then(() => {
        this.props.history.goBack();
      })
      .catch(() => {
        reject({
          warningSnack: {
            open: true,
            content: 'Ocurrió un error creando el usuario'
          }
        });
      });
  });

  cancelHandler = () => {
    this.props.history.goBack();
  };

  render() {
    return (
      <EditUserForm
        formTitle="Crear Usuario"
        submitHandler={this.submitHandler}
        cancelHandler={this.cancelHandler}
        currentProfile={this.currentProfile}
      />
    );
  }
}

NewUser.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default NewUser;
