import React, { Component } from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";

import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { CircularLoader } from "components/CustomLoader/Loaders";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";

import logo from "assets/img/logo.svg";
import backgroundImage from "assets/img/login_background.jpg";

import AuthService from "components/Services/AuthService";
import { EMAIL_REGEX } from "variables/general"
import { primaryColor } from "assets/jss/material-dashboard-react.jsx";

const styles = theme => ({
  root: {
    background: primaryColor,
    backgroundImage: `url(${backgroundImage})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    height: "100vh",
    position: "relative",
  },
  paperContent: {
    paddingTop: theme.spacing.unit * 8,
  },
  paperRoot: {
    maxWidth: "480px",
    margin: "0 auto",
    alignItems: "center",
    padding: `${theme.spacing.unit * 4}px`,
  },
  logoImage: {
    textAlign: "center",
    marginBottom: "60px",
    height: "50px",
    color: "transparent",
  },
  img: {
    width: "90px",
  },
  form: {
    width: "100%",
    marginTop: theme.spacing.unit,
  }
});

class ForgotPassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      warningSnack: {
        open: false,
        content: null
      },
      email: ''
    };

    this.AuthService = new AuthService();
  }

  handleErrorSnackbarClose = (_event, reason) => {
    if (reason === "clickaway")
      return;

    this.setState({
      warningSnack: {
        open: false,
        content: null
      }
    });
  };

  handleSubmit = event => {
    event.preventDefault();

    const { email } = this.state;

    if (!email.match(EMAIL_REGEX))
      return this.setState({
        warningSnack: {
          open: true,
          content: 'Debe ingresar un correo electrónico válido'
        }
      });

    this.setState({
      loading: true,
    });

    this.AuthService.authFetch('/user/password/reset', {
      method: 'post',
      body: JSON.stringify({ email })
    })
      .then(() => {
        this.setState({
          loading: false,
          email: '',
          warningSnack: {
            open: true,
            content: 'Se ha procesado la solicitud... verifique su correo electrónico'
          }
        });
      })
      .catch(() => {
        this.setState({
          loading: false,
          warningSnack: {
            open: true,
            content: 'Ocurrió un problema solicitando el cambio de clave... intente nuevamente más tarde'
          }
        });
      });
  };

  render() {
    if (this.AuthService.loggedIn()) {
      this.props.history.push("/");
    }

    const { classes } = this.props;

    return (
      <div className={classes.root}>
        {this.state.warningSnack.open && (
          <Snackbar
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            open
            onClose={this.handleErrorSnackbarClose}
            ContentProps={{
              "aria-describedby": "message-id",
            }}
            message={<span id="message-id">{this.state.warningSnack.content}</span>}
            action={[
              <IconButton key="close" aria-label="Close" color="inherit" className={classes.close} onClick={this.handleErrorSnackbarClose}>
                <CloseIcon />
              </IconButton>,
            ]}
          />
        )}
        {this.state.loading && <CircularLoader />}
        <div className={classes.paperContent}>
          <Paper className={classes.paperRoot} elevation={1}>
            <div className={classes.logoImage}>
              <img src={logo} alt="logo" className={classes.img} />
            </div>
            <br />
            <ValidatorForm className={classes.form} onSubmit={this.handleSubmit}>
              <TextValidator
                name="email"
                label="Ingrese su correo electrónico registrado"
                value={this.state.email}
                onChange={event => {
                  this.setState({ email: event.target.value });
                }}
                validators={["required", "isEmail"]}
                errorMessages={["Campo requerido", "Correo electrónico inválido"]}
                fullWidth
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
                type="email"
                autoComplete="email"
                autoFocus
              />
              <br />
              <br />
              <div style={{ textAlign: 'right' }}>
                <Button
                  color="secondary"
                  style={{ color: 'rgb(230, 71, 59)' }}
                  onClick={() => {
                    this.props.history.goBack();
                  }}>
                  Ir atrás
                </Button>

                <Button type="submit" color="primary">
                  Enviar
                </Button>
              </div>
            </ValidatorForm>
          </Paper>
        </div>
      </div>
    );
  }
}

ForgotPassword.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ForgotPassword);
