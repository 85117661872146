import React from "react";

// @material-ui/core components
import InputBase from "@material-ui/core/InputBase";
import FormControl from "@material-ui/core/FormControl";
import CircularProgress from "@material-ui/core/CircularProgress";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

// core components
import Button from "components/CustomButtons/Button.jsx";

import { uploadFirebase } from "utils/IncidentUtil";

class NewComment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      commentToPush: "",
      imageFiles: [],
    };
  }

  handleChangeCommentToPush = event => {
    this.setState({ commentToPush: event.target.value });
  };

  handleSubmit = () => {
    if (!this.props.loading && this.state.commentToPush !== "") {
      if (this.props.commentType === "EXTERNAL") {
        const userConfirmation = window.confirm("¿Esta seguro que desea enviar un comentario externo? Por favor tenga en cuenta que el comentario sera visible por los ciudadanos.");
        if (!userConfirmation) {
          return;
        }
      }

      const self = this;
      const comment = {
        description: this.state.commentToPush,
        type: this.props.commentType,
      };
      if (this.state.imageFiles) {
        uploadFirebase(this.state.imageFiles, this.props.incidentId).then(urls => {
          self.props.handleCommentSubmit({ ...comment, pictureUrl: urls }).then(() => {
            self.setState({
              commentToPush: "",
              imageFiles: [],
            });
          });
        });
      } else {
        this.props.handleCommentSubmit(comment).then(() => {
          self.setState({
            commentToPush: "",
            imageFiles: [],
          });
        });
      }
    }
  };

  handleAddFile = event => {
    this.setState({ imageFiles: Array.from(event.target.files).slice(0, 3) });
  };

  handleRemoveFile = fileToRemove => () => {
    var files = this.state.imageFiles.filter(file => file !== fileToRemove);

    this.setState({ imageFiles: files });
  };

  render() {
    const { classes, loading, commentType } = this.props;

    const placeholder =
      commentType === "EXTERNAL"
        ? "Escriba su comentario. Por favor tenga en cuenta que el comentario sera visible por los ciudadanos."
        : "Escriba su comentario. Por favor tenga en cuenta que el comentario sera visible a personas con acceso a este modulo.";

    return (
      <form className={classes.commentForm} autoComplete="off">
        <FormControl className={classes.margin}>
          <InputBase
            id="bootstrap-input"
            multiline
            placeholder={placeholder}
            value={this.state.commentToPush}
            onChange={this.handleChangeCommentToPush}
            classes={{
              root: classes.bootstrapRoot,
              input: classes.bootstrapInput,
            }}
          />
          {this.state.imageFiles.length === 0 && (
            <div>
              <input accept="image/*" className={classes.input} id="contained-button-comment-file" multiple type="file" style={{ display: "none" }} onChange={this.handleAddFile} />
              <label htmlFor="contained-button-comment-file">
                <Button variant="contained" component="span" color="primary" disabled={loading} className={classes.button}>
                  Seleccionar imagen
                </Button>
              </label>
            </div>
          )}

          <List>
            {this.state.imageFiles.map((file, index) => (
              <ListItem key={index}>
                <ListItemText primary={file.name} />
                <ListItemSecondaryAction>
                  <IconButton aria-label="Delete" color="secondary" onClick={this.handleRemoveFile(file)}>
                    <CloseIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        </FormControl>

        <div className={classes.wrapper}>
          <Button type="button" variant="contained" color="primary" disabled={loading} className={classes.buttonSuccess} onClick={this.handleSubmit}>
            Enviar
          </Button>
          {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
        </div>
      </form>
    );
  }
}

export default NewComment;
