import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
//import CircularProgress from "@material-ui/core/CircularProgress";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";

import AuthService from "components/Services/AuthService";
import PaginatedTable2 from "../../components/CustomTable/PaginatedTable2";

import { primaryColor } from "assets/jss/material-dashboard-react.jsx";

import { dateTimeFormat } from "utils/DateUtil.jsx";
import { PAYMENT_STATUS_MASK } from "variables/general";

const styles = theme => ({
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  progress: {
    color: primaryColor,
    position: "absolute",
    top: "calc(50% - 4em)",
    left: "calc(50% - 4em)",
  },
  close: {
    width: theme.spacing.unit * 4,
    height: theme.spacing.unit * 4,
  },
});

class Payments extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      payments: [],
      loading: false,
      warningSnack: {
        open: false,
        payload: null
      }
    };

    this.AuthService = new AuthService();
  }

  handleErrorSnackbarClose = (_event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    this.setState({
      warningSnack: {
        open: false,
        payload: null
      }
    });
  };

  
  componentDidMount(){
    this.getData();
  }

  getData = (page = 0, count = 10, filter = '', sort = 'date', order = 'desc') => {

    this.setState({
      loading: true
    });

    this.AuthService.authFetch(`/payments/pagination?page=${page}&count=${count}&filter=${filter}&sort=${sort}&order=${order}`)
      .then(res => {
        const { payments, totalCount } = res.result;
        this.setState({
          payments,
          totalCount,
          loading: false
        });
      })
      .catch(error => {
        console.error("error here: ", error); 
      });

  }

  render() {
    const { classes } = this.props;
    const { warningSnack, loading, payments, totalCount } = this.state;

    return (
      <div>
        {warningSnack.open && (
          <Snackbar
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            open
            onClose={this.handleErrorSnackbarClose}
            ContentProps={{
              "aria-describedby": "message-id",
            }}
            message={<span id="message-id">{warningSnack.payload}</span>}
            action={[
              <IconButton key="close" aria-label="Close" color="inherit" className={classes.close} onClick={this.handleErrorSnackbarClose}>
                <CloseIcon />
              </IconButton>,
            ]}
          />
        )}

        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="primary">
                <h4 className={classes.cardTitleWhite}>Lista de pagos</h4>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <PaginatedTable2
                      headerList={[
                        'Código pago',
                        'Código sistema',
                        'Nombre',
                        'Fecha',
                        'Estado',
                      ]}
                      initialOrder={'desc'}
                      getData={this.getData}
                      totalCount={totalCount} 
                      loading={loading}
                      tableContent={payments.map(m => ({
                        id: m.id.toUpperCase(),
                        code: m.sysCode,
                        name: m.account || 'N/A',
                        date: dateTimeFormat(new Date(m.processDateISO).toString()),
                        status: m.status ? PAYMENT_STATUS_MASK[m.status] || m.status : 'INDEFINIDO'
                      }))}
                      tableContentProps={[
                        'id', 'code', 'name',
                        'date', 'status'
                      ]}
                      rowKeyPropName='id'
                      fillEmptyRows
                      onRowClick={row => {
                        localStorage.payment = JSON.stringify(payments.find(f => f.id.toLowerCase() === row.id.toLowerCase()));
                        window.open(`/payments/details/${row.id}`, '_blank')
                      }}
                    />
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

export default withStyles(styles)(Payments);
