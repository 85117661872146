import React from "react";
import { Route } from "react-router-dom";
import Template from "./Templates";
import TemplateCreate from "./TemplateCreate";
import TemplateEdit from "./TemplateEdit";


export default function TemplatesContainer() {
	return (
		<div>
			<Route path="/incidents/templates" exact component={Template} />
			<Route path="/incidents/templates/create" exact component={TemplateCreate} />
			<Route path="/incidents/templates/edit" exact component={TemplateEdit} />
		</div>
	);
}