import React from "react";
import { Drawer, withStyles, IconButton, List, ListItem, ListItemText, Tabs, Tab, Badge, ListItemIcon, Tooltip, Button, Paper } from "@material-ui/core";
import ArrowBack from "@material-ui/icons/ArrowBack";
import propTypes from "prop-types";
import NavLink from "react-router-dom/NavLink";

import { NOTIFICATION_TYPES } from "variables/general";
import { dateTimeFormat, getTimeLapse } from "utils/DateUtil.jsx";

import MagnifierIcon from "../../assets/img/report_magnifier.png";
import CommentIcon from "../../assets/img/report_comment.png";
import UserIcon from "../../assets/img/report_user.png";


const styles = theme => ({
  drawer: {
    maxWidth: '100%',
    minWidth: '70%',
    flexShrink: 0
  },
  drawerPaper: {
    maxWidth: '100%',
    width: 640,
    minWidth: 240,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: '10px 30px 30px',
    ...theme.mixins.toolbar,
    justifyContent: 'flex-start',
    '& span': {
      marginLeft: 5,
      fontWeight: 'bold',
    }
  },
  notiTabs: {
    boxShadow: '0 1px 1px -1px gray',
  },
  tabIndicator: {
    height: 4,
    borderRadius: 5,
  },
  tabLabelRoot: {
    padding: '0 10px',
    textTransform: 'unset',
  },
  drawerBody: {
    padding: '0 30px 0 85px',
    overflowX: 'hidden',
    display: 'flex',
    flexDirection: 'column',
  },
  notiContent: {
    overflowY: 'auto',
    marginBottom: 10,
  },
  notiIcon: {
    border: '1.3px solid',
    padding: '10px',
    borderRadius: '5px',
  },
  notiIconIncident: {
    backgroundColor: 'rgba(126,211,33,0.29)',
    borderColor: 'rgba(126,211,33,0.42)',
  },
  notiIconComment: {
    backgroundColor: '#FCF8CD',
    borderColor: 'rgba(248,231,28,0.45)',
  },
  notiIconUser: {
    backgroundColor: 'rgba(74,144,226,0.37)',
    borderColor: 'rgba(74,144,226,0.72)',
  },
  grayNotiIcon: {
    filter: 'grayscale(0.5)'
  },
  notiText: {
    padding: 0
  },
  grayText: {
    color: 'gray',
  },
  notiTextTitleHolder: {
    display: 'flex',
  },
  notiTextTitle: {
    fontWeight: 'bold',
    fontSize: '11pt',
    flex: 1,
  },
  notiTextOwner: {
    // flex: 1,
    fontSize: '8pt',
    color: '#838383',
    textAlign: 'right',
  },
  notiTextContent: {
    margin: 0,
  },
  notiTextDate: {
    fontSize: '8pt',
    fontWeight: 'bold',
    color: '#838383',
  },
  dotBadge: {
    right: -5,
    width: 8,
    height: 8,
    borderRadius: '50%',
  },
  emptyNotifications: {
    display: 'block',
    textAlign: 'center',
    paddingTop: 100,
    color: 'gray',
  },
  loadMore: {
    textAlign: 'center',
    padding: 20,
  },
  loadMoreAction: {
    width: '100%',
    padding: '15px 0',
  }
});

const resourceMapper = {
  INCIDENT_NEW: {
    icon: MagnifierIcon,
    className: 'notiIconIncident',
  },
  INCIDENT_EDITED: {
    icon: MagnifierIcon,
    className: 'notiIconIncident',
  },
  INCIDENT_COMMENT_NEW: {
    icon: CommentIcon,
    className: 'notiIconComment',
  },
  INCIDENT_COMMENT_EDITED: {
    icon: CommentIcon,
    className: 'notiIconComment',
  },
  INCIDENT_HANDLER_NEW: {
    icon: UserIcon,
    className: 'notiIconUser',
  },
  INCIDENT_HANDLER_CHANGED: {
    icon: UserIcon,
    className: 'notiIconUser',
  },
  INCIDENT_SOLVER_NEW: {
    icon: UserIcon,
    className: 'notiIconUser',
  },
  INCIDENT_SOLVER_CHANGED: {
    icon: UserIcon,
    className: 'notiIconUser',
  },
};

class NotificationDrawer extends React.Component {
  constructor(props) {
    super(props);

    this.amountPerLoad = 50;

    this.state = {
      notifications: props.notifications || [],
      tab: 0,
      hideBadge: props.hideBadge,
      currentPaginationAmount: this.amountPerLoad,
    };
  }

  componentWillReceiveProps(nextProps) {
    const newState = {};

    if (nextProps.hideBadge !== this.props.hideBadge)
      newState.hideBadge = nextProps.hideBadge;

    if (nextProps.notifications !== this.props.notifications)
      newState.notifications = nextProps.notifications;

    // 
    if (!this.props.open && nextProps.open && this.props.onDrawerOpen) {
      newState.currentPaginationAmount = this.amountPerLoad;
      this.props.onDrawerOpen({ ...this.state });
    }

    if (Object.keys(newState).length)
      this.setState(newState);
  }

  render() {
    const { classes, open, onCollapseClick, onNotificationClick, onTabChange } = this.props;
    const { notifications, tab, hideBadge, currentPaginationAmount } = this.state;

    const filteredNotifications = notifications
      .filter(f => (tab === 0 && !f.opened) || (tab === 1 && f.opened));

    return (
      <div>
        <Drawer
          className={classes.drawer}
          variant='temporary'
          anchor='left'
          open={open}
          onClose={onCollapseClick}
          classes={{
            paper: classes.drawerPaper
          }}>
          <div className={classes.drawerHeader}>
            <IconButton onClick={onCollapseClick} style={{ color: 'black' }}>
              <ArrowBack />
            </IconButton>

            <span>
              Notificaciones
            </span>
          </div>

          <div className={classes.drawerBody}>
            <Tabs
              value={tab}
              indicatorColor='primary'
              textColor='primary'
              classes={{ indicator: classes.tabIndicator }}
              className={classes.notiTabs}
              onChange={(event, newTab) => {
                this.setState({
                  tab: newTab,
                  currentPaginationAmount: this.amountPerLoad,
                });

                if (onTabChange)
                  onTabChange(event, newTab);
              }}>
              <Tab
                label={
                  <Badge variant='dot' color='secondary' invisible={hideBadge} classes={{ dot: classes.dotBadge }}>
                    Recientes
                 </Badge>
                }
                classes={{ root: classes.tabLabelRoot }}
              />

              <Tab label='Anteriores' classes={{ root: classes.tabLabelRoot }} />
            </Tabs>

            <List className={classes.notiContent}>
              {filteredNotifications.length ? filteredNotifications
                .slice(0, currentPaginationAmount)
                .map(m => {
                  const notiEntry = (
                    <ListItem
                      button
                      key={m.id}
                      onClick={() => {
                        if (onNotificationClick)
                          onNotificationClick(m);
                      }}>
                      <ListItemIcon>
                        <img
                          src={resourceMapper[m.type].icon}
                          alt="notification_icon"
                          className={`${classes.notiIcon} ${classes[resourceMapper[m.type].className]}${tab === 1 ? ` ${classes.grayNotiIcon}` : ''}`}
                        />
                      </ListItemIcon>

                      <ListItemText
                        primary={
                          <div>
                            <div className={classes.notiTextTitleHolder}>
                              <span className={classes.notiTextTitle}>
                                {NOTIFICATION_TYPES[m.type].displayName}
                              </span>

                              <span className={classes.notiTextOwner}>
                                {m.creator ? `Por: ${m.creator.displayName}${m.creator.role === 'APP_USER' ? ' (APP)' : ''}` : ''}
                              </span>
                            </div>

                            <p className={classes.notiTextContent}>
                              {m.desc}
                            </p>

                            <Tooltip disableFocusListener title={dateTimeFormat(m.date)}>
                              <span className={classes.notiTextDate}>
                                {getTimeLapse(m.date)}
                              </span>
                            </Tooltip>
                          </div>
                        }
                        className={classes.notiText}
                        classes={{ primary: m.opened ? classes.grayText : undefined }}
                      />
                    </ListItem>
                  );

                  if (m.url)
                    return (
                      <NavLink to={m.url} key={`link_${m.id}`}>
                        {notiEntry}
                      </NavLink>
                    );

                  return notiEntry;
                })
                .concat(filteredNotifications.length > currentPaginationAmount ? [(
                  <Paper className={classes.loadMore}>
                    <Button className={classes.loadMoreAction} onClick={() => {
                      this.setState({
                        currentPaginationAmount: this.state.currentPaginationAmount + this.amountPerLoad,
                      });
                    }}>
                      Mostrar más
                    </Button>
                  </Paper>
                )] : []) : (
                  <i className={classes.emptyNotifications}>
                    No hay notificaciones que mostrar
                  </i>
                )}
            </List>
          </div>
        </Drawer>
      </div>
    );
  }
}

NotificationDrawer.propTypes = {
  open: propTypes.bool,
  notifications: propTypes.array,
  onCollapseClick: propTypes.func,
  onNotificationClick: propTypes.func,
  hideBadge: propTypes.bool,
  onTabChange: propTypes.func,
  onDrawerOpen: propTypes.func,
};


export default withStyles(styles)(NotificationDrawer);