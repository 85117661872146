import React from "react";
import { Route } from "react-router-dom";
import IncidentPriorities from "./IncidentPriorities";
import CreateIncidentPriority from "./CreateIncidentPriority";
import EditIncidentPriority from "./EditIncidentPriority";

function CategoriesContainer() {
  return (
    <div>
      <Route path="/incidents/priorities" exact component={IncidentPriorities} />
      <Route path="/incidents/priorities/create" exact component={CreateIncidentPriority} />
      <Route path="/incidents/priorities/edit" exact component={EditIncidentPriority} />
    </div>
  );
}

export default CategoriesContainer;
