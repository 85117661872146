import React from "react";
import PropTypes from "prop-types";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import InputBase from "@material-ui/core/InputBase";
import FormControl from "@material-ui/core/FormControl";
import NoSsr from "@material-ui/core/NoSsr";

import IncidentImage from "../IncidentImage";

// core components
import Button from "components/CustomButtons/Button.jsx";
import Select from "components/Select/Select.jsx";

const styles = theme => ({
  input: {
    display: "flex",
    padding: 0,
  },
  divider: {
    height: theme.spacing.unit * 2,
  },
  commentForm: {
    display: "flex",
    flexWrap: "wrap",
  },
  bootstrapRoot: {
    width: "100%",
  },
  margin: {
    width: "100%",
    marginTop: theme.spacing.unit,
  },
  bootstrapInput: {
    borderRadius: 4,
    backgroundColor: theme.palette.common.white,
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: theme.spacing.unit,
    marginLeft: -theme.spacing.unit,
    width: "100%",
    height: "100px !important",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    "&:focus": {
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
});

class IncidentCloseDialog extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      categories: [],
      categorySelected: null,
      comment: "",
      images: [],
      loading: false,
      error: null,
      templates: props.templates
        .map(m => ({
          id: m.id,
          label: m.name,
          value: m.description
        }))
    };
  }

  componentDidMount = () => {
    this.fetchCategories();
  };

  componentWillReceiveProps(nextProps) {
    const newState = {};

    if (nextProps.templates !== this.props.templates)
      newState.templates = nextProps.templates
        .map(m => ({
          id: m.id,
          label: m.name,
          value: m.description
        }));

    this.setState(newState);
  }

  handlerAccept = () => {
    this.props.onAccept(
      this.state.categorySelected,
      this.state.comment,
      this.state.images
    );
  };

  handleClose = () => {
    this.props.onClose();
  };

  handleChangeComment = event => {
    this.setState({ comment: event.target.value });
  };

  handleChangeCloseCategory = value => {
    this.setState({
      categorySelected: this.state.categories.filter(
        category => category.id === value.value
      )[0],
    });
  };

  handleImageChange = images => {
    this.setState({
      images: images,
    });
  };

  fetchCategories = () => {
    this.props.authService
      .authFetch("/admin/categories/closed")
      .then(res => {
        this.setState({
          isLoaded: true,
          categories: res.result,
        });
      })
      .catch(error => {
        error.message = "Ups!!! Ocurrió un problema obteniendo los datos.";
        this.setState({
          isLoaded: true,
          error,
        });
      });
  };

  render() {
    const { classes, onAccept, authService, ...other } = this.props;
    const { categories } = this.state;

    const suggestions = categories.map(category => ({
      value: category.id,
      label: category.name,
    }));

    const templates = [{
      id: 'BLANK_ID',
      label: '(Sin plantilla)',
      value: ''
    }].concat(this.state.templates);

    return (
      <Dialog
        onEntering={() => {
          if (this.state.comment)
            this.setState({ comment: '' });
        }}
        onClose={this.handleClose}
        aria-labelledby="simple-dialog-title"
        {...other}>
        <DialogTitle id="simple-dialog-title">Cerrar Incidente</DialogTitle>
        <DialogContent style={{ height: "600px", width: "500px" }}>
          <NoSsr>
            <Select
              options={suggestions}
              onChange={this.handleChangeCloseCategory}
              placeholder="Escribe el nombre de la categoría"
            />
          </NoSsr>
          <div className={classes.divider} />
          <IncidentImage onFileChange={this.handleImageChange} />
          <div className={classes.divider} />
          <NoSsr>
            <Select
              options={templates}
              onChange={obj => {
                this.setState({ comment: obj.value });
              }}
              placeholder="(Opcional) seleccione una plantilla"
            />
          </NoSsr>
          <div className={classes.divider} />
          <form className={classes.commentForm} autoComplete="off">
            <FormControl className={classes.margin}>
              <InputBase
                id="bootstrap-input"
                multiline
                placeholder="Comentario"
                value={this.state.comment}
                onChange={this.handleChangeComment}
                classes={{
                  root: classes.bootstrapRoot,
                  input: classes.bootstrapInput,
                }}
              />
            </FormControl>
            <span style={{ fontSize: "12px", marginLeft: "-8px" }}>
              Puede ingresar un comentario de forma opcional
            </span>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleClose} color="danger" simple={true}>
            Cancelar
          </Button>
          <Button onClick={this.handlerAccept} color="primary" simple={true}>
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

IncidentCloseDialog.propTypes = {
  onAccept: PropTypes.func,
  onClose: PropTypes.func,
  authService: PropTypes.object.isRequired,
  templates: PropTypes.array
};

export default withStyles(styles)(IncidentCloseDialog);
