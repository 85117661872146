// @material-ui/icons
import Dashboard from "@material-ui/icons/Dashboard";
import Notifications from "@material-ui/icons/Notifications";


// core components/views
import PlacesContainer from "views/PlacesDashboard/PlacesContainer";
import PlacesCategoriesContainer from "views/PlacesDashboard/PlacesCategoriesContainer";

const placesRoutes = [
  {
    path: "/places/entries",
    sidebarName: "Lugares",
    navbarName: "Lugares",
    icon: Dashboard,
    component: PlacesContainer,
    adminRestricted: true,
  },
  {
    path: "/places/categories",
    sidebarName: "Categorías",
    navbarName: "Categorías",
    icon: 'content_paste',
    component: PlacesCategoriesContainer,
    adminRestricted: true,
  },
  {
    path: "#notifications",
    sidebarName: "Notificaciones",
    navbarName: "Notificaciones",
    icon: Notifications,
    adminRestricted: false,
  },
  {
    redirect: true,
    path: "/places",
    to: "/places/entries",
    navbarName: "Redirect",
  },
];

export default placesRoutes;
