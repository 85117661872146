import React from "react";
import propTypes from "prop-types";
import { DialogContent, Dialog, DialogContentText, DialogActions, Button } from "@material-ui/core";

class ConfirmDialog extends React.Component {
  handleAcceptClick = () => {
    if (this.props.onAcceptBtnClick)
      this.props.onAcceptBtnClick(this.props.payload);
  }

  handleCancelClick = () => {
    if (this.props.onCancelBtnClick)
      this.props.onCancelBtnClick();
  }

  handleOnClose = () => {
    if (this.props.onClose)
      this.props.onClose();
  }

  render() {
    const { open, contentText, acceptBtnFocus, cancelBtnText, acceptBtnText } = this.props;

    return (
      <Dialog
        open={open}
        onClose={this.handleOnClose}>
        <DialogContent>
          <DialogContentText>
            {contentText}
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button
            color="secondary"
            onClick={this.handleCancelClick}
            autoFocus={!acceptBtnFocus}
            style={{ color: 'rgb(230, 71, 59)' }}>
            {cancelBtnText || "Cancelar"}
          </Button>

          <Button color="primary" onClick={this.handleAcceptClick} autoFocus={acceptBtnFocus}>
            {acceptBtnText || "Aceptar"}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

ConfirmDialog.propTypes = {
  open: propTypes.bool,
  onClose: propTypes.func,
  contentText: propTypes.oneOfType([propTypes.string, propTypes.object]),
  acceptBtnFocus: propTypes.bool,
  acceptBtnText: propTypes.string,
  cancelBtnText: propTypes.string,
  onCancelBtnClick: propTypes.func,
  onAcceptBtnClick: propTypes.func,
  payload: propTypes.any,
}

export default ConfirmDialog;