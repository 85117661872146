import React from "react";
import PropTypes from "prop-types";
import uuid from "uuid";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";

import withStyles from "@material-ui/core/styles/withStyles";
import { TextField } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import AddIcon from "@material-ui/icons/Add";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import AuthService from "components/Services/AuthService";

const styles = theme => ({
  button: {
    margin: theme.spacing.unit,
  },
  addButton: {
    marginLeft: theme.spacing.unit,
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 400,
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120,
  },
});

const LOCALIZED_DAYS = {
  monday: "Lunes",
  tuesday: "Martes",
  wednesday: "Miércoles",
  thursday: "Jueves",
  friday: "Viernes",
  saturday: "Sábado",
  sunday: "Domingo",
};

export class RangeSelectionDialog extends React.Component {
  propTypes = {
    open: PropTypes.bool,
    form: PropTypes.object,
    cancelHandler: PropTypes.func.isRequired,
    submitHandler: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
  };

  state = {
    garbagePickupRanges: null,
    day: "",
    rangeId: "",
  };

  componentDidMount() {
    this.AuthService = new AuthService();

    this.AuthService.authFetch("/admin/garbage-pickup-range")
      .then(response => {
        const dayCheck = {
          monday: [],
          tuesday: [],
          wednesday: [],
          thursday: [],
          friday: [],
          saturday: [],
          sunday: [],
        };

        response.result.forEach(res => {
          dayCheck[res.name].push(res);
        });

        this.setState({
          loading: false,
          garbagePickupRanges: dayCheck,
        });
      })
      .catch(error => this.setState({ error: error }));
  }

  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleSave = () => {
    if (!this.state.rangeId) {
      alert("Debe seleccionar un rango de horario a salvar.");
    } else {
      this.props.handleAddRange(this.state.rangeId);
    }
  };

  render() {
    const { classes } = this.props;
    const { state } = this;

    let days = [];
    let ranges = [];

    if (state.garbagePickupRanges) {
      days = Object.keys(state.garbagePickupRanges);
    }

    if (state.day) {
      ranges = state.garbagePickupRanges[state.day];
    }

    return (
      <Dialog open={this.props.open} onClose={this.props.handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Subscribe</DialogTitle>
        <DialogContent>
          <DialogContentText>To subscribe to this website, please enter your email address here. We will send updates occasionally.</DialogContentText>
          <FormControl className={classes.formControl}>
            <InputLabel htmlFor="day-value">Seleccionar dia</InputLabel>
            <Select
              value={state.day}
              onChange={this.handleChange}
              inputProps={{
                name: "day",
                id: "day-value",
              }}>
              {days.map(el => (
                <MenuItem key={el} value={el}>
                  {LOCALIZED_DAYS[el]}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl className={classes.formControl}>
            <InputLabel htmlFor="range-id-value">Rango</InputLabel>
            <Select
              value={state.rangeId}
              onChange={this.handleChange}
              inputProps={{
                name: "rangeId",
                id: "range-id-value",
              }}>
              {ranges.map(el => (
                <MenuItem key={el.id} value={el.id}>
                  {el.startTime} || {el.endTime}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.handleClose} color="primary">
            Cancelar
          </Button>
          <Button onClick={this.handleSave} color="primary">
            Salvar
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

class GarbagePickupForm extends React.Component {
  constructor(props) {
    super(props);
    let initialForm = {
      name: null,
      pickupTimes: null,
      takeoutTimes: null,
    };

    let isEdit = false;
    if (props.form) {
      isEdit = true;
      initialForm = props.form;
    }

    this.state = {
      form: initialForm,
      selectedTakeoutTimes: initialForm.takeoutTimes,
      selectedPickupTimes: initialForm.pickupTimes,
      errorForm: {
        name: false,
      },
      isEditForm: isEdit,
      selectedDate: new Date(),
      rangeDialogOpen: false,
      typeOfRange: "",
    };

    this.AuthService = new AuthService();
  }

  handleSubmit = event => {
    event.preventDefault();
    if (this.isFormValid()) {
      const result = {
        ...this.state.form,
        categories: this.state.selectedCategories,
      };
      this.props.submitHandler(result);
    }
  };

  handleAddRange = id => {
    this.props.addRangeHandler(this.state.typeOfRange, id);
    this.setState({ rangeDialogOpen: false });
  };

  handleRangeDialogOpen = name => () => {
    this.setState({ rangeDialogOpen: true, typeOfRange: name });
  };

  handleRangeDialogClose = () => {
    this.setState({ rangeDialogOpen: false });
  };

  isFormValid = () => {
    const form = this.state.form;
    const updatedErrorForm = {
      ...this.state.errorForm,
    };
    updatedErrorForm.name = !form.name || form.name.length === 0;

    this.setState({
      errorForm: updatedErrorForm,
    });
    return !updatedErrorForm.name;
  };

  render() {
    const { classes } = this.props;

    return (
      <div>
        <form className={classes.container} noValidate autoComplete="off" onSubmit={this.handleSubmit}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <TextField
                name="name"
                label="Nombre"
                disabled
                error={this.state.errorForm.name}
                className={classes.textField}
                value={this.state.form.name}
                onChange={this.handleTextFieldChange}
                margin="normal"
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <h6 style={{ marginBottom: "0" }}>
                <span>Horarios de sacada de basura</span>
                <IconButton aria-label="Add" className={classes.addButton} onClick={this.handleRangeDialogOpen("takeoutTimes")}>
                  <AddIcon color="primary" />
                </IconButton>
              </h6>
              <List>
                {this.state.form.takeoutTimes.map(takeoutTime => (
                  <ListItem key={uuid()} role={undefined} dense>
                    <TextField
                      label="Nombre"
                      InputProps={{
                        readOnly: true,
                      }}
                      className={classes.textField}
                      value={LOCALIZED_DAYS[takeoutTime.name]}
                      margin="normal"
                    />
                    <TextField
                      label="Hora Inicial"
                      InputProps={{
                        readOnly: true,
                      }}
                      className={classes.textField}
                      value={takeoutTime.startTime}
                      margin="normal"
                    />
                    <TextField
                      label="Hora Final"
                      InputProps={{
                        readOnly: true,
                      }}
                      className={classes.textField}
                      value={takeoutTime.endTime}
                      margin="normal"
                    />
                    <IconButton aria-label="Delete" onClick={this.props.deleteRangeHandler("takeoutTimes", takeoutTime.id)}>
                      <DeleteForeverIcon color="secondary" />
                    </IconButton>
                  </ListItem>
                ))}
              </List>
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <h6 style={{ marginBottom: "0" }}>
                <span>Horarios de recogida de basura</span>
                <IconButton aria-label="Add" className={classes.addButton} onClick={this.handleRangeDialogOpen("pickupTimes")}>
                  <AddIcon color="primary" />
                </IconButton>
              </h6>
              <List>
                {this.state.form.pickupTimes.map(pickupTime => (
                  <ListItem key={uuid()} role={undefined} dense>
                    <TextField
                      label="Nombre"
                      InputProps={{
                        readOnly: true,
                      }}
                      className={classes.textField}
                      value={LOCALIZED_DAYS[pickupTime.name]}
                      margin="normal"
                    />
                    <TextField
                      label="Hora Inicial"
                      InputProps={{
                        readOnly: true,
                      }}
                      className={classes.textField}
                      value={pickupTime.startTime}
                      margin="normal"
                    />
                    <TextField
                      label="Hora Final"
                      InputProps={{
                        readOnly: true,
                      }}
                      className={classes.textField}
                      value={pickupTime.endTime}
                      margin="normal"
                    />
                    <IconButton aria-label="Delete" onClick={this.props.deleteRangeHandler("pickupTimes", pickupTime.id)}>
                      <DeleteForeverIcon color="secondary" />
                    </IconButton>
                  </ListItem>
                ))}
              </List>
            </GridItem>
          </GridContainer>
          <Button color="primary" className={classes.button} style={{ marginTop: "30px" }} onClick={this.props.cancelHandler}>
            Volver
          </Button>
        </form>

        <RangeSelectionDialog open={this.state.rangeDialogOpen} handleClose={this.handleRangeDialogClose} classes={classes} handleAddRange={this.handleAddRange} />
      </div>
    );
  }
}

GarbagePickupForm.propTypes = {
  history: PropTypes.object,
  form: PropTypes.object,
  cancelHandler: PropTypes.func.isRequired,
  submitHandler: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(GarbagePickupForm);
