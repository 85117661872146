import React from "react";
import { Route } from "react-router-dom";
import Payments from "./Payments";
import PaymentDetails from "./PaymentDetails";

function PaymentsContainer() {
  return (
    <div>
      <Route path="/payments" exact component={Payments} />
      <Route path="/payments/details/:id" exact component={PaymentDetails} />
    </div>
  );
}

export default PaymentsContainer;
