import React from "react";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import { Snackbar } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";

import GarbagePickupRangesForm from "./GarbagePickupRangesForm";
import AuthService from "components/Services/AuthService";

const styles = theme => ({
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  button: {
    margin: theme.spacing.unit,
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 400,
  },
});

class GarbagePickupRangesCreate extends React.Component {
  constructor(props) {
    super(props);
    this.AuthService = new AuthService();

    this.state = {
      showSnackbar: false,
      snackBarText: "",
    };
  }

  submitHandler = form => {
    this.AuthService.authFetch(`/admin/garbage-pickup-range`, {
      method: "POST",
      body: JSON.stringify(form),
    })
      .then(() => {
        this.props.history.goBack();
      })
      .catch(() =>
        this.setState({
          snackBarText: "Error creando rango",
          showSnackbar: true,
        })
      );
  };

  cancelHandler = () => {
    this.props.history.goBack();
  };

  render() {
    const { classes } = this.props;

    return (
      <div>
        <Grid container>
          <Grid item xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="primary">
                <h4 className={classes.cardTitleWhite}>Crear rango de horario</h4>
              </CardHeader>
              <CardBody>
                <GarbagePickupRangesForm form={this.state.form} submitHandler={this.submitHandler} cancelHandler={this.cancelHandler} />
              </CardBody>
            </Card>
          </Grid>
        </Grid>
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          open={this.state.showSnackbar}
          onClose={this.handleClose}
          autoHideDuration={6000}
          ContentProps={{
            "aria-describedby": "message-id",
          }}
          message={<span id="message-id">{this.state.snackBarText}</span>}
        />
      </div>
    );
  }
}

GarbagePickupRangesCreate.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default withStyles(styles)(GarbagePickupRangesCreate);
