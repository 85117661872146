import React from "react";

import { Select, Input, MenuItem, Checkbox, ListItemText, FormControl, InputLabel, FormControlLabel, TextField, Grid, withStyles } from "@material-ui/core";

const styles = theme => ({
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: '50%',
    maxWidth: '100%'
  },
  formControl: {
    margin: theme.spacing.unit,
    width: "50%",
    maxWidth: '100%'
  },
  [theme.breakpoints.down('md')]: {
    textField: {
      width: '100%'
    },
    formControl: {
      width: '100%'
    }
  }
});

class TemplateForm extends React.Component {
  constructor(props) {
    super(props);

    const formContent = props.form || {
      name: null,
      description: null,
      categories: [],
      status: 'ACTIVE'
    };

    const categories = props.categories || [];

    if (categories.length)
      formContent.categories = categories
        .filter(f => formContent.find(w => w.id === f.id));

    this.state = {
      categories: categories,
      form: formContent,
      formError: {
        name: false,
        description: false,
        categories: false
      },
    };

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    this.MenuProps = {
      PaperProps: {
        style: {
          maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
          minWidth: 250,
          maxWidth: 400
        },
      },
    };
  }

  handleInputChange = name => event => {
    var newForm = { ...this.state.form };
    var newErrorForm = { ...this.state.formError };

    newForm[name] = event.target.value;
    newErrorForm[name] = !newForm[name] || newForm[name].length === 0;

    this.setState({
      form: newForm,
      formError: newErrorForm
    });
  };

  handleChangeCategory = ev => {
    var newForm = { ...this.state.form };
    var newErrorForm = { ...this.state.formError };

    newForm.categories = ev.target.value;
    newErrorForm.categories = !newForm.categories.length;

    this.setState({
      form: newForm,
      formError: newErrorForm
    });
  };

  validFields() {
    var newErrorForm = { ...this.state.formError };

    newErrorForm.name = !this.state.form.name;
    newErrorForm.description = !this.state.form.description;
    newErrorForm.categories = !this.state.form.categories.length;

    this.setState({
      formError: newErrorForm
    });

    return (
      !newErrorForm.name &&
      !newErrorForm.description &&
      !newErrorForm.categories
    );
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.saveForm && !this.props.saveForm) {
      const form = { ...this.state.form };
      form.categories = form.categories.filter((e, i, o) => o.findIndex(f => f.id === e.id) === i);
      this.props.onFormSubmit(form, this.validFields());
    }

    if (nextProps.categories !== this.props.categories) {
      const { form } = { ...this.state };

      form.categories = nextProps.categories
        .filter(f => form.categories.find(w => w.id === f.id));

      this.setState({ categories: nextProps.categories, form });
    }
  }

  render() {
    const { classes, categories } = this.props;

    return (
      <Grid container>
        <form noValidate autoComplete="off" style={{ width: '100%' }}>
          <Grid item xs={12} sm={12} md={12}>
            <TextField
              id="name"
              className={classes.textField}
              label="Nombre"
              placeholder="Nombre de la plantilla"
              required
              margin="normal"
              error={this.state.formError.name}
              value={this.state.form.name}
              onChange={this.handleInputChange("name")}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <TextField
              id="description"
              className={classes.textField}
              label="Descripción"
              placeholder="Contenido de la plantilla"
              multiline
              rows="2"
              rowsMax="8"
              required
              margin="normal"
              error={this.state.formError.description}
              value={this.state.form.description}
              onChange={this.handleInputChange("description")}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <FormControl className={classes.formControl}>
              <InputLabel htmlFor="cats">Categorías *</InputLabel>
              <Select
                multiple
                value={this.state.form.categories}
                onChange={this.handleChangeCategory}
                input={<Input id="cats" />}
                renderValue={selected =>
                  selected
                    // .filter((e, i, o) => o.findIndex(f => f.id === e.id) === i)
                    .map(m => m.name.capitalize()).join(', ')}
                error={this.state.formError.categories}
                MenuProps={this.MenuProps}>
                {categories.map(category => (
                  <MenuItem key={category.id} value={category}>
                    <Checkbox checked={this.state.form.categories.indexOf(category) !== -1} />
                    <ListItemText primary={category.name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <br />
            <FormControlLabel
              className={classes.textField}
              control={<Checkbox
                checked={this.state.form.status === 'ACTIVE'}
                onChange={(event) => {
                  const newForm = { ...this.state.form };
                  newForm.status = event.target.checked ? 'ACTIVE' : 'INACTIVE';
                  this.setState({ form: newForm });
                }}
                value={this.state.form.status} />}
              label="Activar plantilla?"
            />
          </Grid>
        </form>
      </Grid>
    );
  }
}

export default withStyles(styles)(TemplateForm);