import React from "react";
// @material-ui/core components
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpansionPanelActions from "@material-ui/core/ExpansionPanelActions";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import Divider from "@material-ui/core/Divider";

// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Button from "components/CustomButtons/Button.jsx";

import AuthService from "components/Services/AuthService";

class IncidentFilter extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      status: [],
      handler: [],
      solvers: [],
      categories: [],
      closedCategories: [],
      categoriesList: [],
      closedCategoriesList: [],
      dateStart: "",
      dateEnd: "",
      handlerList: [...(props.handlerList || [])],
      solverList: [...(props.solverList || [])]
    };

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    this.MenuProps = {
      PaperProps: {
        style: {
          maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
          minWidth: 250,
          maxWidth: 400,
        },
      },
    };

    this.statusList = [
      {
        value: "OPEN",
        label: "En espera de revisión",
      },
      {
        value: "WITH_HANDLER",
        label: "En manos de manejador",
      },
      {
        value: "WITH_SOLVER",
        label: "En manos de solucionador",
      },
      {
        value: "IN_PROGRESS",
        label: "En progreso",
      },
      {
        value: "SOLUTION_COMPLETED",
        label: "Solucion completada",
      },
      {
        value: "CLOSED",
        label: "Completado",
      },
    ];

    this.AuthService = new AuthService();
  }

  componentWillReceiveProps(nextProps) {
    const newState = {};

    if (nextProps.handlerList !== this.props.handlerList)
      newState.handlerList = [...(nextProps.handlerList || [])];

    if (nextProps.solverList !== this.props.solverList)
      newState.solverList = [...(nextProps.solverList || [])];

    if (Object.keys(newState).length)
      this.setState(newState);
  }

  multipleChoiceHandleChange = name => event => {
    this.setState({ [name]: event.target.value });
  };

  dateHandleChange = name => event => {
    this.setState({
      [name]: event.target.value,
    });
  };

  handleApplyFilter = () => {
    const filterData = {
      categories: this.state.categories.map(val => val.id),
      closedCategories: this.state.closedCategories.map(val => val.id),
      dateEnd: this.state.dateEnd,
      dateStart: this.state.dateStart,
      status: this.state.status.map(val => val.value),
      handler: this.state.handler.map(val => val.value),
      solvers: this.state.solvers.map(val => val.value)
    };

    this.props.filterIncidentsHandler(filterData);
  };

  handleDownloadFilterClick = () => {
    if (this.props.downloadIncidentsHandler)
      this.props.downloadIncidentsHandler();
  };

  cleanFilter = () => {
    this.setState({
      status: [],
      handler: [],
      solvers: [],
      categories: [],
      closedCategories: [],
      categoriesList: [],
      closedCategoriesList: [],
      dateStart: "",
      dateEnd: "",
    })
    if (this.props.cleanFilter){
      this.props.cleanFilter();
    }
  }
  componentDidMount() {
    this.AuthService.authFetch("/admin/categories")
      .then(res => {
        // const profile = this.AuthService.getProfile();
        const closedCategoriesList = res.result.filter(category => {
          return category.type === "CLOSED";
        });
        const categoriesList = res.result.filter(category => {
          return category.type === "REGULAR";
        });

        this.setState({
          categoriesList,
          closedCategoriesList,
        });
      })
      .catch(error => {
        this.setState({
          isLoaded: true,
          error,
        });
      });
  }

  render() {
    const { classes } = this.props;

    return (
      <ExpansionPanel>
        <ExpansionPanelSummary className={classes.expandableSummary} expandIcon={<ExpandMoreIcon />}>
          <h4 className={classes.expandableTitle}>Filtros</h4>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <form className={classes.container} noValidate autoComplete="off">
            <GridContainer>
              <GridItem xs={12}>
                <FormControl className={classes.formControl}>
                  <InputLabel htmlFor="status">Estatus</InputLabel>
                  <Select
                    multiple
                    value={this.state.status}
                    onChange={this.multipleChoiceHandleChange("status")}
                    input={<Input id="status" />}
                    renderValue={selected => selected.map(sel => sel.label).join(", ")}
                    MenuProps={this.MenuProps}>
                    {this.statusList.map(option => (
                      <MenuItem key={option.value} value={option}>
                        <Checkbox checked={this.state.status.indexOf(option) > -1} />
                        <ListItemText primary={option.label} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </GridItem>

              <GridItem xs={12}>
                <FormControl className={classes.formControl}>
                  <InputLabel htmlFor="handler">Manejador</InputLabel>
                  <Select
                    multiple
                    disabled={this.state.handlerList.length === 0}
                    value={this.state.handler}
                    onChange={this.multipleChoiceHandleChange("handler")}
                    input={<Input id="handler" />}
                    renderValue={selected => selected.map(sel => sel.label).join(", ")}
                    MenuProps={this.MenuProps}>
                    {this.state.handlerList.map(option => (
                      <MenuItem key={option.value} value={option}>
                        <Checkbox checked={this.state.handler.indexOf(option) > -1} />
                        <ListItemText primary={option.label} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </GridItem>

              <GridItem xs={12}>
                <FormControl className={classes.formControl}>
                  <InputLabel htmlFor="solvers">Solucionador</InputLabel>
                  <Select
                    multiple
                    disabled={this.state.solverList.length === 0}
                    value={this.state.solvers}
                    onChange={this.multipleChoiceHandleChange("solvers")}
                    input={<Input id="solvers" />}
                    renderValue={selected => selected.map(sel => sel.label).join(", ")}
                    MenuProps={this.MenuProps}>
                    {this.state.solverList.map(option => (
                      <MenuItem key={option.value} value={option}>
                        <Checkbox checked={this.state.solvers.indexOf(option) > -1} />
                        <ListItemText primary={option.label} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </GridItem>

              <GridItem xs={12} sm={6} md={6}>
                <FormControl className={classes.formControl}>
                  <InputLabel htmlFor="categories">Categoría</InputLabel>
                  <Select
                    multiple
                    value={this.state.categories}
                    onChange={this.multipleChoiceHandleChange("categories")}
                    input={<Input id="categories" />}
                    renderValue={selected => selected.map(sel => sel.name).join(", ")}
                    MenuProps={this.MenuProps}>
                    {this.state.categoriesList.map(option => (
                      <MenuItem key={option.id} value={option}>
                        <Checkbox checked={this.state.categories.indexOf(option) > -1} />
                        <ListItemText primary={option.name} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </GridItem>

              <GridItem xs={12} sm={6} md={6}>
                <FormControl className={classes.formControl}>
                  <InputLabel htmlFor="closedCategories">Categoría de Cierre</InputLabel>
                  <Select
                    multiple
                    value={this.state.closedCategories}
                    onChange={this.multipleChoiceHandleChange("closedCategories")}
                    input={<Input id="closedCategories" />}
                    renderValue={selected => selected.map(sel => sel.name).join(", ")}
                    MenuProps={this.MenuProps}>
                    {this.state.closedCategoriesList.map(option => (
                      <MenuItem key={option.id} value={option}>
                        <Checkbox checked={this.state.closedCategories.indexOf(option) > -1} />
                        <ListItemText primary={option.name} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </GridItem>
              <GridItem xs={12} sm={6} md={6}>
                <TextField
                  id="dateStart"
                  label="Desde"
                  type="date"
                  value={this.state.dateStart}
                  className={classes.textField}
                  onChange={this.dateHandleChange("dateStart")}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  margin="normal"
                />
              </GridItem>

              <GridItem xs={12} sm={6} md={6}>
                <TextField
                  id="dateEnd"
                  label="Hasta"
                  type="date"
                  value={this.state.dateEnd}
                  className={classes.textField}
                  onChange={this.dateHandleChange("dateEnd")}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  margin="normal"
                />
              </GridItem>
            </GridContainer>
          </form>
        </ExpansionPanelDetails>
        <Divider />
        <ExpansionPanelActions>
          <Button onClick={this.handleDownloadFilterClick} color="primary" style={{ marginRight: "8px" }}>
            Descargar lista
          </Button>

          <Button onClick={this.handleApplyFilter} color="primary" style={{ marginRight: "8px" }}>
            Aplicar
          </Button>

          <Button onClick={this.cleanFilter} color="primary" style={{ marginRight: "8px" }}>
            Limpiar filtro
          </Button>
        </ExpansionPanelActions>
      </ExpansionPanel>
    );
  }
}

export default IncidentFilter;
