import React from "react";
import { Grid, withStyles, TextField, Snackbar } from "@material-ui/core";

import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import Button from "components/CustomButtons/Button.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import Select from "components/Select/Select.jsx";
import CustomEditor from "components/CustomInput/CustomEditor";
import CustomFileSelector from "components/CustomInput/CustomFileSelector";
import CircularProgress from "@material-ui/core/CircularProgress";
import CheckIcon from '@material-ui/icons/Check';
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

import { primaryColor } from "assets/jss/material-dashboard-react.jsx";
import { POST_STATUS } from "variables/general.jsx";
import AuthService from "components/Services/AuthService";
import ConfirmDialog from "../../components/CustomDialogs/ConfirmDialog";
import { dateTimeFormat } from "utils/DateUtil.jsx";


const styles = theme => ({
  rootContent: {
    position: "relative",
  },
  coverLoading: {
    position: "absolute",
    height: "100%",
    width: "100%",
    zIndex: 1,
    backgroundColor: "#ddd",
    opacity: 0.3,
  },
  progress: {
    color: primaryColor,
    position: "absolute",
    top: "calc(50% - 4em)",
    left: "calc(50% - 4em)",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: '100%',
    boxSizing: 'border-box',
    paddingRight: theme.spacing.unit * 2,
  },
  selectContent: {
    margin: `${theme.spacing.unit * 3}px ${theme.spacing.unit}px
    ${theme.spacing.unit * 3}px ${theme.spacing.unit}px`,
  },
  dividerSecction: {
    height: "48px",
    borderBottom: "1px solid rgba(224, 224, 224, 1)",
    backgroundColor: "#eeeeee50",
    padding: `0 ${theme.spacing.unit * 3}px ${theme.spacing.unit}px ${theme.spacing.unit * 3}px`,
    marginBottom: theme.spacing.unit,
  },
  title: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
    marginBottom: theme.spacing.unit,
    paddingTop: theme.spacing.unit * 3,
    color: primaryColor,
  },
  notiSnack: {
    backgroundColor: '#eee',

    '& #noti-message': {
      display: 'flex',
      color: 'black',

      '& #noti-message-icon': {
        fontSize: 20,
        opacity: 0.9,
        marginRight: theme.spacing.unit,
        color: 'green',
      }
    }
  },
  close: {
    width: theme.spacing.unit * 4,
    height: theme.spacing.unit * 4,
    color: 'darkgray',
  },
});

const formatDateTime = date => dateTimeFormat(date && date.includes('T') && !date.endsWith('Z') ? `${date}Z` : date);

class ManagePost extends React.Component {
  constructor(props) {
    super(props);

    this.statusOptions = Object.keys(POST_STATUS)
      .map(m => ({
        value: m,
        label: POST_STATUS[m],
      }));

    this.AuthService = new AuthService();
    this.action = props.location.pathname.includes('create') ? 'create' : 'edit';
    this.postID = props.match.params.id;
    this.wpREST = null;
    this.thumbnail = null;
    this.metaDataBuffer = {};
    this.formBuffer = {};

    this.loadedAsyncBoard = {
      categories: false,
      post: this.action === 'create',
      thumbnail: this.action === 'create',
      author: this.action === 'create',
    };

    this.state = {
      loading: true,
      snackError: {
        open: false,
        payload: null,
      },
      notiSnack: {
        open: false,
        payload: null,
      },
      confirmDialog: {
        open: false,
        payload: null,
      },
      metaData: {},
      categories: [],
      files: [],
      contentFiles: {},
      selectedImage: null,
      form: {
        title: '',
        content: '',
        status: null,
        categories: [],
      },
      formError: {
        title: false,
        content: false,
        status: false,
        categories: false,
      },
    };
  }

  componentDidMount() {
    const getCategories = () => this.AuthService.authFetch("/admin/news/categories")
      .then(res => {
        this.loadedAsyncBoard.categories = true;

        const newState = {
          categories: res.result.map(m => ({
            value: m.id,
            label: m.name,
          })),
        };

        if (this.allAsyncProcessesDone())
          newState.loading = false;

        if (this.formBuffer.categories && this.formBuffer.categories.length)
          newState.form = {
            ...this.formBuffer,
            status: this.statusOptions.find(f => f.value === this.formBuffer.status),
            categories: this.formBuffer.categories
              .map(m => newState.categories.find(f => f.value === m))
              // To filter only found categories
              .filter(f => f),
          };

        this.setState(newState);
      })
      .catch(reason => {
        console.log('Failed to load categories:', reason);
        this.loadedAsyncBoard.categories = true;

        const newState = {
          snackError: {
            open: true,
            payload: 'Ocurrió un error cargando las categorías para las noticias... intente nuevamente más tarde',
          },
        };

        if (this.allAsyncProcessesDone())
          newState.loading = false;

        this.setState(newState);
      });

    const getAuthor = () => this.AuthService.authFetch(`/admin/news/authors/${this.metaDataBuffer.author}`)
      .then(res => {
        this.loadedAsyncBoard.author = true;

        const newState = {
          metaData: {
            ...this.metaDataBuffer,
            author: res.result.name
          }
        };

        if (this.allAsyncProcessesDone())
          newState.loading = false;

        this.setState(newState);
      })
      .catch(reason => {
        console.log('Failed to load author:', reason);
        this.loadedAsyncBoard.author = true;

        const newState = {
          metaData: {
            ...this.metaDataBuffer,
            author: (<i>Unknown</i>)
          },
          snackError: {
            open: true,
            payload: 'Ocurrió un error recibiendo información del autor... intente nuevamente más tarde',
          },
        };

        if (this.allAsyncProcessesDone())
          newState.loading = false;

        this.setState(newState);
      });

    const getFeatured = () => this.AuthService.authFetch(`/admin/news/file/${this.thumbnail}`)
      .then(res => {
        this.loadedAsyncBoard.thumbnail = true;
        this.metaDataBuffer.thumbnailURL = res.result.url;
        this.metaDataBuffer.thumbnailName = res.result.title;

        const newState = {
          selectedImage: {
            url: res.result.url,
            file: {
              name: res.result.title,
            },
          }
        };

        if (this.allAsyncProcessesDone())
          newState.loading = false;

        this.setState(newState);
      })
      .catch(reason => {
        console.log('Failed to load thumbnail:', reason);
        this.loadedAsyncBoard.thumbnail = true;

        const newState = {
          snackError: {
            open: true,
            payload: 'Ocurrió un error cargando la imagen destacada... intente nuevamente más tarde',
          },
        };

        if (this.allAsyncProcessesDone())
          newState.loading = false;

        this.setState(newState);
      });

    if (this.action === 'edit')
      this.AuthService.authFetch(`/admin/news/get/${this.postID}`)
        .then(res => {
          const { result } = res;

          this.wpREST = result.wpREST;
          this.thumbnail = result.thumbnail;
          this.loadedAsyncBoard.post = true;

          this.metaDataBuffer = {
            creationDate: result.creationDate,
            link: result.link,
            modDate: result.modDate,
            author: result.author,
          };

          this.formBuffer = {
            title: result.title,
            content: result.content,
            categories: result.categories,
            status: result.status,
          };

          getCategories();

          if (this.action === 'edit') {
            getAuthor();

            if (this.thumbnail)
              getFeatured();
            else
              this.loadedAsyncBoard.thumbnail = true;
          }
        })
        .catch(reason => {
          console.log('Failed to load post:', reason);
          this.loadedAsyncBoard.post = true;

          this.setState({
            loading: false,
            snackError: {
              open: true,
              payload: 'Ocurrió un error cargando la noticia... intente nuevamente más tarde',
            },
          });
        });
    else
      getCategories();
  }

  allAsyncProcessesDone = () => {
    return Object.keys(this.loadedAsyncBoard).every(e => this.loadedAsyncBoard[e]);
  }

  handleInputChange = name => (event, extraObj) => {
    const { form, formError } = { ...this.state };
    const eventfulFields = ['title'];

    if (eventfulFields.includes(name))
      form[name] = (event.target.value || '');
    else
      form[name] = event;

    formError[name] = Array.isArray(form[name]) ? !form[name].length : !form[name];

    const newState = { form, formError };

    if (name === 'content')
      newState.contentFiles = extraObj.imageMapper;

    this.setState(newState);
  };

  validation = () => {
    const { form, formError } = { ...this.state };
    let fieldWithError;

    const newState = {};

    const fieldName = {
      title: 'Título',
      content: 'Contenido',
      status: 'Estado',
      categories: 'Categorías',
    };

    Object.keys(form).forEach(f => {
      if (typeof form[f] === 'string')
        form[f] = form[f].trim();

      formError[f] = Array.isArray(form[f]) ? !form[f].length : !form[f];
    });

    newState.form = form;
    newState.formError = formError;

    const someError = Object.keys(formError).some(s => {
      if (formError[s])
        fieldWithError = s;

      return formError[s];
    });

    if (someError)
      newState.snackError = {
        open: true,
        payload: `El campo "${fieldName[fieldWithError] || fieldWithError}" debe tener un valor válido`,
      };
    else
      newState.loading = true;

    this.setState(newState);

    return someError;
  };

  uploadImages = file => {
    const formData = new FormData();
    formData.append('file', file);

    return this.AuthService.authFetch('/admin/news/file', {
      method: 'POST',
      body: formData,
      headers: {
        'Content-Type': null,
      },
    });
  };

  savePost = payload => {
    this.AuthService.authFetch(this.action === 'create' ?
      '/admin/news/create' :
      `/admin/news/update/${this.postID}?booleanResponse=false`, {
        method: this.action === 'create' ? 'POST' : 'PUT',
        body: JSON.stringify(payload),
      })
      .then(res => {
        if (res.result === true || Object.keys(res.result).length) {
          if (this.action === 'create')
            this.props.history.push('/news');
          else {
            this.metaDataBuffer = {
              ...this.metaDataBuffer,
              creationDate: res.result.creationDate,
              link: res.result.link,
              modDate: res.result.modDate,
              author: res.result.author,
            };

            this.formBuffer = {
              title: res.result.title,
              content: res.result.content,
              categories: res.result.categories,
              status: res.result.status,
            };

            this.setState({
              loading: false,
              notiSnack: {
                open: true,
                payload: 'La noticia fue actualizada',
              },
              form: {
                ...this.formBuffer,
                categories: this.formBuffer.categories.map(m => this.state.categories.find(f => f.value === m)),
                status: this.statusOptions.find(f => f.value === this.formBuffer.status),
              },
              metaData: {
                ...this.metaDataBuffer,
                author: this.state.metaData.author,
              },
              selectedImage: this.metaDataBuffer.thumbnailURL ? {
                url: this.metaDataBuffer.thumbnailURL,
                file: {
                  name: this.metaDataBuffer.thumbnailName,
                },
              } : null,
            });
          }
        }
        else
          this.setState({
            loading: false,
            snackError: {
              open: true,
              payload: 'Ocurrió un error guardando la noticia... intente nuevamente más tarde',
            },
          });
      })
      .catch(reason => {
        console.log('Failed to save post:', reason);

        this.setState({
          loading: false,
          snackError: {
            open: true,
            payload: `Ocurrió un error ${{ create: 'creando', edit: 'editando' }[this.action]} la noticia... intente nuevamente más tarde`,
          },
        });
      });
  };

  handleSave = () => {
    const { form, files, contentFiles, selectedImage } = { ...this.state };

    const foundErrors = this.validation();

    if (foundErrors)
      return;

    const payload = {
      ...form,
      status: form.status.value,
      categories: form.categories.map(m => m.value),
      featured_media: this.thumbnail && !selectedImage ? 0 : undefined,
    };

    if (this.action === 'edit' && this.formBuffer.title === form.title)
      delete payload.title;

    const fileBundle = files
      .map(m => ({
        file: m,
        promise: null,
        callback: response => {
          payload.featured_media = response.result.id;

          if (this.action === 'edit') {
            this.thumbnail = response.result.id;
            this.metaDataBuffer.thumbnailURL = response.result.url;
            this.metaDataBuffer.thumbnailName = response.result.title;
          }
        },
      }))
      .concat(
        Object.keys(contentFiles)
          .filter(f => payload.content.includes(f))
          .map(m => ({
            file: contentFiles[m],
            promise: null,
            callback: response => {
              payload.content = payload.content.replace(new RegExp(m, 'g'), response.result.url);
            },
          }))
      );

    fileBundle.forEach(item => {
      item.promise = new Promise(resolve => {
        this.uploadImages(item.file)
          .then(result => {
            item.callback(result);
            resolve();
          });
      });
    });

    Promise.all(fileBundle.map(m => m.promise))
      .then(() => {
        this.savePost(payload);
      })
      .catch(reason => {
        console.log('Failed saving post:', reason);

        this.setState({
          loading: false,
          snackError: {
            open: true,
            payload: `Ocurrió un problema ${{ create: 'creando', edit: 'editando' }[this.action]} la noticia... intente nuevamente más tarde`,
          },
        });
      });
  };

  // retrieveChangedContent = () => {
  //   const { form } = this.state;

  //   if (this.action === 'create')
  //     return {
  //       ...form,
  //       status: form.status.value,
  //       categories: form.categories.map(m => m.value),
  //     };

  //   const result = {};

  //   Object.keys(form).forEach(f => {
  //     if (typeof form[f] !== 'object') {
  //       if (form[f] !== this.formBuffer[f])
  //         result[f] = form[f];

  //       return;
  //     }

  //     if (Array.isArray(form[f])) {
  //       if (form[f].length !== this.formBuffer[f].length
  //         || form[f].some(s => !this.formBuffer[f].includes(s.value)))
  //         result[f] = form[f].map(m => m.value);

  //       return;
  //     }

  //     if (form[f].value !== this.formBuffer[f])
  //       result[f] = form[f].value;
  //   });

  //   return result;
  // };

  onNotiSnackClose = (_event, reason) => {
    if (reason === "clickaway")
      return;

    this.setState({
      notiSnack: {
        open: false,
        payload: null,
      }
    });
  };

  render() {
    const { loading, form, formError, categories, snackError, metaData, notiSnack, confirmDialog, selectedImage } = this.state;
    const { classes } = this.props;

    if (loading)
      return (<CircularProgress className={classes.progress} size={50} />);

    return (
      <Grid container>
        <Grid item xs={12} sm={12} md={12}>
          {this.action === 'edit' && (
            <Grid container>
              <Grid item xs={12} sm={12} md={12}>
                <Card>
                  <CardHeader color="primary">
                    <h4 className={classes.cardTitleWhite}>Detalles</h4>
                  </CardHeader>

                  <CardBody>
                    <Grid container>
                      <Grid item xs={12} sm={6} md={6}>
                        <b>Autor: </b>
                        {metaData.author}
                      </Grid>

                      <Grid item xs={12} sm={6} md={6}>
                        <b>Fecha creación: </b>
                        {formatDateTime(metaData.creationDate)}
                      </Grid>

                      <Grid item xs={12} sm={6} md={6}>
                        <b>Fecha modificación: </b>
                        {formatDateTime(metaData.modDate)}
                      </Grid>

                      <Grid item xs={12} sm={12} md={12}>
                        <br />
                        <Button
                          color="primary"
                          onClick={() => {
                            if (this.action === 'edit' && this.formBuffer && this.formBuffer.status === 'publish')
                              window.open(metaData.link, '_blank');
                            else {
                              this.setState({
                                confirmDialog: {
                                  open: true,
                                  payload: () => {
                                    window.open(metaData.link, '_blank');
                                  },
                                }
                              });
                            }
                          }}>
                          Ver noticia
                      </Button>
                      </Grid>
                    </Grid>
                  </CardBody>
                </Card>
              </Grid>
            </Grid>
          )}

          <Grid container>
            <Grid item xs={12} sm={12} md={12}>
              <Card>
                <CardHeader color="primary">
                  <h4 className={classes.cardTitleWhite}>{{ create: 'Crear noticia', edit: 'Editar' }[this.action] || this.action}</h4>
                </CardHeader>

                <CardBody>
                  <div className={classes.dividerSecction}>
                    <h4 className={classes.title}>Información general</h4>
                  </div>

                  <Grid container>
                    <Grid item xs={12} sm={12} md={12}>
                      <TextField
                        id="title"
                        className={classes.textField}
                        label="Título"
                        required
                        margin="normal"
                        error={formError.title}
                        value={form.title}
                        onChange={this.handleInputChange("title")}
                        autoComplete='off'
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={12}>
                      <div className={classes.selectContent}>
                        <Select
                          options={this.statusOptions}
                          onChange={this.handleInputChange('status')}
                          error={formError.status}
                          value={form.status}
                          placeholder='Seleccione el estado de la noticia'
                        />
                      </div>
                    </Grid>

                    <Grid item xs={12} sm={12} md={12}>
                      <div className={classes.selectContent}>
                        <Select
                          options={categories}
                          onChange={this.handleInputChange('categories')}
                          error={formError.categories}
                          value={form.categories}
                          multiple
                          placeholder='Seleccione las categorías'
                        />
                      </div>
                    </Grid>

                    <Grid item xs={12} sm={12} md={12}>
                      <br />
                      <CustomFileSelector
                        buttonText='Imagen destacada'
                        variant='grid'
                        selectedMask={selectedImage}
                        onFileChange={files => {
                          const newState = { files };

                          if (this.state.selectedImage !== null)
                            newState.selectedImage = null;

                          this.setState(newState);
                        }}
                      />
                    </Grid>
                  </Grid>

                  <div className={classes.dividerSecction}>
                    <h4 className={classes.title}>Contenido</h4>
                  </div>

                  <Grid container>
                    <Grid item xs={12} sm={12} md={12}>
                      <CustomEditor
                        initialValue={form.content}
                        onValueChange={this.handleInputChange('content')}
                      />
                    </Grid>
                  </Grid>
                </CardBody>

                <CardFooter style={{ justifyContent: "normal" }}>
                  <Button color="primary" style={{ marginRight: "8px" }} onClick={this.handleSave}>
                    Guardar
                  </Button>

                  <Button
                    onClick={() => {
                      this.props.history.push('/news');
                    }}>
                    Cancelar
                  </Button>
                </CardFooter>
              </Card>
            </Grid>
          </Grid>
        </Grid>

        {snackError.open && (
          <Snackbar
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            open
            onClose={() => {
              this.setState({
                snackError: {
                  open: false,
                  payload: null,
                }
              });
            }}
            ContentProps={{
              "aria-describedby": "error-message-id",
            }}
            message={<span id="error-message-id">{snackError.payload}</span>}
          />
        )}

        {notiSnack.open && (
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            open
            onClose={this.onNotiSnackClose}
            ContentProps={{
              "aria-describedby": "noti-message",
              className: classes.notiSnack,
            }}
            style={{ marginTop: '10px' }}
            message={
              <span id="noti-message">
                <CheckIcon id="noti-message-icon" />
                <span>{notiSnack.payload}</span>
              </span>
            }
            action={[
              <IconButton key="close" aria-label="Close" color="inherit" className={classes.close} onClick={this.onNotiSnackClose}>
                <CloseIcon />
              </IconButton>,
            ]}
          />
        )}

        {confirmDialog.open && (
          <ConfirmDialog
            open
            payload={confirmDialog.payload}
            contentText={(
              <span style={{ display: 'block' }}>
                <span style={{ display: 'block', marginBottom: 15 }}>
                  El estado actual de la noticia no es "PUBLICADA", por lo que debe tener una sesión iniciada en el WordPress para poder visualizarla.
                </span>

                <a
                  href={`${this.wpREST.endsWith('.php') ?
                    this.wpREST.substr(0, this.wpREST.lastIndexOf('/')) :
                    this.wpREST}/wp-login.php?redirect_to=${metaData.link}`}
                  onClick={() => {
                    this.setState({
                      confirmDialog: {
                        open: false,
                        payload: null,
                      }
                    });
                  }}
                  target="_blank"
                  rel="noopener noreferrer">
                  Ir a la página de inicio de sesión
                </a>
              </span>
            )}
            acceptBtnText="Ir de todos modos"
            onAcceptBtnClick={payload => {
              payload();

              this.setState({
                confirmDialog: {
                  open: false,
                  payload: null,
                }
              });
            }}
            onCancelBtnClick={() => {
              this.setState({
                confirmDialog: {
                  open: false,
                  payload: null,
                },
              });
            }}
          />
        )}
      </Grid>
    );
  }
}


export default withStyles(styles)(ManagePost);
