import React from "react";

import propTypes from "prop-types";
import { withStyles, Table, TableHead, TableRow, TableCell, TableBody, TablePagination, TextField, TableSortLabel, Button } from "@material-ui/core";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { defaultFont } from "assets/jss/material-dashboard-react.jsx";
//import CloseIcon from "@material-ui/icons/Close";
import CircularProgress from "@material-ui/core/CircularProgress";

const styles = theme => ({
  table: {
    marginBottom: "0",
    width: "100%",
    maxWidth: "100%",
    backgroundColor: "transparent",
    borderSpacing: "0",
    borderCollapse: "collapse",
  },
  tableHeadCell: {
    color: "inherit",
    ...defaultFont,
    fontSize: "1em",
  },
  tableCell: {
    ...defaultFont,
    lineHeight: "1.42857143",
    padding: "12px 8px",
    verticalAlign: "middle",
  },
  tableResponsive: {
    width: "100%",
    marginTop: theme.spacing.unit * 3,
    overflowX: "auto",
  },
  filterButton: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    width: '100%',
    height: '100%',
  },
  customBadge: {
    marginTop: theme.spacing.unit / 4,
    color: 'white',
    fontWeight: 'bold',
    backgroundColor: '#2a61ad',
    padding: '5px 10px',
    borderRadius: '20px',
    marginBottom: '10px',
    display: 'inline-flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    boxSizing: 'border-box',
    flex: '0 0 auto',
    maxHeight: 34,
    '&:not(:first-child)': {
      marginLeft: '3px'
    },
  },
  badgeHolder: {
    display: 'flex',
    flex: 1,
    textAlign: 'left',
    overflowX: 'auto',
  },
  badgeCloser: {
    fontSize: '15pt',
    marginLeft: '2px',
    cursor: 'pointer',
    padding: '0 6px',
    borderRadius: '20px',
    transition: 'background-color ease-in-out .1s',
    '&:hover': {
      backgroundColor: '#295189'
    },
    '&:active': {
      backgroundColor: '#274877'
    }
  },
  [theme.breakpoints.down('sm')]: {
    filterField: {
      width: '100%',
      marginBottom: '10px'
    }
  }
});

class PaginatedTable2 extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: props.loading || false,
      headerList: props.headerList || [],
      tableContent: props.tableContent || [],
      tableContentProps: props.tableContentProps || [],
      rowsPerPage: props.rowsPerPage || 10,
      page: props.page || 0,
      totalCount: props.totalCount || 0,
      filter: '',
      order: props.initialOrder || 'asc',
      orderBy: '',
      compact: !!props.compact,
      orderable: typeof props.orderable === 'boolean' ? props.orderable : true,
    };
  }

  componentWillReceiveProps(nextProps) {
    const nextState = {};

    if (nextProps.headerList !== this.props.headerList)
      nextState.headerList = [...nextProps.headerList];

   // if (nextProps.tableContent !== this.props.tableContent)
      nextState.tableContent = [...nextProps.tableContent];

    if (nextProps.tableContentProps !== this.props.tableContentProps)
      nextState.tableContentProps = [...nextProps.tableContentProps];

    if (nextProps.rowsPerPage !== this.props.rowsPerPage)
      nextState.rowsPerPage = nextProps.rowsPerPage;

    if (nextProps.page !== this.props.page)
      nextState.page = nextProps.page;

    if (nextProps.totalCount !== this.props.totalCount)
      nextState.totalCount = nextProps.totalCount;

    if (nextProps.hideFilter !== this.props.hideFilter)
      nextState.hideFilter = nextProps.hideFilter;

    if (nextProps.loading !== this.props.loading)
      nextState.loading = nextProps.loading;

    if (nextProps.disableDeepFilterCheck !== this.props.disableDeepFilterCheck)
      nextState.disableDeepFilterCheck = nextProps.disableDeepFilterCheck;

    this.setState(nextState);
  }

  handleChangePage = (_event, page) => {
    this.setState({ page },() => {
      this.updateData();
    });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value },() => {
      this.updateData();
    });
  };

  //////////////////////////////////

  createSortHandler = property => () => {
    const { order, orderBy } = this.state;
    const isDesc = orderBy === property && order === 'desc';

    this.setState({
      order: isDesc ? 'asc' : 'desc',
      orderBy: property,
    },() => {
      this.updateData();
    });
  };

  updateData = () => {
    if (typeof this.props.getData === "function") {
      this.props.getData(
        this.state.page,
        this.state.rowsPerPage,
        this.state.filter,
        this.state.orderBy,
        this.state.order
      )
    }
  }

  handleFilterChange = (filterVal, dropFixed = false) => {
    
    const newVal = filterVal.trimLeft();
    let timeOut = null;

    if(this.state.timeOut){
      clearTimeout(this.state.timeOut);
    }

    timeOut = setTimeout(() => {
      this.setState({
          page: 0
      },() => {
        this.updateData();
      });
    },2000);

    this.setState({
      filter: newVal,
      timeOut : timeOut
    });

  };

  fixFilter = () => {
    if(this.state.timeOut){
      clearTimeout(this.state.timeOut);
    }

    this.setState({
      page: 0,
      filter: '',
      timeOut : null
    },() => {
      this.updateData();
    });
  }

  render() {
    const { classes, fillEmptyRows, onRowClick } = this.props;
    const { headerList, tableContent, tableContentProps, rowsPerPage, page, totalCount, filter, compact, orderable, order, orderBy, loading } = this.state;

    const collectionToShow = tableContent;
    const rowKeyName = this.props.rowKeyPropName || '__id';
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, totalCount - page * rowsPerPage);

    return (
      <div className={classes.tableResponsive}>
        {loading && (
          <GridContainer style={{ textAlign: 'right', maxWidth: '100%' }}>
            <GridItem xs={12} sm={12} md={12} lg={12}>
              <CircularProgress size={20} />
            </GridItem>
          </GridContainer>
        )}

        <GridContainer style={{ textAlign: 'right', maxWidth: '100%' }}>
          <GridItem xs={12} sm={12} md={12} lg={8}>
          </GridItem>

          <GridItem xs={9} sm={10} md={10} lg={3}>
            <TextField
              label="Filtro rápido"
              className={classes.filterField}
              value={filter}
              onChange={event => {
                try {
                  this.handleFilterChange(event.target.value);
                }
                catch (ex) {
                  console.log('Failed filtering table:', ex);
                }
              }}
              margin="normal"
              style={{ minWidth: '50%' }}
            />
          </GridItem>

          <GridItem xs={3} sm={2} md={2} lg={1}>
            <div className={classes.filterButton}>
              <Button
                onClick={this.fixFilter}>
                Limpiar filtro
              </Button>
            </div>
          </GridItem>
        </GridContainer>

        <Table className={classes.table} size={compact ? 'small' : 'medium'}>
          <TableHead>
            <TableRow>
              {headerList.map((m, i) => {
                const identifier = tableContentProps ? tableContentProps[i] : i;
                return (
                  <TableCell
                    style={{ fontWeight: 'bold' }}
                    className={classes.tableCell + " " + classes.tableHeadCell}
                    key={`header_${identifier}`}>
                    {orderable && totalCount && !(tableContent[0][identifier].$$typeof &&
                      tableContent[0][identifier].$$typeof.toString() === Symbol('react.element').toString()) ? (
                        <TableSortLabel
                          active={orderBy === identifier}
                          direction={order}
                          onClick={this.createSortHandler(identifier)}>
                          {m}
                        </TableSortLabel>
                      ) : m}
                  </TableCell>
                )
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {collectionToShow
              .map(row => {
                if (Array.isArray(row))
                  return (<TableRow
                    hover
                    style={!!onRowClick ? { cursor: 'pointer' } : {}}
                    onClick={() => { if (onRowClick) onRowClick(row); }}>
                    {row.map((column, i) => (
                      <TableCell
                        className={classes.tableCell}
                        key={`${row[rowKeyName]}_${i}`}>
                        {column}
                      </TableCell>
                    ))}
                  </TableRow>)

                return (
                  <TableRow
                    key={row[rowKeyName]}
                    hover
                    style={!!onRowClick ? { cursor: 'pointer' } : {}}
                    onClick={() => { if (onRowClick) onRowClick(row); }}>
                    {tableContentProps.map((column, i) => (
                      <TableCell
                        className={classes.tableCell}
                        key={`${row[rowKeyName]}_${i}`}>
                        {row[column]}
                      </TableCell>
                    ))}
                  </TableRow>
                );
              })}
            {fillEmptyRows && emptyRows > 0 && (
              <TableRow style={{ height: 49 * emptyRows }}>
                <TableCell colSpan={8} />
              </TableRow>
            )}
          </TableBody>
        </Table>
        <TablePagination
          component="div"
          count={totalCount}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{
            "aria-label": "Previous Page",
          }}
          nextIconButtonProps={{
            "aria-label": "Next Page",
          }}
          labelDisplayedRows={info => `${info.from}-${info.to} de ${info.count}`}
          labelRowsPerPage='Filas por pág'
          onChangePage={this.handleChangePage}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}
        />
      </div>
    );
  }
}

PaginatedTable2.propTypes = {
  classes: propTypes.object.isRequired,
  fillEmptyRows: propTypes.bool,
  onRowClick: propTypes.func,
  rowKeyPropName: propTypes.string,
  headerList: propTypes.array,
  tableContent: propTypes.array,
  tableContentProps: propTypes.array,
  rowsPerPage: propTypes.number,
  page: propTypes.number,
  hideFilter: propTypes.bool,
  disableDeepFilterCheck: propTypes.bool,
  orderable: propTypes.bool,
  compact: propTypes.bool,
  loading: propTypes.bool,
};

export default withStyles(styles)(PaginatedTable2);