import React from "react";
import PropTypes from "prop-types";
import uuid from "uuid";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";

// incident components
import Comment from "./Comment.jsx";
import NewComment from "./NewComment.jsx";

import { commentType as types } from "variables/general";

const styles = theme => ({
  tableRoot: {
    width: "100%",
  },
  chip: {
    margin: theme.spacing.unit,
  },
  comment: {
    display: "flex",
    marginBottom: theme.spacing.unit * 2,
    marginTop: theme.spacing.unit,
  },
  commentAvatar: {
    marginRight: theme.spacing.unit * 2,
  },
  commentContent: { width: "100%" },
  commentInfo: {
    "& > span": {
      marginRight: theme.spacing.unit * 2,
    },
  },
  commentImage: {
    width: "200px",
  },
  commentDescription: {
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
  },
  commentForm: {
    display: "flex",
    flexWrap: "wrap",
  },
  commentTextfield: {
    width: "100%",
  },
  bootstrapRoot: {
    width: "100%",
  },
  margin: {
    width: "100%",
    marginTop: theme.spacing.unit,
  },
  bootstrapInput: {
    borderRadius: 4,
    backgroundColor: theme.palette.common.white,
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: theme.spacing.unit,
    marginLeft: -theme.spacing.unit,
    width: "100%",
    height: "100px !important",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    "&:focus": {
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
  switchFormControl: {
    marginLeft: '10px'
  },
  [theme.breakpoints.down('xs')]: {
    switchFormControl: {
      marginLeft: '0'
    }
  }
});

class IncidentComments extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      comments: [],
      commentToPush: "",
      imageFile: null,
      loading: false,
      error: null,
    };
  }

  handleChangeCommentToPush = event => {
    this.setState({ commentToPush: event.target.value });
  };

  componentDidMount() {
    this.fetchComments();
  }

  fetchComments() {
    this.props.authService
      .authFetch(`/admin/incidents/incident/${this.props.incidentId}/comments`)
      .then(res => {
        this.setState({
          loading: false,
          comments: res.result,
        });
      })
      .catch(error => {
        error.message = "Ocurrió un problema obteniendo los datos";
        this.setState({
          loading: false,
          error,
        });
      });
  }

  postComment = async requestPayload => {
    this.setState({ loading: true });

    this.props.authService
      .authFetch(`/admin/incidents/incident/${this.props.incidentId}/comments`, {
        method: "POST",
        body: JSON.stringify(requestPayload),
      })
      .then(() => {
        this.setState({
          loading: false,
          comments: [],
        });

        this.fetchComments();
      })
      .catch(error => {
        error.message = "Ocurrió un problema enviando el comentario";
        this.setState({
          loading: false,
          error,
        });
      });
  };

  updateComment = (id, requestPayload) => {
    this.setState({ loading: true });

    return this.props.authService
      .authFetch(`/admin/incidents/incident/comments/${id}`, {
        method: "PUT",
        body: JSON.stringify(requestPayload),
      })
      .then(() => {
        this.setState({
          loading: false,
        });

        this.fetchComments();
      })
      .catch(error => {
        error.message = "Ocurrió un problema actualizando el comentario";
        this.setState({
          loading: false,
          error,
        });
      });
  };

  handleAddFile = event => {
    if (event.target.files.length > 0) {
      this.setState({ imageFile: event.target.files[0] });
    }
  };

  handleRemoveFile = () => {
    this.setState({ imageFile: null });
  };

  render() {
    const { classes, commentType, isEnabledComment, authService } = this.props;
    const { comments, loading } = this.state;

    let commentsFiltred = [];

    if (commentType) {
      if (commentType === types.INTERNAL) {
        commentsFiltred = commentType ? comments.filter(comment => comment.type === commentType || comment.type === "SOLUTION_COMPLETED" || comment.type === "RESOLVED") : comments;
      } else {
        commentsFiltred = commentType ? comments.filter(comment => comment.type === commentType) : comments;
      }
    }

    commentsFiltred.sort(function (a, b) {
      if (a.createdAt < b.createdAt) {
        return 1;
      }
      if (a.createdAt > b.createdAt) {
        return -1;
      }
      return 0;
    });

    if (commentType && isEnabledComment) {
      return (
        <div className={classes.tableRoot}>
          <Table>
            <TableBody>
              {commentsFiltred.map(comment => {
                return (
                  <TableRow key={uuid()}>
                    <TableCell>
                      <Comment
                        comment={comment}
                        commentType={commentType}
                        classes={classes}
                        updateComment={this.updateComment}
                        isEditable={this.props.isEditable}
                        authService={authService}
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
          <NewComment loading={loading} classes={classes} commentType={commentType} handleCommentSubmit={this.postComment} incidentId={this.props.incidentId} />
        </div>
      );
    } else {
      return (
        <div className={classes.tableRoot}>
          <Table className={classes.table}>
            <TableBody>
              {commentsFiltred.map(comment => {
                return (
                  <TableRow key={uuid()}>
                    <TableCell>
                      <Comment
                        comment={comment}
                        commentType={commentType}
                        classes={classes}
                        updateComment={this.updateComment}
                        isEditable={this.props.isEditable}
                        authService={authService}
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </div>
      );
    }
  }
}

IncidentComments.propTypes = {
  classes: PropTypes.object.isRequired,
  incidentId: PropTypes.number.isRequired,
  isEnabledComment: PropTypes.bool,
  commentType: PropTypes.oneOf(Object.keys(types)),
  authService: PropTypes.object.isRequired,
  isEditable: PropTypes.bool,
};

export default withStyles(styles)(IncidentComments);
