import React from "react";
import PropTypes from "prop-types";

import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";

import withStyles from "@material-ui/core/styles/withStyles";
import Divider from "@material-ui/core/Divider";
import { GLOBAL_ROLES, EMAIL_REGEX, MODULES_INFO } from "../../variables/general";

import { FormControl, FormControlLabel, TextField, InputLabel, Select, Table, TableBody, TableCell, TableRow, Checkbox, TableHead, Snackbar, IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import Button from "@material-ui/core/Button";

const styles = theme => ({
  button: {
    margin: theme.spacing.unit,
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 400,
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120,
  },
  table: {},
});

const ModuleFormRow = props => {
  // const classes = this.props.classes;

  return (
    <TableRow>
      <TableCell>
        <Checkbox
          checked={props.isEnabled}
          onChange={event => {
            props.checkedListener(event, props.moduleId);
          }}
          disabled={!props.editEnabled}
        />
      </TableCell>
      <TableCell>{props.name}</TableCell>
      <TableCell>
        <FormControl className={styles.formControl}>
          <InputLabel htmlFor="age-native-simple">Rol</InputLabel>
          <Select
            value={props.role}
            onChange={event => {
              props.selectListener(event, props.moduleId);
            }}
            disabled={!props.editEnabled}>
            {props.roles.map(role => {
              return (
                <option key={role.id} value={role.id}>
                  {role.displayName}
                </option>
              );
            })}
          </Select>
        </FormControl>
      </TableCell>
    </TableRow>
  );
};

class EditUserForm extends React.Component {
  constructor(props) {
    super(props);

    let isEdit = false;
    let isGlobalAdminSelected = false;

    let initialForm = {
      firstName: null,
      lastName: null,
      email: null,
      role: GLOBAL_ROLES.REGULAR.id,
      modules: [],
    };

    if (props.form) {
      isEdit = true;
      initialForm = props.form;
      isGlobalAdminSelected = GLOBAL_ROLES.isAdmin(props.form.role);
    }

    this.state = {
      form: initialForm,
      errorForm: {
        firstName: false,
        lastName: false,
        email: false,
      },
      isEditForm: isEdit,
      modulesEditEnabled: GLOBAL_ROLES.isAdmin(this.props.currentProfile.role),
      modulesForm: {},
      isGlobalAdminSelected: isGlobalAdminSelected,
      warningSnack: {
        open: false,
        content: null
      }
    };

    //Initializae empty viewmodel form for modulesForm
    for (const key in MODULES_INFO) {
      const innerName = Object.keys(MODULES_INFO[key].roles)[Object.keys(MODULES_INFO[key].roles).length - 1];
      this.state.modulesForm[key] = {
        isEnabled: false,
        role: innerName,
      };
    }

    const modulesForm = this.state.modulesForm;
    // Incident viewModel loading
    for (let _module of this.state.form.modules) {
      modulesForm[_module.name].isEnabled = true;
      modulesForm[_module.name].role = _module.localRole;
    }
  }

  handleFieldChange = event => {
    const target = event.target;
    const name = target.name;
    const updatedForm = {
      ...this.state.form,
    };
    updatedForm[name] = target.value;

    const updatedErrorForm = {
      ...this.state.errorForm,
    };
    updatedErrorForm[name] = !target.value || target.value.length === 0;

    const updatedModules = {
      ...this.state.modulesForm,
    };

    const newState = {
      form: updatedForm,
      errorForm: updatedErrorForm,
      modulesForm: updatedModules,
    };

    if (name === 'role') {
      newState.isGlobalAdminSelected = GLOBAL_ROLES.isAdmin(target.value);

      // Only WON'T enter if the "previous" or "new" state doesn't involve an admin change
      if (this.state.isGlobalAdminSelected || newState.isGlobalAdminSelected)
        for (const k in MODULES_INFO) {
          const localRoles = Object.keys(MODULES_INFO[k].roles);
          const innerName = localRoles[newState.isGlobalAdminSelected ? 0 : localRoles.length - 1];
          newState.modulesForm[k] = {
            isEnabled: newState.isGlobalAdminSelected,
            role: MODULES_INFO[k].roles[innerName].id
          };
        }
    }

    this.setState(newState);
  };

  handleStatusCheckBoxChange = event => {
    const updatedForm = { ...this.state.form };
    updatedForm.status = event.target.checked ? "ACTIVE" : "INACTIVE";
    this.setState({
      form: updatedForm,
    });
  };

  handleModuleCheckBoxChange = (event, name) => {
    const updatedModules = {
      ...this.state.modulesForm,
    };
    updatedModules[name].isEnabled = event.target.checked;
    this.setState({
      modulesForm: updatedModules,
    });
  };

  handleModuleSelectChange = name => event => {
    const updatedModules = { ...this.state.modulesForm };
    updatedModules[name].role = event.target.value;

    this.setState({
      modulesForm: updatedModules,
    });
  };

  handleSubmit = event => {
    event.preventDefault();
    if (this.isFormValid()) {
      const result = {
        ...this.state.form,
      };
      const newModules = Object.keys(this.state.modulesForm)
        .filter(key => {
          const m = this.state.modulesForm[key];
          return m.isEnabled;
        })
        .map(m => {
          return {
            name: m,
            status: "ACTIVE",
            localRole: this.state.modulesForm[m].role,
          };
        });
      result.modules = newModules;
      const handlerResult = this.props.submitHandler(result);

      if (handlerResult && handlerResult.catch)
        handlerResult.catch(payload => {
          this.setState(payload);
        });
    }
  };

  // validateEmail = email => /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email);

  validateEmail = email => EMAIL_REGEX.test(email);

  isFormValid = () => {
    const form = this.state.form;
    const updatedErrorForm = {
      ...this.state.errorForm,
    };
    updatedErrorForm.firstName = !form.firstName || form.firstName.length === 0;
    updatedErrorForm.lastName = !form.lastName || form.lastName.length === 0;
    updatedErrorForm.email = !this.state.isEditForm && !this.validateEmail(form.email);

    const newState = { errorForm: updatedErrorForm };

    if (!Object.keys(this.state.modulesForm).some(s => this.state.modulesForm[s].isEnabled))
      newState.warningSnack = {
        open: true,
        content: 'Debe seleccionarse al menos un módulo para el usuario'
      };

    this.setState(newState);

    const validForm = !newState.warningSnack && Object.keys(updatedErrorForm).every(e => !updatedErrorForm[e]);

    return validForm;
  };

  render() {
    const { classes } = this.props;
    const emailStyle = this.state.isEditForm ? { display: "none" } : {};
    const submitButtonText = this.state.isEditForm ? "Salvar" : "Crear";
    const formTitle = this.props.formTitle || 'Editar Usuario';

    return (
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="primary">
                <h4 className={classes.cardTitleWhite}>{formTitle}</h4>
              </CardHeader>
              <CardBody>
                <form className={classes.container} noValidate autoComplete="off" onSubmit={this.handleSubmit}>
                  <GridContainer>
                    <GridItem xs={12}>
                      <GridContainer>
                        <GridItem>
                          <GridContainer>
                            <GridItem>
                              <TextField
                                name="firstName"
                                label="Nombre"
                                required
                                error={this.state.errorForm.firstName}
                                className={classes.textField}
                                value={this.state.form.firstName}
                                onChange={this.handleFieldChange}
                                margin="normal"
                              />
                            </GridItem>
                            <GridItem>
                              <TextField
                                name="lastName"
                                label="Apellido"
                                required
                                error={this.state.errorForm.lastName}
                                className={classes.textField}
                                value={this.state.form.lastName}
                                onChange={this.handleFieldChange}
                                margin="normal"
                              />
                            </GridItem>
                            <GridItem style={emailStyle}>
                              <TextField
                                name="email"
                                label="Email"
                                required
                                type="email"
                                error={this.state.errorForm.email}
                                className={classes.textField}
                                value={this.state.form.email}
                                onChange={this.handleFieldChange}
                                margin="normal"
                              />
                            </GridItem>
                            <GridItem>
                              <FormControl className={classes.formControl}>
                                <InputLabel htmlFor="age-native-simple">Rol General</InputLabel>
                                <Select disabled={!GLOBAL_ROLES.isAdmin(this.props.currentProfile.role)} name="role" value={this.state.form.role} onChange={this.handleFieldChange}>
                                  <option value="REGULAR">Regular</option>
                                  <option value="ADMIN">Administrador General</option>
                                  {GLOBAL_ROLES.isSuperAdmin(this.props.currentProfile.role) && (
                                    <option value="SUPERADMIN">Super Admin</option>
                                  )}
                                </Select>
                              </FormControl>
                            </GridItem>

                            {GLOBAL_ROLES.isSuperAdmin(this.props.currentProfile.role) && (
                              <div>
                                <br />
                                <GridItem>
                                  <FormControlLabel
                                    className={classes.textField}
                                    control={<Checkbox checked={this.state.form.status === "ACTIVE" ? true : false} onChange={this.handleStatusCheckBoxChange} value={this.state.form.status} />}
                                    label="Usuario activo?"
                                  />
                                </GridItem>
                              </div>
                            )}
                          </GridContainer>
                        </GridItem>
                        <GridItem xs={12} style={{ marginTop: "50px" }}>
                          <Divider />
                        </GridItem>
                        <GridItem style={{ marginTop: "50px" }}>
                          <GridContainer>
                            <GridItem>
                              <Table className={classes.table}>
                                <TableHead>
                                  <TableRow>
                                    <TableCell>Habilitar</TableCell>
                                    <TableCell>Módulo</TableCell>
                                    <TableCell>Rol</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {Object.keys(MODULES_INFO).map(key => {
                                    const info = MODULES_INFO[key];
                                    return (
                                      <ModuleFormRow
                                        key={key}
                                        classes={this.props.classes}
                                        moduleId={key}
                                        name={info.displayName}
                                        roles={Object.keys(info.roles).map(roleKey => {
                                          return {
                                            id: roleKey,
                                            displayName: info.roles[roleKey].displayName,
                                          };
                                        })}
                                        editEnabled={this.state.modulesEditEnabled && !this.state.isGlobalAdminSelected}
                                        isEnabled={this.state.modulesForm[key].isEnabled}
                                        role={this.state.modulesForm[key].role}
                                        checkedListener={this.handleModuleCheckBoxChange}
                                        selectListener={this.handleModuleSelectChange(info.id)}
                                      />
                                    );
                                  })}
                                </TableBody>
                              </Table>
                            </GridItem>
                          </GridContainer>
                        </GridItem>
                      </GridContainer>
                    </GridItem>
                  </GridContainer>
                  <Button color="primary" className={classes.button} style={{ marginTop: "30px" }} type="submit">
                    {submitButtonText}
                  </Button>
                  <Button color="secondary" className={classes.button} onClick={this.props.cancelHandler} style={{ marginTop: "30px" }}>
                    Cancelar
                  </Button>
                </form>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>

        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          open={this.state.warningSnack.open}
          message={this.state.warningSnack.content}
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              className={classes.close}
              onClick={() => {
                this.setState({
                  warningSnack: {
                    open: false,
                    content: null
                  }
                });
              }}>
              <CloseIcon />
            </IconButton>,
          ]}
        />
      </div>
    );
  }
}

EditUserForm.propTypes = {
  history: PropTypes.object,
  form: PropTypes.object,
  cancelHandler: PropTypes.func.isRequired,
  submitHandler: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};

ModuleFormRow.propTypes = {
  classes: PropTypes.object.isRequired,
  moduleId: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  roles: PropTypes.array.isRequired,
  isEnabled: PropTypes.bool.isRequired,
  role: PropTypes.string.isRequired,
  editEnabled: PropTypes.bool.isRequired,
  checkedListener: PropTypes.func.isRequired,
  selectListener: PropTypes.func.isRequired,
};

export default withStyles(styles)(EditUserForm);
