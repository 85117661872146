import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";

// @material-ui/core components
import Grid from "@material-ui/core/Grid";

import { Table, TableHead, TableCell, TableRow, TableBody, Checkbox, TextField, FormControlLabel } from "@material-ui/core";

const styles = theme => ({
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 400,
  },
  timeInput: {
    width: '100%',
  },
  timeInputContainer: {
    padding: '0 5px',
  },
});


class CustomScheduleSelector extends React.Component {
  constructor(props) {
    super(props);

    this.defaultTime = props.defaultTime || '07:00';

    this.days = Array.isArray(props.days) ? [...props.days] : [
      'Lunes',
      'Martes',
      'Miércoles',
      'Jueves',
      'Viernes',
      'Sábado',
      'Domingo',
    ];

    this.daysLabelMapper = props.daysLabelMapper || this.days.reduce((o, e) => {
      o[e] = e;
      return o;
    }, {});

    const selectedDays = {};
    const schedules = this.days
      .map(() => ({
        from: this.defaultTime,
        to: this.defaultTime,
      }));

    if (Array.isArray(props.initialValue))
      this.days.forEach((day, inx) => {
        const item = props.initialValue.find(e => e && e.day === day);

        if (item) {
          selectedDays[day] = true;

          if (typeof item.from === 'string')
            schedules[inx].from = item.from;

          if (typeof item.to === 'string')
            schedules[inx].to = item.to;
        }
      });

    const selectedCount = Object.keys(selectedDays).length;

    this.state = {
      globalSelector: selectedCount === this.days.length ? true : selectedCount > 0 ? null : false,
      selectedDays,
      schedules,
    };
  }

  returnValue = () => {
    setTimeout(() => {
      const { schedules, selectedDays } = this.state;
      const { onChange } = this.props;

      if (onChange) {
        const value = this.days
          .map((m, i) => ({
            day: m,
            ...schedules[i],
          }));

        const filteredValue = value.filter(f => selectedDays[f.day]);
        const valid = filteredValue.every(s => s.from && s.to);

        onChange(valid ? filteredValue : null, { value, valid });
      }
    }, 0);
  };

  onDaySelected = day => event => {
    const { selectedDays: selectedDaysCopy } = { ...this.state };

    const newState = {
      selectedDays: {
        ...selectedDaysCopy,
        [day]: event.target.checked,
      }
    };

    if (this.days.every(e => newState.selectedDays[e]))
      newState.globalSelector = true;
    else if (this.days.every(e => !newState.selectedDays[e]))
      newState.globalSelector = false;
    else
      newState.globalSelector = null;

    this.setState(newState);
    this.returnValue();
  };

  onSelectAllSelected = event => {
    const newState = {
      globalSelector: event.target.checked,
    };

    if (newState.globalSelector)
      newState.selectedDays = this.days
        .reduce((o, e) => {
          o[e] = true;
          return o;
        }, {});
    else
      newState.selectedDays = {};

    this.setState(newState);
    this.returnValue();
  };

  onTimeChanged = (type, inx) => event => {
    const schedules = [...this.state.schedules];

    schedules[inx][type] = event.target.value;

    this.setState({
      schedules,
    });

    this.returnValue();
  };

  render() {
    const { globalSelector, selectedDays, schedules } = this.state;
    const { classes } = this.props;


    return (
      <Grid container alignItems="center" style={{ width: '100%' }}>
        <Grid item xs={12} sm={12} md={12}>
          <div
            style={{
              position: "relative",
              width: "100%",
              margin: "auto"
            }}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <FormControlLabel
                      control={
                        <Checkbox
                          indeterminate={globalSelector === null}
                          checked={!!globalSelector}
                          onChange={this.onSelectAllSelected}
                          color="primary"
                          inputProps={{
                            'aria-label': 'Día',
                          }}
                        />
                      }
                      label={'Día'}
                    />
                  </TableCell>

                  <TableCell>Horario</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {this.days.map((day, inx) => {
                  return (
                    <TableRow key={inx}>
                      <TableCell>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={!!selectedDays[day]}
                              onChange={this.onDaySelected(day)}
                              color="primary"
                              inputProps={{
                                'aria-label': this.daysLabelMapper[day] || 'Unknown',
                              }}
                            />
                          }
                          label={this.daysLabelMapper[day] || 'Unknown'}
                        />
                      </TableCell>

                      <TableCell>
                        <Grid container>
                          <Grid item xs={12} sm={12} md={6} className={classes.timeInputContainer}>
                            <TextField
                              label="Desde"
                              type="time"
                              disabled={!selectedDays[day]}
                              defaultValue={schedules[inx].from || this.defaultTime}
                              error={selectedDays[day] && !schedules[inx].from}
                              className={classes.timeInput}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              inputProps={{
                                step: 300,
                              }}
                              onChange={this.onTimeChanged('from', inx)}
                            />
                          </Grid>

                          <Grid item xs={12} sm={12} md={6} className={classes.timeInputContainer}>
                            <TextField
                              label="Hasta"
                              type="time"
                              disabled={!selectedDays[day]}
                              defaultValue={schedules[inx].to || this.defaultTime}
                              error={selectedDays[day] && !schedules[inx].to}
                              className={classes.timeInput}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              inputProps={{
                                step: 300,
                              }}
                              onChange={this.onTimeChanged('to', inx)}
                            />
                          </Grid>
                        </Grid>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </div>
        </Grid>
      </Grid>
    );
  }
}

CustomScheduleSelector.propTypes = {
  classes: PropTypes.object.isRequired,
  onChange: PropTypes.func,
  initialValue: PropTypes.array,
  days: PropTypes.array,
  daysLabelMapper: PropTypes.object,
  defaultTime: PropTypes.string,
};

export default withStyles(styles)(CustomScheduleSelector);