// @material-ui/icons
import Dashboard from "@material-ui/icons/Dashboard";
import Refresh from "@material-ui/icons/Refresh";
import Person from "@material-ui/icons/Person";
import Notifications from "@material-ui/icons/Notifications";

// core components/views
import NewsContainer from "views/NewsDashboard/NewsContainer";
import Users from "views/NewsDashboard/Users";

const garbagePickups = [
  {
    path: "/news/",
    sidebarName: "Noticias",
    navbarName: "Noticias",
    icon: Dashboard,
    component: NewsContainer,
  },
  {
    path: "#refresh-news",
    sidebarName: "Refrescar noticias",
    navbarName: "Refrescar noticias",
    icon: Refresh,
  },
  {
    path: "/news/users",
    sidebarName: "Usuarios",
    navbarName: "Usuarios",
    icon: Person,
    component: Users,
    adminRestricted: true,
  },
  {
    path: "#notifications",
    sidebarName: "Notificaciones",
    navbarName: "Notificaciones",
    icon: Notifications,
    adminRestricted: false,
  },
  
];
export default garbagePickups;
