// @material-ui/icons
import Dashboard from "@material-ui/icons/Dashboard";
import Notifications from "@material-ui/icons/Notifications";


// core components/views
import PaymentsContainer from "views/PaymentDashboard/PaymentContainer";

const paymentsRoutes = [
  {
    path: "/payments",
    sidebarName: "Pagos",
    navbarName: "Pagos",
    icon: Dashboard,
    component: PaymentsContainer,
    adminRestricted: true,
  },
  {
    path: "#notifications",
    sidebarName: "Notificaciones",
    navbarName: "Notificaciones",
    icon: Notifications,
    adminRestricted: false,
  },
];

export default paymentsRoutes;
