import React from "react";
import { Route } from "react-router-dom";
import News from "./News";
import ManagePost from "./ManagePost";
import Users from "./Users";

const NewsContainer = () => {
  return (
    <div>
      <Route path="/news/" exact component={News} />
      <Route path="/news/create" exact component={ManagePost} />
      <Route path="/news/edit/:id([0-9]+)" exact component={ManagePost} />
      <Route path="/news/users" exact component={Users} />
    </div>
  );
};

export default NewsContainer;
