import React from "react";
import classNames from "classnames";
import { NavLink } from "react-router-dom";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Icon from "@material-ui/core/Icon";
import PowerSettings from "@material-ui/icons/PowerSettingsNew";

import AuthService from "components/Services/AuthService";
import NotificationDrawer from "components/CustomNotifications/NotificationDrawer";

import sidebarStyle from "assets/jss/material-dashboard-react/components/sidebarStyle.jsx";
import { Badge } from "@material-ui/core";

const Auth = new AuthService();

const customStyles = theme => ({
  ...sidebarStyle(theme),
  sidebarBadgeDot: {
    right: 10,
    top: 10,
    width: 8,
    height: 8,
    borderRadius: '50%',
  }
});

class Sidebar extends React.Component {
  constructor(props) {
    super(props);

    this.hashClickEvents = {};

    Object.assign(this.hashClickEvents, props.hashClickEvents || {});

    this.state = {
      openNotifications: false,
      notifications: [],
      notificationIDs: [],
      lastNotificationSeen: null,
      newestTabOpen: false,
    };

    this.hashClickEvents['#notifications'] = () => {
      this.setState({
        openNotifications: !this.state.openNotifications,
      });
    };
  }

  getNotifications = (firstCall = false) => {
    return Auth.authFetch('/admin/notification/')
      .then(resp => {
        const { notificationIDs, newestTabOpen } = this.state;

        const filteredList = resp.result
          .reduce((o, e) => {
            if (!o.some(s => s.desc === e.desc && s.opened === e.opened))
              o.push(e);

            return o;
          }, []);

        const isSameURL = url => this.props.location.pathname === url;
        // Simulate "opened" effect on client-side if necessary
        const verifyPseudoOpened = _elem => firstCall && isSameURL(_elem.url) ? true :
          // Simulate "unopened" state if necessary
          !firstCall && isSameURL(_elem.url) && !notificationIDs.includes(_elem.id) ? false : _elem.opened;

        const finalFilter = filteredList
          .reduce((collection, elem, _inx, obj) => {
            const filterValidator = v => (!elem.url && elem.id === v.id)
              || (!!elem.url && v.url === elem.url && verifyPseudoOpened(v) === verifyPseudoOpened(elem));

            if (collection.some(filterValidator))
              return collection;

            const coincidences = obj.filter(filterValidator);
            const pseudoOpened = verifyPseudoOpened(coincidences[0]);

            collection.push({
              ...coincidences[0],
              desc: coincidences.length > 1 ?
                `${coincidences[0].desc}... +${coincidences.length - 1} notificaci${coincidences.length === 2 ? 'ón' : 'ones'}` :
                coincidences[0].desc,
              opened: pseudoOpened,
            });

            return collection;
          }, []);

        const newState = {
          notifications: finalFilter,
          notificationIDs: resp.result.map(m => m.id),
        };

        const mostRecent = finalFilter.find(s => !s.opened);

        if (newestTabOpen && mostRecent)
          newState.lastNotificationSeen = new Date(mostRecent.date).getTime();

        this.setState(newState);

        if (!newestTabOpen && this.props.hideNotificationBadge && finalFilter.some(s => !s.opened))
          this.props.hideNotificationBadge(false);
      })
      .catch(reason => {
        console.log('Failed to load notifications:', reason);
      });
  };

  componentDidMount() {
    this.getNotifications(true)
      .then(() => {
        if (document.__notificationInterval !== undefined)
          clearInterval(document.__notificationInterval);

        document.__notificationInterval = setInterval(() => {
          if (!Auth.loggedIn() && document.__notificationInterval !== undefined)
            return clearInterval(document.__notificationInterval);

          Auth.authFetch('/admin/notification/minimal/')
            .then(resp => {
              const { notificationIDs } = this.state;
              const response = resp.result;
              let loadAgain = false;

              if (response.length === notificationIDs.length)
                loadAgain = !notificationIDs.every(e => response.includes(e));
              else
                loadAgain = true;

              if (loadAgain)
                this.getNotifications();
            })
            .catch(reason => {
              console.log('Failed to check new notifications:', reason);
            });
        }, 30000);
      });
  }

  render() {
    const { classes, color, logo, routes, open, handleDrawerToggle, location, hideNotificationBadge } = this.props;
    const { openNotifications, notifications, lastNotificationSeen, newestTabOpen } = this.state;

    const safelyHideNotificationBadge = value => {
      if (hideNotificationBadge)
        hideNotificationBadge(value);
      else
        console.warn('No master notification handler found');
    };

    const lastUnopened = notifications.find(f => !f.opened);
    const hideBadge = !!newestTabOpen
      || !lastUnopened
      || (!!lastNotificationSeen && (new Date(lastUnopened.date)).getTime() <= lastNotificationSeen);

    const activeRoute = routeName => location.pathname.indexOf(routeName) > -1;

    const links = (
      <List className={classes.list}>
        {routes.map((prop, key) => {
          if (prop.redirect)
            return null;

          const listItemClasses = classNames({
            [" " + classes[color]]: activeRoute(prop.path),
          });
          const whiteFontClasses = classNames({
            [" " + classes.whiteFont]: activeRoute(prop.path),
          });

          const itemText = (<ListItemText primary={prop.sidebarName} className={classes.itemText + whiteFontClasses} disableTypography={true} />);

          const sidebarItem = (
            <ListItem
              button
              className={classes.itemLink + listItemClasses}
              key={`list_${key}`}
              onClick={!prop.path.startsWith('#') ? undefined : this.hashClickEvents[prop.path]}>
              <ListItemIcon className={classes.itemIcon + whiteFontClasses}>{typeof prop.icon === "string" ? <Icon>{prop.icon}</Icon> : <prop.icon />}</ListItemIcon>
              {prop.path === '#notifications' ? (
                <Badge variant='dot' color='secondary' invisible={hideBadge} classes={{ dot: classes.sidebarBadgeDot }}>
                  {itemText}
                </Badge>
              ) : itemText}
            </ListItem>);

          if (prop.path.startsWith('#'))
            return sidebarItem;

          return (
            <NavLink to={prop.path} className={classes.item} activeClassName="active" key={key}>
              {sidebarItem}
            </NavLink>
          );
        })}
      </List>
    );

    const brand = (
      <div className={classes.logo}>
        <a href="/" className={classes.logoLink}>
          <div className={classes.logoImage}>
            <img src={logo} alt="logo" className={classes.img} />
          </div>
          Menu inicial
        </a>
      </div>
    );

    const logout = (
      <List className={classes.list}>
        <ListItem
          button
          className={classes.itemLink}
          onClick={async () => {
            await Auth.logout();
            this.props.history.push("/login");
          }}>
          <ListItemIcon className={classes.itemIcon}>
            <PowerSettings />
          </ListItemIcon>
          <ListItemText primary={"Cerrar Session"} className={classes.itemText} disableTypography={true} />
        </ListItem>
      </List>
    );

    return (
      <div>
        <Hidden mdUp implementation="css">
          <Drawer
            variant="temporary"
            anchor="right"
            open={open && !openNotifications}
            classes={{
              paper: classes.drawerPaper,
            }}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}>
            {brand}
            <div className={classes.sidebarWrapper}>{links}</div>
            <div
              className={classes.sidebarWrapper}
              style={{
                position: "fixed",
                bottom: "16px",
              }}>
              {logout}
            </div>
            <div className={classes.background} style={{ background: 'linear-gradient(28.96deg, #23BAED 0%, #194E9A 100%)' }} />
          </Drawer>
        </Hidden>
        <Hidden smDown implementation="css">
          <Drawer
            anchor="left"
            variant="permanent"
            open
            classes={{
              paper: classes.drawerPaper,
            }}>
            {brand}
            <div className={classes.sidebarWrapper}>{links}</div>
            <div
              className={classes.sidebarWrapper}
              style={{
                position: "fixed",
                bottom: "10px",
              }}>
              {logout}
            </div>
            <div className={classes.background} style={{ background: 'linear-gradient(28.96deg, #23BAED 0%, #194E9A 100%)' }} />
          </Drawer>
        </Hidden>

        <NotificationDrawer
          open={openNotifications}
          notifications={notifications}
          onCollapseClick={() => {
            this.setState({ openNotifications: false })
          }}
          onTabChange={(_event, newTab) => {
            const { newestTabOpen } = this.state;
            const newState = {};

            if (newTab === 0) {
              const firstOne = notifications.find(f => !f.opened);

              if (firstOne)
                newState.lastNotificationSeen = new Date(firstOne.date).getTime();

              if (!newestTabOpen)
                newState.newestTabOpen = true;
            }
            else if (newestTabOpen)
              newState.newestTabOpen = false;

            if (Object.keys(newState).length) {
              this.setState(newState);

              safelyHideNotificationBadge(hideBadge
                || !lastUnopened
                || newState.newestTabOpen
                || (newState.lastNotificationSeen && new Date(lastUnopened.date).getTime()) <= newState.lastNotificationSeen);
            }

          }}
          hideBadge={hideBadge}
          onDrawerOpen={childState => {
            if (childState.tab === 0 && !newestTabOpen) {
              const newState = { newestTabOpen: true };

              if (lastUnopened)
                newState.lastNotificationSeen = new Date(lastUnopened.date).getTime();

              this.setState(newState);
              safelyHideNotificationBadge(true);
            }
          }}
          onNotificationClick={entry => {
            const newState = {};
            const { notifications } = { ...this.state };

            notifications
              .filter(f => f.url === entry.url)
              .forEach(f => {
                f.opened = true;
              });

            newState.notifications = notifications;

            if (entry.url) {
              newState.openNotifications = false;
              newState.open = false;
            }

            if (Object.keys(newState).length)
              this.setState(newState);

            if (entry.url === this.props.location.pathname)
              window.location.reload();
          }}
        />
      </div>
    );
  }
}

export default withStyles(customStyles)(Sidebar);
