import React from "react";
import PropTypes from "prop-types";

// @material-ui/core components
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

// core components
import Button from "components/CustomButtons/Button.jsx";
import Select from "components/Select/Select.jsx";
import { sessionStorageKeys } from "variables/general";

class IncidentChangeCategoryDialog extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      categories: [],
      categorySelected: this.props.selectedValue,
      loading: false,
      error: null,
      token: sessionStorage.getItem(sessionStorageKeys.token)
    };
  }

  handleClose = () => {
    this.props.onClose(this.props.selectedValue);
  };

  onAccept = () => {
    this.props.onAccept(this.state.categorySelected);
  };

  handleChange = value => {
    this.setState({
      categorySelected: this.state.categories.filter(
        category => category.id === value.value
      )[0]
    });
  };

  componentDidMount() {
    this.fetchCategories();
  }

  fetchCategories() {
    this.props.authService
      .authFetch("/admin/categories/regular")
      .then(res => {
        this.setState({
          isLoaded: true,
          categories: res.result
        });
      })
      .catch(error => {
        error.message = "Ups!!! Ocurrió un problema obteniendo los datos.";
        this.setState({
          isLoaded: true,
          error
        });
      });
  }

  render() {
    const { selectedValue, onAccept, authService, ...other } = this.props;
    const { categories } = this.state;

    const suggestions = categories.map(category => ({
      value: category.id,
      label: category.name
    }));

    const defaultSuggestion = suggestions.filter(
      suggestion => suggestion.value === selectedValue.id
    )[0];

    return (
      <Dialog
        onClose={this.handleClose}
        aria-labelledby="simple-dialog-title"
        {...other}
      >
        <DialogTitle id="simple-dialog-title">Cambiar Categoría</DialogTitle>
        <DialogContent style={{ height: "300px", width: "500px" }}>
          <Select
            options={suggestions}
            defaultValue={defaultSuggestion}
            onChange={this.handleChange}
            placeholder="escribe el nombre de la categoría"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleClose} color="danger" simple={true}>
            Cancelar
          </Button>
          <Button onClick={this.onAccept} color="primary" simple={true}>
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

IncidentChangeCategoryDialog.propTypes = {
  selectedValue: PropTypes.object,
  authService: PropTypes.object.isRequired
};

export default IncidentChangeCategoryDialog;
