import decode from "jwt-decode";
import { sessionStorageKeys } from "../../variables/general";

export default class AuthService {
  constructor() {
    this.domain = process.env.REACT_APP_API_BASE;
    this.fetch = this.fetch.bind(this);
    this.login = this.login.bind(this);
    this.getProfile = this.getProfile.bind(this);
  }

  login(email, password) {
    return this.fetch("/account/login", {
      method: "POST",
      body: JSON.stringify({
        email,
        password,
      }),
    }).then(res => {
      if (res.result.jwt) {
        const jwt = res.result.jwt;

        this.setToken(jwt.token);
        this.setRefreshToken(jwt.refreshToken);
        this.setUser(jwt.user);
      }

      return Promise.resolve(res);
    });
  }

  loggedIn() {
    const token = this.getToken();
    return !!token && !this.isTokenExpired(token);
  }

  isTokenExpired(token) {
    try {
      const decoded = decode(token);
      if (decoded.exp < Date.now() / 1000) {
        return true;
      } else return false;
    } catch (err) {
      return false;
    }
  }

  setToken(idToken) {
    sessionStorage.setItem(sessionStorageKeys.id_token, idToken);
  }

  getToken() {
    return sessionStorage.getItem(sessionStorageKeys.id_token);
  }

  setRefreshToken(refreshToken) {
    sessionStorage.setItem(sessionStorageKeys.refresh_token, refreshToken);
  }

  getRefreshToken() {
    return sessionStorage.getItem(sessionStorageKeys.refresh_token);
  }

  setUser(userObj) {
    sessionStorage.setItem(sessionStorageKeys.user_object, JSON.stringify({ user: userObj }));
  }

  getUser() {
    return JSON.parse(sessionStorage.getItem(sessionStorageKeys.user_object));
  }

  logout() {
    sessionStorage.clear();
  }

  getProfile() {
    return this.getUser();
  }

  fetch(url, options) {
    return this._internalFetch(url, false, options);
  }

  authFetch(url, options) {
    return this._internalFetch(url, true, options);
  }

  _internalFetch(url, isPrivate, options) {
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json; charset=utf-8",
      'X-Client-Path': window.location.pathname,
      ...((options || {}).headers || {})
    };

    if (options && options.headers)
      delete options.headers;

    if (headers['Content-Type'] === null)
      delete headers['Content-Type'];

    if (isPrivate) {
      if (this.loggedIn())
        headers["Authorization"] = this.getToken();
      else
        fetch(`${this.domain}/account/refreshToken`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json; charset=utf-8",
          },
          body: JSON.stringify({ token: this.getRefreshToken() }),
        }).then(res => {
          if (res.status >= 200 && res.status < 300) {
            if (res.result.jwt) {
              const jwt = res.result.jwt;

              this.setToken(jwt.token);
              this.setRefreshToken(jwt.refreshToken);
              this.setUser(jwt.user);
            }

            return this._internalFetch(url, isPrivate, options);
          } else {
            var error = new Error(res.statusText);
            error.response = res;
            throw error;
          }
        });
    }

    return fetch(`${this.domain}${url}`, {
      headers,
      ...options,
    })
      .then(this._checkStatus)
      .then(response => response.json());
  }

  _checkStatus(response) {
    if (response.status >= 200 && response.status < 300) {
      return response;
    } else {
      var error = new Error(response.statusText);
      error.response = response;
      throw error;
    }
  }
}
