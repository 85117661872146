import React from "react";
import PropTypes from "prop-types";

import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import withStyles from "@material-ui/core/styles/withStyles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";

import CircularProgress from "@material-ui/core/CircularProgress";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { Redirect } from "react-router-dom";

import AuthService from "components/Services/AuthService";

const styles = theme => ({
  progress: {
    margin: theme.spacing.unit * 2,
  },
});

class IncidentPriorities extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      navigateToCreate: false,
      navigateToEdit: null,
      priorities: [],
      error: null,
    };
    this.AuthService = new AuthService();
  }

  componentDidMount() {
    this.setState({ loading: true });
    this.AuthService.authFetch("/admin/priorities?showDeleted=true")
      .then(response => {
        this.setState({ loading: false });
        this.setState({
          priorities: response.result,
        });
      })
      .catch(error => this.setState({ loading: false, error: error }));
  }

  render() {
    const { classes } = this.props;
    if (this.state.navigateToCreate === true) {
      return <Redirect push to="/incidents/priorities/create" />;
    }
    if (this.state.navigateToEdit !== null) {
      return (
        <Redirect
          push
          to={{
            pathname: "/incidents/priorities/edit",
            state: { priority: this.state.navigateToEdit },
          }}
        />
      );
    }
    if (this.state.loading === true) {
      return (
        <Paper>
          <Grid container spacing={8} justify="center">
            <Grid item xs={3} sm={3} md={3}>
              <CircularProgress className={classes.progress} size={50} />
            </Grid>
          </Grid>
        </Paper>
      );
    }

    return (
      <Paper>
        <Grid container justify="center">
          <Grid item xs={12} sm={12} md={12}>
            <Button
              variant="outlined"
              style={{ margin: "10px" }}
              onClick={() => {
                this.setState({ navigateToCreate: true });
              }}>
              Agregar nueva
            </Button>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Nombre</TableCell>
                  <TableCell>Descripción</TableCell>
                  <TableCell>Categorías</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.priorities.map(priority => {
                  return (
                    <TableRow
                      key={priority.id}
                      hover
                      onClick={() => {
                        this.setState({ navigateToEdit: priority });
                      }}>
                      <TableCell>{priority.name}</TableCell>
                      <TableCell>{priority.description}</TableCell>
                      <TableCell>
                        {priority.categories
                          .map(cat => {
                            return cat.name;
                          })
                          .join(", ")}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Grid>
        </Grid>
      </Paper>
    );
  }
}
IncidentPriorities.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(IncidentPriorities);
