import React from "react";
import PropTypes from "prop-types";

import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import withStyles from "@material-ui/core/styles/withStyles";
import Grid from "@material-ui/core/Grid";

import CircularProgress from "@material-ui/core/CircularProgress";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { Redirect } from "react-router-dom";

import AuthService from "components/Services/AuthService";

const styles = theme => ({
  progress: {
    margin: theme.spacing.unit * 2
  }
});

class IncidentCategories extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      navigateToCreate: false,
      navigateToEdit: null,
      categories: [],
      error: null
    };
    this.AuthService = new AuthService();
  }

  componentDidMount() {
    this.setState({ loading: true });
    this.AuthService.authFetch("/admin/categories")
      .then(response => {
        this.setState({ loading: false });
        this.setState({
          categories: response.result.filter(
            c => c.type === IncidentCategoriesTypes.REGULAR.id
          )
        });
      })
      .catch(error => this.setState({ loading: false, error: error }));
  }

  render() {
    const { classes } = this.props;
    if (this.state.navigateToCreate === true) {
      return <Redirect push to="/incidents/categories/incident/create" />;
    }
    if (this.state.navigateToEdit !== null) {
      return (
        <Redirect
          push
          to={{
            pathname: "/incidents/categories/incident/edit",
            state: { category: this.state.navigateToEdit }
          }}
        />
      );
    }
    if (this.state.loading === true) {
      return (
        <Grid container spacing={8} justify="center">
          <Grid item xs={3} sm={3} md={3}>
            <CircularProgress className={classes.progress} size={50} />
          </Grid>
        </Grid>
      );
    }

    return (
      <div>
        <Grid container justify="center">
          <Grid item xs={12} sm={12} md={12}>
            <Button
              variant="outlined"
              style={{ margin: "10px" }}
              onClick={() => {
                this.setState({ navigateToCreate: true });
              }}
            >
              Agregar nueva
            </Button>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Nombre</TableCell>
                  <TableCell>Dirección Requerida</TableCell>
                  <TableCell>Categoría oculta</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.categories.map(category => {
                  return (
                    <TableRow
                      key={category.id}
                      hover
                      onClick={() => {
                        this.setState({ navigateToEdit: category });
                      }}>
                      <TableCell>{category.name}</TableCell>

                      <TableCell>
                        {category.addressRequired ? "Si" : "No"}
                      </TableCell>

                      <TableCell>
                        {category.hidden ? "Si" : "No"}
                      </TableCell>

                      <TableCell />
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Grid>
        </Grid>
      </div>
    );
  }
}
IncidentCategories.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(IncidentCategories);

export const IncidentCategoriesTypes = {
  REGULAR: {
    id: "REGULAR"
  },
  CLOSED: {
    id: "CLOSED"
  }
};
