import React from "react";

// @material-ui/core components
import Avatar from "@material-ui/core/Avatar";
import TextField from "@material-ui/core/TextField";
import GridListTile from "@material-ui/core/GridListTile";
import GridList from "@material-ui/core/GridList";
import Chip from "@material-ui/core/Chip";

// core components
import Button from "components/CustomButtons/Button.jsx";
import ImageDialog from "components/ImageDialog/ImageDialog.jsx";
//colors
import blue from "@material-ui/core/colors/blue";
import green from "@material-ui/core/colors/green";

import { dateTimeFormat } from "utils/DateUtil.jsx";
import { storage } from "utils/Firebase";
import { FormControlLabel, Switch } from "@material-ui/core";

import { GLOBAL_ROLES } from "variables/general";

class IncidentComment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      comment: props.comment,
      dialogOpen: false,
      currentImage: null,
      imageUrls: [],
      disableSwitch: false
    };

    this.user = props.authService.getProfile().user;
  }

  componentDidMount() {
    this.getImage();
  }

  handleChange = event => {
    var comment = { ...this.state.comment };
    comment.description = event.target.value;
    this.setState({ comment });
  };

  handleBlur = event => {
    const description = event.target.value;

    if (description.length <= 0) {
      alert("Comentarios sin contenido no son permitidos.");
      const comment = { ...this.state.comment };
      comment.description = this.state.description;
      this.setState({ comment });
      return;
    }

    const confirmationLabel = "¿Esta seguro que desea actualizar la descripción de este comentario? Esta acción es irreversible.";
    const userConfirmation = window.confirm(confirmationLabel);

    if (!userConfirmation) {
      const comment = { ...this.state.comment };
      comment.description = this.state.description;
      this.setState({ comment });
      return;
    }

    const requestPayload = {
      description,
    };

    this.props.updateComment(this.state.comment.id, requestPayload);
  };

  avatarLetters(userName) {
    if (userName === "") {
      return "";
    }

    const splittedName = userName.split(" ");
    let initials;
    if (splittedName.length > 1) {
      initials = `${splittedName[0][0]}${splittedName[1][0]}`;
    } else {
      initials = splittedName[0][0];
    }

    return initials.toUpperCase();
  }

  editTextField = (comment, classes) => {
    let textField = (
      <div className={classes.commentDescription}>
        <span>{comment.description}</span>
      </div>
    );

    if (this.props.isEditable) {
      textField = (
        <TextField
          id="incident-comment"
          label="Descripcion del comentario"
          multiline
          rowsMax="4"
          className={classes.commentTextfield}
          value={comment.description}
          onChange={this.handleChange}
          onBlur={this.handleBlur}
          margin="normal"
          variant="outlined"
        />
      );
    }

    return textField;
  };

  visibilityButton = (comment, classes) => {
    let toggleVisibilityBtn = null;

    if (this.props.isEditable) {
      const label = comment.isHidden ? "Mostrar comentario" : "Ocultar comentario";
      toggleVisibilityBtn = (
        <div className={classes.commentDescription}>
          <Button color="danger" onClick={() => this.toggleVisibility(comment)}>
            {label}
          </Button>
        </div>
      );
    }

    return toggleVisibilityBtn;
  };

  handleClickOpen = image => () => {
    this.setState({ dialogOpen: true, currentImage: image });
  };

  toggleVisibility = comment => {
    const confirmationLabel = comment.isHidden ? "¿Esta seguro que desea mostrar este comentario?" : "¿Esta seguro que desea ocultar este comentario?";
    const userConfirmation = window.confirm(confirmationLabel);

    if (!userConfirmation) {
      return;
    }

    const requestPayload = {
      isHidden: !comment.isHidden,
    };
    this.props.updateComment(comment.id, requestPayload);
  };

  getImage() {
    const { comment } = this.props;

    if (comment.pictureUrl.length > 0) {
      const urlPromises = comment.pictureUrl.map(image => {
        return storage
          .refFromURL(image)
          .getDownloadURL()
          .then(url => {
            return url;
          });
      });

      Promise.all(urlPromises).then(imageUrls => {
        this.setState({
          imageUrls,
        });
      });
    }
  }

  renderCommentChips() {
    return (
      <div>
        {this.props.comment.isHidden && <Chip label="Oculto" className={this.props.classes.chip} color="primary" />}
        {this.props.comment.isAnonymous && <Chip label="Anónimo" className={this.props.classes.chip} color="primary" />}
      </div>
    );
  }

  render() {
    const { comment, disableSwitch } = this.state;
    const { classes } = this.props;

    return (
      <div className={classes.comment}>
        <div className={classes.commentAvatar}>
          {!this.props.commentType && comment.type === "LOG" ? (
            <Avatar
              ficlassName={classes.avatar}
              style={{
                backgroundColor: green[500],
              }}>
              E
            </Avatar>
          ) : (
              <Avatar
                className={classes.avatar}
                style={{
                  backgroundColor: blue[500],
                }}>
                {this.avatarLetters(comment.user)}
              </Avatar>
            )}
        </div>
        <div className={classes.commentContent}>
          <div className={classes.commentInfo}>
            <span>
              <b>{comment.user}</b>
            </span>
            <span>-</span>
            <span>{dateTimeFormat(comment.createdAt)}</span>

            {comment.type === 'EXTERNAL' && GLOBAL_ROLES.isAdmin(this.user.role) && (
              <FormControlLabel
                value="end"
                control={<Switch
                  color="primary"
                  checked={comment.isAnonymous}
                  onChange={event => {
                    const request = this.props.updateComment(comment.id, {
                      isAnonymous: event.target.checked
                    });

                    this.setState({ disableSwitch: true });

                    request.then(() => {
                      this.setState({
                        comment: {
                          ...comment,
                          isAnonymous: event.target.checked
                        },
                        disableSwitch: false
                      });
                    }).catch(() => {
                      this.setState({ disableSwitch: false });
                    });
                  }}
                  disabled={disableSwitch}
                />}
                label="Anónimo"
                labelPlacement="end"
                className={classes.switchFormControl}
              />
            )}
          </div>
          {this.renderCommentChips()}
          {this.editTextField(comment, classes)}
          <GridList cellHeight={160} className={classes.gridList} cols={3}>
            {this.state.imageUrls.map(img => (
              <GridListTile key={img} cols={1}>
                <img src={img} alt={img} onClick={this.handleClickOpen(img)} />
              </GridListTile>
            ))}
          </GridList>
          <ImageDialog open={this.state.dialogOpen} image={this.state.currentImage} closeDialog={() => this.setState({ dialogOpen: false })} />

          {this.visibilityButton(comment, classes)}
        </div>
      </div>
    );
  }
}

export default IncidentComment;
