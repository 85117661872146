export async function asyncForEach(array, callback) {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index]);
  }
}

export function isEmpty(obj) {
  if (obj === "undefined" || obj === null) return true;

  return Object.keys(obj).length === 0 && obj.constructor === Object;
}
