import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";

import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import CircularProgress from "@material-ui/core/CircularProgress";

// core components
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import Button from "components/CustomButtons/Button.jsx";

import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";

import Select from "components/Select/Select.jsx";

import { } from "utils/StringExtension";

import IncidentMap from "./IncidentMap";
import IncidentImage from "../IncidentImage";

import AuthService from "components/Services/AuthService";

import { uploadFirebase } from "utils/IncidentUtil";

import { primaryColor } from "assets/jss/material-dashboard-react.jsx";

const styles = theme => ({
  rootContent: {
    position: "relative",
  },
  coverLoading: {
    position: "absolute",
    height: "100%",
    width: "100%",
    zIndex: 1,
    backgroundColor: "#ddd",
    opacity: 0.3,
  },
  progress: {
    color: primaryColor,
    position: "absolute",
    top: "calc(50% - 4em)",
    left: "calc(50% - 4em)",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 400,
  },
  selectContent: {
    margin: `${theme.spacing.unit * 3}px ${theme.spacing.unit}px
    ${theme.spacing.unit * 3}px ${theme.spacing.unit}px`,
  },
  dividerSecction: {
    height: "48px",
    borderBottom: "1px solid rgba(224, 224, 224, 1)",
    backgroundColor: "#eeeeee50",
    padding: `0 ${theme.spacing.unit * 3}px ${theme.spacing.unit}px ${theme.spacing.unit * 3}px`,
    marginBottom: theme.spacing.unit,
  },
  title: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
    marginBottom: theme.spacing.unit,
    paddingTop: theme.spacing.unit * 3,
    color: primaryColor,
  },
});

const defaultLocation = { lat: 18.483402, lng: -69.929611 };

class IncidentCreate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      categories: [],
      neighborhoods: [],
      subneighborhoods: [],
      isLoaded: false,
      errorSnackbarOpen: false,
      mapDialogOpen: false,
      isSetLocation: false,
      location: defaultLocation,
      images: [],
      form: {
        title: null,
        description: null,
        category: null,
        streetName: null,
        streetNumber: null,
        neighborhood: null,
        subneighborhood: null,
      },
      formError: {
        title: false,
        description: false,
        category: false,
        streetName: false,
        streetNumber: false,
        neighborhood: false,
        subneighborhood: false,
      },
    };

    this.AuthService = new AuthService();
  }

  componentDidMount() {
    this.getCategories();
    this.getNeighborhood();
  }

  handleErrorSnackbarClose = (_event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    this.setState({ errorSnackbarOpen: false });
  };

  handleInputChange = name => event => {
    var newForm = this.state.form;
    var newErrorForm = this.state.formError;

    newForm[name] = event.target.value;
    newErrorForm[name] = !newForm[name] || newForm[name].length === 0;

    this.setState({
      form: newForm,
      formError: newErrorForm,
    });
  };

  handleChangeCloseCategory = value => {
    var newForm = this.state.form;
    var newErrorForm = this.state.formError;

    newForm.category = value;
    newErrorForm.category = !newForm.category;

    this.setState({
      form: newForm,
      formError: newErrorForm,
    });
  };

  handleChangeNeighborhood = value => {
    var newForm = this.state.form;
    var newErrorForm = this.state.formError;

    newForm.neighborhood = value;
    newErrorForm.neighborhood = !newForm.neighborhood;

    this.setState({ form: newForm });
    this.getSubNeighborhood(value.value);
  };

  handleChangeSubneighborhood = value => {
    var newForm = this.state.form;
    var newErrorForm = this.state.formError;

    newForm.subneighborhood = value;
    newErrorForm.subneighborhood = !newForm.subneighborhood;

    this.setState({ form: newForm });
  };

  handleClickOpenMapDialog = () => {
    this.setState({ mapDialogOpen: true });
  };

  handleClickCloseMapDialog = location => {
    this.setState({
      location: location,
      isSetLocation: true,
      mapDialogOpen: false,
    });
  };

  handleImageChange = images => {
    this.setState({
      images: images,
    });
  };

  handleSave = () => {
    if (this.__saveClicked)
      return;

    this.__saveClicked = true;

    if (!this.validFields()) {
      this.__saveClicked = false;

      return;
    }

    this.setState({
      isLoaded: false,
      errorSnackbarOpen: false,
      error: null,
    });

    let incidentResquest = {
      title: this.state.form.title,
      description: this.state.form.description,
      category: this.state.form.category.value,
      location: {
        coordinates: {
          latitude: this.state.location.lat,
          longitude: this.state.location.lng,
        },
      },
    };

    if (this.state.form.category.addressRequired) {
      incidentResquest = {
        ...incidentResquest,
        address: {
          neighborhood: this.state.form.neighborhood.value,
          subNeighborhood: this.state.form.subneighborhood.value,
          street: `${this.state.form.streetName}/${this.state.form.streetNumber}`,
        },
      };
    }

    this.AuthService.authFetch("/admin/incidents", {
      method: "POST",
      body: JSON.stringify(incidentResquest),
    })
      .then(res => {
        const incidentId = res.result.id;
        const images = this.state.images;

        if (images.length)
          uploadFirebase(images, incidentId).then(urls => {
            const incidentRequest = {
              pictureUrl: urls,
            };

            this.AuthService.authFetch(`/admin/incidents/incident/${incidentId}`, {
              method: "PUT",
              body: JSON.stringify(incidentRequest),
            })
              .then(() => {
                this.__saveClicked = false;
                this.props.history.goBack();
              })
              .catch(error => {
                this.__saveClicked = false;

                error.message = "Ocurrió un problema creando el incidente.";
                this.setState({
                  isLoaded: true,
                  errorSnackbarOpen: true,
                  error,
                });
              });
          });
        else {
          this.__saveClicked = false;
          this.props.history.goBack();
        }
      })
      .catch(error => {
        console.log('Failed to save incident:', error);

        this.__saveClicked = false;

        if (error.message === 'Conflict')
          error.message = 'La localidad no está en el rango esperado';
        else
          error.message = "Ocurrió un problema creando el incidente.";

        this.setState({
          isLoaded: true,
          errorSnackbarOpen: true,
          error,
        });
      });
  };

  validFields() {
    var newErrorForm = this.state.formError;

    newErrorForm.title = !this.state.form.title || this.state.form.title.length === 0;

    newErrorForm.description = !this.state.form.description || this.state.form.description.length === 0;

    newErrorForm.category = this.state.form.category === null;

    const addressRequired = this.state.form.category !== null && this.state.form.category.addressRequired;

    newErrorForm.streetName = addressRequired && (!this.state.form.streetName || this.state.form.streetName.length === 0);

    newErrorForm.streetNumber = addressRequired && (!this.state.form.streetNumber || this.state.form.streetNumber.length === 0);

    newErrorForm.neighborhood = addressRequired && this.state.form.neighborhood === null;
    newErrorForm.subneighborhood = addressRequired && this.state.form.subneighborhood === null;

    const locationRequired = !addressRequired && !this.state.isSetLocation;
    if (locationRequired && !newErrorForm.category) {
      this.setState({
        errorSnackbarOpen: true,
        error: {
          message: "Por favor, seleccione una localidad.",
        },
      });
    } else {
      this.setState({
        errorSnackbarOpen: false,
        error: null,
      });
    }

    this.setState({
      formError: newErrorForm,
    });

    return (
      !newErrorForm.title &&
      !newErrorForm.description &&
      !newErrorForm.category &&
      !newErrorForm.streetName &&
      !newErrorForm.streetNumber &&
      !newErrorForm.neighborhood &&
      !newErrorForm.subneighborhood &&
      !locationRequired
    );
  }

  getCategories = () => {
    this.AuthService.authFetch("/admin/categories/regular")
      .then(res => {
        this.setState({
          isLoaded: true,
          categories: res.result,
        });
      })
      .catch(error => {
        error.message = "Ups!!! Ocurrió un problema obteniendo los datos.";
        this.setState({
          isLoaded: true,
          error,
        });
      });
  };

  getNeighborhood = () => {
    this.AuthService.authFetch("/admin/neighborhood")
      .then(res => {
        this.setState({
          isLoaded: true,
          neighborhoods: res.result,
        });
      })
      .catch(error => {
        error.message = "Ups!!! Ocurrió un problema obteniendo los datos.";
        this.setState({
          isLoaded: true,
          error,
        });
      });
  };

  getSubNeighborhood = neighborhood => {
    this.setState({ subneighborhoodSelected: null });

    this.AuthService.authFetch(`/admin/neighborhood/${neighborhood}/sub-neighborhood`)
      .then(res => {
        this.setState({
          isLoaded: true,
          subneighborhoods: res.result,
        });
      })
      .catch(error => {
        error.message = "Ups!!! Ocurrió un problema obteniendo los datos.";
        this.setState({
          isLoaded: true,
          error,
        });
      });
  };

  render() {
    const { classes } = this.props;
    const { categories, neighborhoods, subneighborhoods, mapDialogOpen, location, isSetLocation } = this.state;

    const categoriesSuggestions = categories.map(category => ({
      value: category.id,
      label: category.name.capitalize(),
      addressRequired: category.addressRequired,
    }));

    const neighborhoodSuggestions = neighborhoods.map(neighborhood => ({
      value: neighborhood.id,
      label: neighborhood.name.capitalize(),
    }));

    const subneighborhoodSuggestions = subneighborhoods.map(subneighborhood => ({
      value: subneighborhood.id,
      label: subneighborhood.name.capitalize(),
    }));

    return (
      <div className={classes.rootContent}>
        {this.state.error && (
          <Snackbar
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            open={this.state.errorSnackbarOpen}
            onClose={this.handleErrorSnackbarClose}
            ContentProps={{
              "aria-describedby": "message-id",
            }}
            message={<span id="message-id">{this.state.error.message}</span>}
            action={[
              <IconButton key="close" aria-label="Close" color="inherit" className={classes.close} onClick={this.handleErrorSnackbarClose}>
                <CloseIcon />
              </IconButton>,
            ]}
          />
        )}
        {!this.state.isLoaded && <div className={classes.coverLoading} />}
        <Grid container>
          <Grid item xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="primary">
                <h4 className={classes.cardTitleWhite}>Crear Incidente</h4>
              </CardHeader>
              <CardBody>
                <div className={classes.dividerSecction}>
                  <h4 className={classes.title}>Información</h4>
                </div>
                <Grid container>
                  <form noValidate autoComplete="off">
                    <Grid item xs={12} sm={12} md={12}>
                      <TextField
                        id="title"
                        className={classes.textField}
                        label="Título"
                        required
                        margin="normal"
                        error={this.state.formError.title}
                        value={this.state.form.title || ''}
                        onChange={this.handleInputChange("title")}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                      <TextField
                        id="description"
                        className={classes.textField}
                        label="Descripción"
                        multiline
                        rowsMax="8"
                        required
                        margin="normal"
                        error={this.state.formError.description}
                        value={this.state.form.description || ''}
                        onChange={this.handleInputChange("description")}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                      <div className={classes.selectContent}>
                        <Select
                          options={categoriesSuggestions}
                          onChange={this.handleChangeCloseCategory}
                          error={this.state.formError.category}
                          value={this.state.form.category}
                          placeholder="Escribe el nombre de la categoría"
                        />
                      </div>
                    </Grid>
                  </form>
                </Grid>
                {!this.state.form.category ||
                  (this.state.form.category.addressRequired && (
                    <div>
                      <div className={classes.dividerSecction}>
                        <h4 className={classes.title}>Dirección</h4>
                      </div>
                      <Grid container>
                        <form noValidate autoComplete="off">
                          <Grid item xs={12} sm={12} md={12}>
                            <TextField
                              id="streetName"
                              className={classes.textField}
                              label="Nombre de calle"
                              required
                              margin="normal"
                              error={this.state.formError.streetName}
                              value={this.state.form.streetName}
                              onChange={this.handleInputChange("streetName")}
                            />
                          </Grid>
                          <Grid item xs={12} sm={12} md={12}>
                            <TextField
                              id="streetNumber"
                              className={classes.textField}
                              label="Numero de calle"
                              required
                              margin="normal"
                              type="number"
                              error={this.state.formError.streetNumber}
                              value={this.state.form.streetNumber}
                              onChange={this.handleInputChange("streetNumber")}
                            />
                          </Grid>
                          <Grid item xs={12} sm={12} md={12}>
                            <div className={classes.selectContent}>
                              <Select
                                options={neighborhoodSuggestions}
                                onChange={this.handleChangeNeighborhood}
                                error={this.state.formError.neighborhood}
                                value={this.state.form.neighborhood}
                                placeholder="Escribe el nombre del sector"
                              />
                            </div>
                          </Grid>
                          <Grid item xs={12} sm={12} md={12}>
                            <div className={classes.selectContent}>
                              <Select
                                options={subneighborhoodSuggestions}
                                onChange={this.handleChangeSubneighborhood}
                                error={this.state.formError.subneighborhood}
                                value={this.state.form.subneighborhood}
                                placeholder="Escribe el nombre del vecindario"
                              />
                            </div>
                          </Grid>
                        </form>
                      </Grid>
                    </div>
                  ))}
                <div className={classes.dividerSecction}>
                  <h4 className={classes.title}>Localidad</h4>
                </div>
                <div>
                  <Button onClick={this.handleClickOpenMapDialog} color={isSetLocation ? "primary" : undefined}>
                    {isSetLocation ? "Cambiar de localidad" : "Seleccione una localidad"}
                  </Button>
                  <IncidentMap
                    title={isSetLocation ? "Cambiar de localidad" : "Seleccione una localidad"}
                    open={mapDialogOpen}
                    defaultLocation={location}
                    closeDialog={this.handleClickCloseMapDialog}
                  />
                </div>
                <div className={classes.dividerSecction}>
                  <h4 className={classes.title}>Imágenes</h4>
                </div>
                <IncidentImage onFileChange={this.handleImageChange} />
              </CardBody>
              <CardFooter style={{ justifyContent: "normal" }}>
                <Button color="primary" style={{ marginRight: "8px" }} onClick={this.handleSave}>
                  Guardar
                </Button>
                <Button
                  color="default"
                  onClick={() => {
                    this.props.history.goBack();
                  }}>
                  Cancelar
                </Button>
              </CardFooter>
            </Card>
          </Grid>
        </Grid>
        {!this.state.isLoaded && <CircularProgress className={classes.progress} size={50} thickness={5} />}
      </div>
    );
  }
}

IncidentCreate.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(IncidentCreate);
