import React from "react";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import { withStyles, TextField, Button, Snackbar, IconButton, Paper } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import ConfirmDialog from "components/CustomDialogs/ConfirmDialog";
import { CircularLoader } from "components/CustomLoader/Loaders";

import logo from "assets/img/logo.svg";

import AuthService from "components/Services/AuthService";

const styles = theme => ({
  paperContent: {
    padding: `${theme.spacing.unit * 8}px 10px 0`
  },
  paperRoot: {
    maxWidth: "480px",
    margin: "0 auto 50px",
    alignItems: "center",
    padding: `${theme.spacing.unit * 4}px`,
  },
  logoImage: {
    textAlign: "center",
    marginBottom: "60px",
    height: "50px",
    color: "transparent",
  },
  img: {
    width: "90px",
  },
  button: {
    margin: theme.spacing.unit,
    marginTop: "30px"
  },
  cardTitleBlue: {
    color: "#2877bd",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: '100%',
  }
});

const headerStyle = {
  background: 'white',
  boxShadow: '0 5px 5px -5px gray'
}

class ChangePassword extends React.Component {
  constructor(props) {
    super(props);

    const queryString = new URLSearchParams(props.location.search);
    this.query = {
      cgf: queryString.get('cgf'),
      udq: queryString.get('udq')
    };

    this.firstPass = !!this.query.cgf;
    this.AuthService = new AuthService();

    // If not assigning password for the first time and not logged in:
    if ((!this.firstPass && !this.AuthService.loggedIn())
      // If missing a required value
      || (this.firstPass && !this.query.udq))
      props.history.push('/');

    this.state = {
      loading: this.firstPass,
      form: {
        oldPassword: null,
        password: null,
        confirmPassword: null
      },
      formErrors: {
        oldPassword: false,
        password: false,
        confirmPassword: false
      },
      warningSnack: {
        open: false,
        content: null
      },
      openAlertDialog: this.AuthService.loggedIn() && this.firstPass,
    };
  }

  onFieldChange = name => ev => {
    const newForm = { ...this.state.form };
    const newFormErrors = { ...this.state.formErrors };

    newForm[name] = ev.target.value;
    newFormErrors[name] = false;

    this.setState({
      form: newForm,
      formErrors: newFormErrors
    });
  };

  failedValidation() {
    const { form } = this.state;

    if (!this.firstPass && !form.oldPassword)
      return {
        msg: 'La Clave actual no puede estar vacía',
        name: 'oldPassword'
      };

    if (!form.password || form.password.length < 6)
      return {
        msg: 'La Clave debe tener al menos 6 caracteres',
        name: 'password'
      };
    else if ((form.password.match(/\d/g) || []).length < 2)
      return {
        msg: 'La Clave debe tener al menos 2 números',
        name: 'password'
      };
    else if (!form.password.match(/[A-ZÑ]/))
      return {
        msg: 'La Clave debe tener al menos 1 letra mayúscula',
        name: 'password'
      };

    if (form.confirmPassword !== form.password)
      return {
        msg: 'Las Claves no coinciden',
        name: 'confirmPassword'
      };
  }

  onSubmit = ev => {
    ev.preventDefault();

    const { form, formErrors } = { ...this.state };
    const failed = this.failedValidation();

    if (failed) {
      formErrors[failed.name] = true;

      return this.setState({
        formErrors: formErrors,
        warningSnack: {
          open: true,
          content: failed.msg
        }
      });
    }

    this.setState({ loading: true });

    this.AuthService.authFetch('/user/change/password', {
      method: 'post',
      body: JSON.stringify({ ...this.query, pass: form.password })
    }).then(() => {
      this.setState({
        loading: false,
        warningSnack: {
          open: true,
          content: 'Clave guardada! Redirigiendo...'
        }
      });

      setTimeout(() => {
        this.props.history.push('/');
      }, 2500);
    }).catch(() => {
      this.setState({
        loading: false,
        warningSnack: {
          open: true,
          content: 'Ocurrió un problema creando su clave... intente nuevamente más tarde'
        }
      });
    });
  }

  componentDidMount() {
    if (this.firstPass)
      this.AuthService.authFetch('/user/verify/ipcr', {
        method: 'post',
        body: JSON.stringify({ ...this.query })
      }).then(res => {
        if (res.result === true)
          this.setState({ loading: false });
        else
          this.props.history.push('/');
      }).catch(() => {
        this.setState({
          loading: false,
          warningSnack: {
            open: true,
            content: 'Ocurrió un problema validando la solicitud para crear su clave... intente nuevamente más tarde'
          }
        });
      });
  }

  render() {
    const { classes } = this.props;
    const { form, warningSnack, formErrors } = this.state;
    const title = this.firstPass ? 'Crear clave' : 'Cambiar Clave';

    return (
      <div className={classes.paperContent}>
        {this.state.loading && <CircularLoader />}

        <Paper className={classes.paperRoot}>
          <GridContainer>
            <GridItem xs={12}>
              <Card style={{ boxShadow: 'none' }}>
                <CardHeader color="primary" style={headerStyle}>
                  <div className={classes.logoImage}>
                    <img src={logo} alt="logo" className={classes.img} />
                  </div>

                  <h3 className={classes.cardTitleBlue}>
                    {title}
                  </h3>
                </CardHeader>

                <CardBody>
                  <form noValidate autoComplete="off" onSubmit={this.onSubmit}>
                    <GridContainer style={this.firstPass ? { display: 'none' } : {}}>
                      <GridItem xs={12}>
                        <TextField
                          name="oldPassword"
                          label="Clave actual"
                          required
                          error={formErrors.oldPassword}
                          className={classes.textField}
                          type="password"
                          value={form.oldPassword}
                          margin='normal'
                          autoComplete={false}
                          onChange={this.onFieldChange('oldPassword')}
                        />
                      </GridItem>
                    </GridContainer>

                    <GridContainer>
                      <GridItem xs={12}>
                        <TextField
                          name="password"
                          label="Nueva Clave"
                          required
                          error={formErrors.password}
                          className={classes.textField}
                          type="password"
                          value={form.password}
                          margin='normal'
                          autoComplete={false}
                          onChange={this.onFieldChange('password')}
                        />
                      </GridItem>
                    </GridContainer>

                    <GridContainer>
                      <GridItem xs={12}>
                        <TextField
                          label="Confirmar nueva Clave"
                          required
                          error={formErrors.confirmPassword}
                          className={classes.textField}
                          type="password"
                          value={form.confirmPassword}
                          margin='normal'
                          autoComplete={false}
                          onChange={this.onFieldChange('confirmPassword')}
                        />
                      </GridItem>
                    </GridContainer>

                    <div style={{ textAlign: 'right' }}>
                      <Button
                        color="secundary"
                        className={classes.button}
                        style={{ color: 'rgb(230, 71, 59)' }}
                        onClick={() => {
                          this.props.history.push('/');
                        }}>
                        Cancelar
                      </Button>

                      <Button color="primary" className={classes.button} type="submit">
                        Continuar
                      </Button>
                    </div>
                  </form>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </Paper>
        {warningSnack.open && (
          <Snackbar
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            open
            message={warningSnack.content}
            action={[
              <IconButton
                key="close"
                aria-label="Close"
                color="inherit"
                className={classes.close}
                onClick={() => {
                  this.setState({
                    warningSnack: {
                      open: false,
                      content: null
                    }
                  });
                }}>
                <CloseIcon />
              </IconButton>,
            ]}
          />
        )}

        {this.state.openAlertDialog && (
          <ConfirmDialog
            open
            contentText="Tiene una sesión activa actualmente. Si continúa, se cerrará la sesión."
            acceptBtnText="Continuar"
            onAcceptBtnClick={() => {
              this.AuthService.logout();
              this.setState({ openAlertDialog: false });
            }}
            onCancelBtnClick={() => {
              this.props.history.push('/');
            }}
          />
        )}
      </div>
    );
  }
}

export default withStyles(styles)(ChangePassword);