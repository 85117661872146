import React from "react";
import { Grid, withStyles, TextField, Snackbar, FormControl, FormLabel, FormGroup, FormControlLabel, Checkbox } from "@material-ui/core";

import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import Button from "components/CustomButtons/Button.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import CustomFileSelector from "components/CustomInput/CustomFileSelector";
// import CustomMaskedInput from "components/CustomInput/CustomMaskedInput";
import CircularProgress from "@material-ui/core/CircularProgress";
import CheckIcon from '@material-ui/icons/Check';
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

import { GENERIC_STATUS } from "variables/general";
import { primaryColor } from "assets/jss/material-dashboard-react.jsx";
import AuthService from "components/Services/AuthService";
import { uploadFile, getFileURL, removeFile } from "utils/Firebase";
import not_found_sm from "assets/img/not_found_sm.png";


const styles = theme => ({
  rootContent: {
    position: "relative",
  },
  coverLoading: {
    position: "absolute",
    height: "100%",
    width: "100%",
    zIndex: 1,
    backgroundColor: "#ddd",
    opacity: 0.3,
  },
  progress: {
    color: primaryColor,
    position: "absolute",
    top: "calc(50% - 4em)",
    left: "calc(50% - 4em)",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: '100%',
    boxSizing: 'border-box',
    paddingRight: theme.spacing.unit * 2,
  },
  selectContent: {
    margin: `${theme.spacing.unit * 3}px ${theme.spacing.unit}px
    ${theme.spacing.unit * 3}px ${theme.spacing.unit}px`,
  },
  dividerSecction: {
    height: "48px",
    borderBottom: "1px solid rgba(224, 224, 224, 1)",
    backgroundColor: "#eeeeee50",
    padding: `0 ${theme.spacing.unit * 3}px ${theme.spacing.unit}px ${theme.spacing.unit * 3}px`,
    marginBottom: theme.spacing.unit,
  },
  title: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
    marginBottom: theme.spacing.unit,
    paddingTop: theme.spacing.unit * 3,
    color: primaryColor,
  },
  notiSnack: {
    backgroundColor: '#eee',

    '& #noti-message': {
      display: 'flex',
      color: 'black',

      '& #noti-message-icon': {
        fontSize: 20,
        opacity: 0.9,
        marginRight: theme.spacing.unit,
        color: 'green',
      }
    }
  },
  close: {
    width: theme.spacing.unit * 4,
    height: theme.spacing.unit * 4,
    color: 'darkgray',
  },
  servicesArea: {
    width: '100%',
    marginTop: theme.spacing.unit * 5,
  },
  servicesAreaTitle: {
    marginBottom: theme.spacing.unit * 2,
  },
  servicesAreaContent: {
    marginBottom: theme.spacing.unit * 5,
  },
});

class ManagePlacesCategories extends React.Component {
  constructor(props) {
    super(props);

    this.AuthService = new AuthService();
    this.action = props.location.pathname.includes('create') ? 'create' : 'edit';
    this.categoryID = props.match.params.id;

    this.state = {
      loading: this.action === 'edit',
      snackError: {
        open: false,
        payload: null,
      },
      notiSnack: {
        open: false,
        payload: null,
      },
      category: {},
      image: null,
      selectedImage: null,
      form: {
        name: '',
        headerTitle: '',
        description: '',
        status: true,
      },
      formError: {
        name: false,
        headerTitle: false,
        description: false,
      },
    };
  }

  componentDidMount() {
    const getImageURL = () => {
      const { category } = this.state;

      if (!category.image)
        return this.setState({
          loading: false,
        });

      const imageProm = new Promise((resolve, reject) => {
        getFileURL(category.image)
          .then(url => {
            resolve(url);
          })
          .catch(reason => {
            if (reason.code === 'storage/object-not-found')
              resolve();

            reject(reason);
          });
      });

      imageProm
        .then(obj => {
          const newState = {
            loading: false,
            selectedImage: {
              url: obj || not_found_sm,
            },
          };

          this.setState(newState);
        })
        .catch(reason => {
          console.log('Failed to load image\'s URL:', reason);

          const newState = {
            loading: false,
            snackError: {
              open: true,
              payload: 'Ocurrió un error cargando la imagen de muestra... intente nuevamente más tarde',
            },
          };

          this.setState(newState);
        });
    };

    if (this.action === 'edit')
      this.AuthService.authFetch(`/admin/places/categories/${this.categoryID}`)
        .then(res => {
          this.setState({
            category: res.result,
            form: {
              name: res.result.name,
              headerTitle: res.result.headerTitle,
              description: res.result.description,
              status: res.result.status === GENERIC_STATUS.ACTIVE,
            },
          });

          setTimeout(() => {
            getImageURL();
          }, 0);
        })
        .catch(reason => {
          console.log('Failed to load category:', reason);

          this.setState({
            loading: false,
            snackError: {
              open: true,
              payload: 'Ocurrió un error cargando la categoría... intente nuevamente más tarde',
            },
          });
        });
  }

  handleInputChange = name => event => {
    const { form, formError } = { ...this.state };
    const notEventfulFields = ['status'];

    if (notEventfulFields.includes(name))
      form[name] = event;
    else
      form[name] = (event.target.value || '');

    if (Object.keys(formError).includes(name))
      formError[name] = !form[name];

    const newState = { form, formError };

    this.setState(newState);
  };

  validation = () => {
    const { form, formError } = { ...this.state };
    let payloadMsg;

    const newState = {};

    const fieldName = {
      name: 'Nombre',
      headerTitle: 'Título',
      description: 'Descripción',
    };

    Object.keys(form).forEach(f => {
      if (typeof form[f] === 'string')
        form[f] = form[f].trim();

      if (Object.keys(formError).includes(f))
        formError[f] = !form[f];
    });

    newState.form = form;
    newState.formError = formError;

    const someError = Object.keys(formError).some(s => {
      if (formError[s])
        payloadMsg = `El campo "${fieldName[s] || s}" debe tener un valor válido`;

      return formError[s];
    });

    if (someError)
      newState.snackError = {
        open: true,
        payload: payloadMsg,
      };
    else
      newState.loading = true;

    this.setState(newState);

    return someError;
  };

  saveCategory = payload => {
    this.AuthService.authFetch(this.action === 'create' ?
      '/admin/places/categories' :
      `/admin/places/categories/${this.categoryID}?booleanResponse=false`, {
        method: this.action === 'create' ? 'POST' : 'PUT',
        body: JSON.stringify(payload),
      })
      .then(res => {
        if (res.result === true || Object.keys(res.result).length) {
          if (this.action === 'create')
            this.props.history.push('/places/categories');
          else {
            const { image } = this.state;

            const newState = {
              loading: false,
              category: { ...res.result },
              selectedImage: image ? {
                url: URL.createObjectURL(image),
              } : null,
              notiSnack: {
                open: true,
                payload: 'La categoría fue actualizada',
              },
              form: {
                name: res.result.name,
                headerTitle: res.result.headerTitle,
                description: res.result.description,
                status: res.result.status === GENERIC_STATUS.ACTIVE,
              },
            };

            this.setState(newState);
          }
        }
        else
          this.setState({
            loading: false,
            snackError: {
              open: true,
              payload: 'Ocurrió un error guardando la categoría... intente nuevamente más tarde',
            },
          });
      })
      .catch(reason => {
        console.log('Failed to save category:', reason);

        let message;

        if (reason.message === 'Conflict')
          message = 'El nombre de la categoría ya existe... favor utilizar uno diferente';
        else
          message = `Ocurrió un error ${{ create: 'creando', edit: 'editando' }[this.action]} la categoría... intente nuevamente más tarde`;

        this.setState({
          loading: false,
          snackError: {
            open: true,
            payload: message,
          },
          notiSnack: {
            open: false,
            payload: null,
          },
        });
      });
  };

  handleSave = () => {
    const { form, category, image, selectedImage } = { ...this.state };

    const foundErrors = this.validation();

    if (foundErrors)
      return;

    const payload = {
      ...form,
    };

    if (this.action === 'edit') {
      if (!image && selectedImage)
        payload.image = category.image;
      else if (!image && !selectedImage)
        payload.image = null;
    }

    if (image)
      uploadFile(`placeCategoriesImages/${Date.now()}_${image.name}`, image)
        .then(uri => {
          payload.image = uri;

          if (this.action === 'edit' && image && category.image)
            removeFile(category.image)
              .then(() => {
                console.log('Unnecessary file removed');
              })
              .catch(reason => {
                console.log('Failed removing unnecessary file:', reason);
              });

          this.saveCategory(payload);
        })
        .catch(reason => {
          console.log('Failed saving category:', reason);
          const { selectedImage } = this.state;

          this.setState({
            loading: false,
            snackError: {
              open: true,
              payload: `Ocurrió un problema ${{ create: 'creando', edit: 'editando' }[this.action]} la categoría... intente nuevamente más tarde`,
            },
            selectedImage: selectedImage || (image ? {
              url: URL.createObjectURL(image),
            } : null),
          });
        });
    else {
      if (category.image && !payload.image)
        removeFile(category.image)
          .then(() => {
            console.log('Unnecessary file removed');
          })
          .catch(reason => {
            console.log('Failed removing unnecessary file:', reason);
          });

      this.saveCategory(payload);
    }
  };

  onNotiSnackClose = (_event, reason) => {
    if (reason === "clickaway")
      return;

    this.setState({
      notiSnack: {
        open: false,
        payload: null,
      }
    });
  };

  render() {
    const { loading, form, formError, snackError, notiSnack, selectedImage } = this.state;
    const { classes } = this.props;

    if (loading)
      return (<CircularProgress className={classes.progress} size={50} />);

    return (
      <Grid container>
        <Grid item xs={12} sm={12} md={12}>
          <Grid container>
            <Grid item xs={12} sm={12} md={12}>
              <Card>
                <CardHeader color="primary">
                  <h4 className={classes.cardTitleWhite}>{{ create: 'Agregar categoría', edit: 'Editar categoría' }[this.action] || this.action}</h4>
                </CardHeader>

                <CardBody>
                  <div className={classes.dividerSecction}>
                    <h4 className={classes.title}>Información general</h4>
                  </div>

                  <Grid container>
                    <Grid item xs={12} sm={12} md={12}>
                      <TextField
                        id="name"
                        className={classes.textField}
                        label="Nombre"
                        required
                        margin="normal"
                        error={formError.name}
                        value={form.name}
                        onChange={this.handleInputChange("name")}
                        autoComplete='off'
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={12}>
                      <TextField
                        id="headerTitle"
                        className={classes.textField}
                        label="Alias"
                        required
                        margin="normal"
                        error={formError.headerTitle}
                        value={form.headerTitle}
                        onChange={this.handleInputChange("headerTitle")}
                        autoComplete='off'
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={12}>
                      <TextField
                        id="description"
                        className={classes.textField}
                        label="Descripción"
                        required
                        margin="normal"
                        error={formError.description}
                        value={form.description}
                        onChange={this.handleInputChange("description")}
                        autoComplete='off'
                      />
                    </Grid>
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <br />
                    <br />

                    <CustomFileSelector
                      buttonText='Imagen de muestra'
                      variant='grid'
                      accept='image/*'
                      selectedMask={selectedImage}
                      onFileChange={files => {
                        const newState = { image: files[0] || null };

                        if (this.state.selectedImage)
                          newState.selectedImage = null;

                        this.setState(newState);
                      }}
                    />
                    <br />
                  </Grid>

                  <FormControl style={{ marginTop: 25 }}>
                    <FormLabel className={classes.servicesAreaTitle}>
                      Estado de la categoría
                    </FormLabel>

                    <FormGroup className={classes.servicesAreaContent}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={form.status}
                            onChange={event => {
                              this.handleInputChange(`status`)(event.target.checked);
                            }}
                            color="primary"
                            inputProps={{
                              'aria-label': 'Activa',
                            }}
                          />
                        }
                        label={'Activa'}
                      />
                    </FormGroup>
                  </FormControl>
                </CardBody>

                <CardFooter style={{ justifyContent: "normal" }}>
                  <Button color="primary" style={{ marginRight: "8px" }} onClick={this.handleSave}>
                    Guardar
                  </Button>

                  <Button
                    onClick={() => {
                      this.props.history.push('/places/categories');
                    }}>
                    Cancelar
                  </Button>
                </CardFooter>
              </Card>
            </Grid>
          </Grid>
        </Grid>

        {snackError.open && (
          <Snackbar
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            open
            onClose={() => {
              this.setState({
                snackError: {
                  open: false,
                  payload: null,
                }
              });
            }}
            ContentProps={{
              "aria-describedby": "error-message-id",
            }}
            message={<span id="error-message-id">{snackError.payload}</span>}
          />
        )}

        {notiSnack.open && (
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            open
            onClose={this.onNotiSnackClose}
            ContentProps={{
              "aria-describedby": "noti-message",
              className: classes.notiSnack,
            }}
            style={{ marginTop: '10px' }}
            message={
              <span id="noti-message">
                <CheckIcon id="noti-message-icon" />
                <span>{notiSnack.payload}</span>
              </span>
            }
            action={[
              <IconButton key="close" aria-label="Close" color="inherit" className={classes.close} onClick={this.onNotiSnackClose}>
                <CloseIcon />
              </IconButton>,
            ]}
          />
        )}
      </Grid>
    );
  }
}


export default withStyles(styles)(ManagePlacesCategories);
