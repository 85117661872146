import Login from "views/Login";
import ForgotPassword from "views/usersDashboard/ForgotPassword";
import ChangePassword from "views/usersDashboard/ChangePassword";
import HomeDashboard from "layouts/Dashboards/HomeDashboard";

const indexRoutes = [
    { path: "/login", component: Login },
    { path: "/forgot", component: ForgotPassword },
    { path: "/user/change_pass", component: ChangePassword },
    { private: true, path: "/", component: HomeDashboard }
];

export default indexRoutes;
