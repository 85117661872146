import IncidentsDashboard from "layouts/Dashboards/IncidentsDashboard";
import GarbagePickupDashboard from "layouts/Dashboards/GarbagePickupDashboard";
import NewsDashboard from "layouts/Dashboards/NewsDashboard";
import UsersDashboard from "layouts/Dashboards/UsersDashboard";
import PaymentDashboard from "layouts/Dashboards/PaymentDashboard";
import PlacesDashboard from "layouts/Dashboards/PlacesDashboard";

const homeDashboard = [
  { private: true, path: "/incidents", component: IncidentsDashboard },
  { private: true, path: "/garbage-pickup", component: GarbagePickupDashboard },
  { private: true, path: "/news", component: NewsDashboard },
  { private: true, path: "/users", component: UsersDashboard },
  { private: true, path: "/payments", component: PaymentDashboard },
  { private: true, path: "/places", component: PlacesDashboard },
];

export default homeDashboard;
