import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import GridListTileBar from "@material-ui/core/GridListTileBar";
import ImageDialog from "components/ImageDialog/ImageDialog.jsx";
import DeleteForeverRounded from "@material-ui/icons/DeleteForeverRounded";
import CheckCircle from "@material-ui/icons/CheckCircle";
import ConfirmDialog from "components/CustomDialogs/ConfirmDialog";

import { SORT_DESC } from "variables/general";

// @material-ui/core components
import Grid from "@material-ui/core/Grid";

import Button from "components/CustomButtons/Button.jsx";

const styles = theme => ({
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 400,
  },
  actionIconArea: {
    width: '100%',
  },
  checkedIcon: {
    color: '#169f16',
  },
});

const descSorter = SORT_DESC('mainFile');
let internalCounter = 0;

class CustomFileSelector extends React.Component {
  constructor(props) {
    super(props);

    this.internalKey = props.key || ++internalCounter;

    this.state = {
      files: (props.selectedMask && Array.isArray(props.selectedMask) ? props.selectedMask : [])
        .map((m, i) => ({
          ...m,
          mainFile: i === 0,
        })),
      imageDialog: {
        open: false,
        url: null,
      },
      confirmDialog: {
        open: false,
        payload: null,
      },
    };
  }

  handleAddFile = event => {
    const { files } = this.state;
    const selectedFiles = [...event.target.files];

    files.forEach(f => {
      if (f.file instanceof File)
        URL.revokeObjectURL(f.url);
    });

    const newFileBatch = files
      .filter(f => !(f.file instanceof File))
      .concat(selectedFiles.map(m => ({
        file: m,
        url: URL.createObjectURL(m),
        mainFile: false,
      })));

    if (newFileBatch.length && !newFileBatch.some(s => s.mainFile))
      newFileBatch[0].mainFile = true;

    this.setState({
      files: newFileBatch,
    });

    if (this.props.onFileChange) {
      const sorted = [...newFileBatch].sort(descSorter);
      this.props.onFileChange(sorted.map(m => m.file || { url: m.url }), { action: 'add' });
    }
  };

  handleRemoveFile = index => () => {
    const { files } = this.state;

    files
      .filter((_f, i) => i === index)
      .forEach(f => {
        if (f.file instanceof File)
          URL.revokeObjectURL(f.url);
      });

    const filteredFiles = files.filter((_f, i) => i !== index);

    if (filteredFiles.length && !filteredFiles.some(s => s.mainFile))
      filteredFiles[0].mainFile = true;

    this.setState({
      files: filteredFiles,
    });

    if (this.props.onFileChange) {
      const sorted = [...filteredFiles].sort(descSorter);
      this.props.onFileChange(sorted.map(m => m.file || { url: m.url }), { action: 'remove' });
    }
  };

  handleImageClick = url => () => {
    this.setState({
      imageDialog: {
        url,
        open: true,
      }
    });
  };

  render() {
    const { files, imageDialog, confirmDialog } = this.state;
    const { classes, buttonText, multiple, accept, buttonColor, variant = 'text', selectedMask, showMainFile = false } = this.props;

    const selectedFiles = files.concat(selectedMask && !Array.isArray(selectedMask) ? [selectedMask] : []);

    return (
      <Grid container alignItems="center">
        <Grid item xs={12} sm={12} md={6}>
          <div
            style={{
              position: "relative",
              width: "100%",
              margin: "auto",
            }}>
            <input
              accept={accept || '*/*'}
              id={`contained-button-file-${this.internalKey}`}
              multiple={multiple}
              type="file"
              style={{ display: "none" }}
              onChange={this.handleAddFile}
            />

            {imageDialog.open && (
              <ImageDialog
                open
                image={imageDialog.url}
                closeDialog={() =>
                  this.setState({
                    imageDialog: {
                      open: false,
                      url: null,
                    }
                  })}
              />
            )}

            <label htmlFor={`contained-button-file-${this.internalKey}`}>
              <Button
                variant="contained"
                component="span"
                color={buttonColor || 'primary'}
                className={classes.button}>
                {buttonText || 'Seleccionar imagenes'}
              </Button>
            </label>

            {variant === 'text' ? (
              <List>
                {selectedFiles.map((obj, inx) => (
                  <ListItem key={inx}>
                    <ListItemText primary={obj.file.name} />
                    <ListItemSecondaryAction>
                      <IconButton
                        aria-label="Delete"
                        color="secondary"
                        onClick={() => {
                          this.setState({
                            confirmDialog: {
                              open: true,
                              payload: this.handleRemoveFile(inx),
                            },
                          });
                        }}>
                        <CloseIcon />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
              </List>
            ) : (
                <GridList cellHeight={160} cols={4} style={{ paddingTop: 8 }}>
                  {selectedFiles.map((obj, inx) => (
                    <GridListTile key={inx} cols={1}>
                      <img src={obj.url} alt={obj.url} onClick={this.handleImageClick(obj.url)} style={{ cursor: 'pointer' }} />
                      <GridListTileBar
                        classes={{ actionIcon: classes.actionIconArea }}
                        actionIcon={
                          <div style={{ textAlign: 'right' }}>
                            <IconButton
                              style={showMainFile ? undefined : { display: 'none' }}
                              onClick={() => {
                                if ((files.find(f => f === obj) || {}).mainFile)
                                  return;

                                const filesCopy = [...files];

                                filesCopy.forEach(f => f.mainFile = false);
                                filesCopy.filter((_f, i) => i === inx)[0].mainFile = true;

                                this.setState({
                                  files: filesCopy,
                                });

                                if (this.props.onFileChange) {
                                  const sorted = [...filesCopy].sort(descSorter);
                                  this.props.onFileChange(sorted.map(m => m.file || { url: m.url }), { action: 'main_changed' });
                                }
                              }}>
                              <CheckCircle color="action" className={obj.mainFile ? classes.checkedIcon : undefined} />
                            </IconButton>

                            <IconButton
                              onClick={() => {
                                this.setState({
                                  confirmDialog: {
                                    open: true,
                                    payload: this.handleRemoveFile(inx),
                                  },
                                });
                              }}>
                              <DeleteForeverRounded color="error" />
                            </IconButton>
                          </div>
                        }
                      />{" "}
                    </GridListTile>
                  ))}
                </GridList>
              )}
          </div>
        </Grid>

        {confirmDialog.open && (
          <ConfirmDialog
            open
            payload={confirmDialog.payload}
            contentText="¿Desea eliminar el archivo?"
            onAcceptBtnClick={payload => {
              payload();

              this.setState({
                confirmDialog: {
                  open: false,
                  payload: null,
                },
              });
            }}
            onCancelBtnClick={() => {
              this.setState({
                confirmDialog: {
                  open: false,
                  payload: null,
                },
              });
            }}
          />
        )}
      </Grid>
    );
  }
}

CustomFileSelector.propTypes = {
  classes: PropTypes.object.isRequired,
  onFileChange: PropTypes.func,
  buttonText: PropTypes.string,
  multiple: PropTypes.bool,
  accept: PropTypes.string,
  buttonColor: PropTypes.string,
  variant: PropTypes.oneOf(['text', 'grid']),
  showMainFile: PropTypes.bool,
  selectedMask: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

export default withStyles(styles)(CustomFileSelector);