import React from "react";
import PropTypes from "prop-types";
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps";
import Autocomplete from "react-google-autocomplete";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";

import ping from "assets/img/pingMap.png";

import { config } from "utils/Firebase";
import { mapStyle } from "variables/general";

class CustomSkinMap extends React.Component {
  constructor(props) {
    super(props);

    this.customSkinMap = withScriptjs(
      withGoogleMap(props => (
        <GoogleMap
          defaultZoom={15.5}
          defaultCenter={props.defaultLocation}
          onClick={props.onClick}
          ref={this.onMapMounted}
          defaultOptions={{
            streetViewControl: false,
            mapTypeControl: false,
            zoomControl: true,
            styles: mapStyle,
          }}
          style={{ height: 900 }}>
          <Autocomplete
            style={{
              boxSizing: 'border-box',
              border: '1px solid transparent',
              width: 240,
              height: 32,
              marginTop: 27,
              padding: '0 12px',
              borderRadius: 3,
              boxShadow: '0 2px 6px rgba(0, 0, 0, 0.3)',
              fontSize: '10pt',
              outline: 'none',
              textOverflow: 'ellipses',
              position: 'absolute',
              top: 0,
              left: 20,
            }}
            placeholder="Buscar"
            onPlaceSelected={props.onPlaceSelected}
            types={[]}
            componentRestrictions={{ country: 'do' }}
          // fields={['address_components', 'geometry', 'icon', 'name']}
          />

          <Marker
            position={props.defaultLocation}
            onDragEnd={props.onDragEnd}
            draggable
            defaultIcon={ping}
          />
        </GoogleMap>
      )
      )
    );
  }

  onMapMounted = map => {
    this.props.onMapMounted(map);
    this.map = map;
  }

  render() {
    return <this.customSkinMap {...this.props} />;
  }
}

class CustomMap extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      location: props.defaultLocation,
      map: undefined,
    };
  }

  onMapMounted = map => {
    this.map = map;
  };

  componentWillUnmount() {
    this.map = undefined;
  }

  handleClose = () => {
    if (this.props.closeDialog)
      this.props.closeDialog(this.state.location);
  };

  handleCancel = () => {
    if (this.props.handleCancel)
      this.props.handleCancel();
  };

  onLocationChanged = ev => {
    const location = { lat: ev.latLng.lat(), lng: ev.latLng.lng() };
    this.setState({ location });
  };

  onPlaceSelected = place => {
    if (!place.geometry)
      return;

    const location = { lat: place.geometry.location.lat(), lng: place.geometry.location.lng() };
    this.map.panTo(location);
    this.setState({ location });
  };

  render() {
    const { open, title } = this.props;
    const { location } = this.state;

    return (
      <Dialog open={open} onClose={this.handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent
          style={{
            minHeight: 400,
            minWidth: 500,
            boxSizing: 'border-box',
          }}>

          <style dangerouslySetInnerHTML={{
            __html: `.pac-container {
              z-index: 1300;
            }`}}
          />

          <CustomSkinMap
            googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${config.apiKey}&libraries=places`}
            loadingElement={<div style={{ height: `100%` }} />}
            containerElement={<div style={{ height: `400px`, position: 'relative' }} />}
            mapElement={<div style={{ height: `100%` }} />}
            defaultLocation={location}
            onMapMounted={this.onMapMounted}
            onClick={this.onLocationChanged}
            onDragEnd={this.onLocationChanged}
            onPlaceSelected={this.onPlaceSelected}
          />
        </DialogContent>

        <DialogActions>
          <Button onClick={this.handleCancel}>
            Cancelar
          </Button>

          <Button onClick={this.handleClose} color="primary" autoFocus>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

CustomMap.propTypes = {
  closeDialog: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  defaultLocation: PropTypes.object.isRequired,
};

export default CustomMap;
