import React from "react";
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import TextField from "@material-ui/core/TextField";

// core components
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Button from "components/CustomButtons/Button.jsx";

// incident components
import IncidentComments from "./IncidentComments";
import IncidentImagesGridList from "./IncidentImagesGridList";
import IncidentUserDialog from "./IncidentUserDialog";
import IncidentChangeCategoryDialog from "./IncidentChangeCategoryDialog";
import IncidentChangePriorityDialog from "./IncidentChangePriorityDialog";
import IncidentCloseDialog from "./IncidentCloseDialog";

import AuthService from "components/Services/AuthService";

import { uploadFirebase, deleteFromFirebase } from "utils/IncidentUtil";

import { primaryColor } from "assets/jss/material-dashboard-react.jsx";

import { config } from "utils/Firebase";

import { isEmpty } from "utils/HubUtil";

import ping from "assets/img/pingMap.png";

import { incidentStatus, incidentRoles, commentType, imageType, mapStyle, SOLVER_STATUS, INCIDENT_STATUS, GLOBAL_ROLES, MODULES_INFO } from "variables/general";

import { dateTimeFormat } from "utils/DateUtil.jsx";
import { Switch } from "@material-ui/core";

const styles = theme => ({
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: 300,
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: 400,
      lineHeight: 1,
    },
  },
  expansionPanel: {
    boxShadow: "none;",
    "&:before": {
      backgroundColor: "transparent;",
    },
  },
  expansionPanelSummary: {
    borderBottom: "1px solid rgba(224, 224, 224, 1)",
    backgroundColor: "#eeeeee50",
    minHeight: "48px !important;",
    "& div": {
      margin: "0 !important;",
    },
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
    marginBottom: theme.spacing.unit,
    color: primaryColor,
  },
  title: {
    textTransform: "none;",
    fontWeight: 500,
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit / 2,
  },
  content: {
    marginTop: theme.spacing.unit / 4,
    marginBottom: theme.spacing.unit / 2,
  },
  statusContent: {
    display: "flex",
    "& p": {
      backgroundColor: "#FFC600",
      paddingLeft: theme.spacing.unit / 2,
      paddingRight: theme.spacing.unit / 2,
      borderRadius: "3px",
      fontWeight: "bold",
    },
  },
  tabsIndicator: {
    backgroundColor: primaryColor,
  },
  tabRoot: {
    color: primaryColor,
    opacity: 1,
    fontWeight: 400,
    textTransform: "none;",
  },
  avatar: {
    margin: 10,
  },
  link: {
    paddingTop: "2px",
    color: primaryColor,
    textTransform: "none",
    padding: "0px 16px",
  },
  wrapper: {
    width: "100px",
    position: "relative",
  },
  buttonSuccess: {
    backgroundColor: primaryColor,
    "&:hover": {
      backgroundColor: primaryColor,
    },
  },
  incidentNakedInput: {
    width: "100%",
    color: "#FFFFFF",
  },
  buttonProgress: {
    color: primaryColor,
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  formControlLabelRoot: {
    "& span": { fontWeight: 500 },
  },
  checkbox: {
    padding: theme.spacing.unit * 1.4,
  },
  checkboxRoot: {
    color: primaryColor,
    "&$checked": {
      color: primaryColor,
    },
  },
  checked: {
    color: primaryColor,
  },
  input: {
    display: "flex",
    padding: 0,
  },
  paper: {
    position: "absolute",
    zIndex: 1,
    marginTop: theme.spacing.unit,
    left: 0,
    right: 0,
  },
  divider: {
    height: theme.spacing.unit * 2,
  },
  progress: {
    color: primaryColor,
    position: "absolute",
    top: "calc(50% - 4em)",
    left: "calc(50% - 4em)",
  },
  updateProgress: {
    color: primaryColor,
    position: "absolute",
    top: "calc(50% - 4em)",
    left: "calc(50% - 4em)",
  },
  buttonContent: {
    display: "flex",
    "& button": {
      margin: theme.spacing.unit,
    },
  },
  rootContent: {
    position: "relative",
  },
  coverLoading: {
    position: "absolute",
    height: "100%",
    width: "100%",
    zIndex: 1,
    backgroundColor: "#ddd",
    opacity: 0.3,
  },
  switchFormControl: {
    marginLeft: '10px'
  },
  anonymousIncidentBadge: {
    backgroundColor: 'lightgray',
    padding: '5px 10px',
    borderRadius: '20px',
    marginBottom: '10px',
    display: 'inline-block'
  },
  commentsHolder: {
    maxHeight: '800px',
    overflowY: 'auto',
  },
});

const SimpleMap = withScriptjs(
  withGoogleMap(props => (
    <GoogleMap
      defaultZoom={15.5}
      defaultCenter={props.mapCenter}
      defaultOptions={{
        scrollwheel: false,
        draggable: true,
        streetViewControl: false,
        mapTypeControl: false,
        fullscreenControl: false,
        zoomControl: true,
        styles: mapStyle,
      }}>
      <Marker
        icon={{
          url: ping,
        }}
        position={props.markerLocation}
      />
    </GoogleMap>
  ))
);

class IncidentDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      incidentIsLoaded: false,
      incidentError: null,
      updateLoading: false,
      updateError: null,
      errorSnackbarOpen: false,
      imagesTabSelectedIndex: 0,
      activityTabSelectedIndex: 0,
      categoryDialogOpen: false,
      priorityDialogOpen: false,
      closeDialogOpen: false,
      incident: null,
      title: "",
      description: "",
      incidentDialog: {
        action: null,
        open: false,
        title: null,
        selected: null,
        onAccept: null,
        onClose: null
      },
      templates: [],
      disableSwitch: false
    };

    this.AuthService = new AuthService();
    this.user = this.AuthService.getProfile().user;

    this.isIncidentModuleAdmin = GLOBAL_ROLES.isAdmin(this.user.role)
      || this.user.modules.some(s => s.name === MODULES_INFO.INCIDENT_MODULE.id
        && s.localRole === MODULES_INFO.INCIDENT_MODULE.roles.INCIDENT_ADMIN.id);

    this.isIncidentModuleAdminOrHandler = this.isIncidentModuleAdmin
      || this.user.modules.some(s => s.name === MODULES_INFO.INCIDENT_MODULE.id
        && s.localRole === MODULES_INFO.INCIDENT_MODULE.roles.INCIDENT_HANDLER.id);
  }

  loadPage = () => {
    this.getIncident();

    this.AuthService.authFetch('/admin/template?showInactives=false')
      .then(res => {
        this.setState({ templates: res.result });
      })
      .catch(reason => {
        console.error('Failed retrieving templates:', reason);
      });
  };

  componentDidMount() {
    this.loadPage();
  }

  componentDidUpdate(oldProps) {
    if (oldProps.match.params.id === this.props.match.params.id)
      return;

    this.loadPage();
  }

  tabHandleChange = name => (_event, value) => {
    this.setState({ [name]: value });
  };

  dialogHandlerOpen = name => () => {
    this.setState({
      [name]: true,
    });
  };

  dialogHandlerClose = name => () => {
    this.setState({
      [name]: false,
    });
  };

  onDialogClose = () => {
    this.setState({
      incidentDialog: {
        action: null,
        open: false,
        title: null,
        selected: null,
        onAccept: null,
        onClose: null
      }
    });
  };

  handleErrorSnackbarClose = (_event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    this.setState({ errorSnackbarOpen: false });
  };

  categoryDialogHandleAccept = value => {
    let category = {
      id: value.id,
      name: value.name,
      addressRequired: value.addressRequired,
    };

    this.setState({
      categoryDialogOpen: false,
      updateLoading: true,
    });

    let incidentRequest = {
      category: category.id,
    };

    this.AuthService.authFetch(`/admin/incidents/incident/${this.state.incident.id}`, {
      method: "PUT",
      body: JSON.stringify(incidentRequest),
    })
      .then(
        result => {
          this.setState({
            updateLoading: false,
            incident: result.result,
          });
        },
        error => {
          this.setState({
            updateLoading: false,
            errorSnackbarOpen: true,
            updateError: error,
          });
        }
      )
      .catch(error => {
        error.message = "Ocurrió un problema creando el incidente";
        this.setState({
          isLoaded: true,
          errorSnackbarOpen: true,
          error,
        });
      });
  };

  priorityDialogHandleAccept = value => {
    let priority = {
      id: value.id,
      name: value.name,
      description: value.description,
    };

    this.setState({
      priorityDialogOpen: false,
      updateLoading: true,
    });

    let incidentRequest = {
      priority: priority.id,
    };

    this.AuthService.authFetch(`/admin/incidents/incident/${this.state.incident.id}`, {
      method: "PUT",
      body: JSON.stringify(incidentRequest),
    })
      .then(
        result => {
          this.setState({
            updateLoading: false,
            incident: result.result,
          });
        },
        error => {
          this.setState({
            updateLoading: false,
            errorSnackbarOpen: true,
            updateError: error,
          });
        }
      )
      .catch(error => {
        error.message = "Ocurrió un problema actualizando el incidente";
        this.setState({
          isLoaded: true,
          errorSnackbarOpen: true,
          error,
        });
      });
  };

  mutedHandler = event => {
    const incidentRequest = {
      muted: event.target.checked,
    };

    this.setState({
      updateLoading: true,
    });

    this.AuthService.authFetch(`/admin/incidents/incident/${this.state.incident.id}`, {
      method: "PUT",
      body: JSON.stringify(incidentRequest),
    })
      .then(res => {
        this.setState({
          updateLoading: false,
          incident: res.result,
        });
      })
      .catch(error => {
        error.message = "Ocurrió un problema actualizando el incidente";
        this.setState({
          updateLoading: false,
          errorSnackbarOpen: true,
          updateError: error,
        });
      });
  };

  toggleHideIncident = () => {
    const confirmation = window.confirm("¿Realmente desea cambiar la visibilidad del incidente?");

    if (!confirmation) {
      return;
    }

    const incidentRequest = {
      isHidden: !this.state.incident.isHidden,
    };

    this.setState({
      updateLoading: true,
    });

    this.AuthService.authFetch(`/admin/incidents/incident/${this.state.incident.id}`, {
      method: "PUT",
      body: JSON.stringify(incidentRequest),
    })
      .then(() => {
        this.getIncident();
      })
      .catch(error => {
        error.message = "Ocurrió un problema actualizando el incidente";
        this.setState({
          updateLoading: false,
          errorSnackbarOpen: true,
          updateError: error,
        });
      });
  };

  deleteIncident = () => {
    const confirmation = window.confirm("¿Esta seguro de borrar el incidente? Esta accion es irreversible");

    if (!confirmation) {
      return;
    }

    const incidentRequest = {
      isDeleted: true,
    };

    this.setState({
      updateLoading: true,
    });

    this.AuthService.authFetch(`/admin/incidents/incident/${this.state.incident.id}`, {
      method: "PUT",
      body: JSON.stringify(incidentRequest),
    })
      .then(() => {
        this.props.history.goBack();
      })
      .catch(error => {
        error.message = "Ocurrió un problema actualizando el incidente";
        this.setState({
          updateLoading: false,
          errorSnackbarOpen: true,
          updateError: error,
        });
      });
  };

  closeDialogHandleAccept = (category, comment, images) => {
    var error = null;
    if (category === null || comment.length <= 0) {
      error = {
        message: "Debe completar los campos",
      };
    }

    this.setState({
      closeDialogOpen: error !== null,
      updateLoading: true,
    });

    if (error !== null) {
      this.setState({
        updateLoading: false,
        errorSnackbarOpen: true,
        updateError: error,
      });
    } else {
      const incidentId = this.state.incident.id;

      let closeCategory = {
        id: category.id,
        name: category.name,
        type: "CLOSED",
      };

      let statusUpdateRequest = {
        comment: {
          description: comment,
          type: commentType.SOLUTION_COMPLETED,
        },
        status: incidentStatus.CLOSED,
        categoryClosed: closeCategory.id,
      };

      if (images.length === 0) {
        this.updateIncident(statusUpdateRequest);
      } else {
        uploadFirebase(images, incidentId).then(urls => {
          statusUpdateRequest.categoryClosedPictures = urls;
          this.updateIncident(statusUpdateRequest);
        });
      }
    }
  };

  toAssignDialogHandleAccept = (action, user, comment) => {
    if (this.state.incident.status === "CLOSED") {
      const confirmation = window.confirm("¿Está seguro de que desea re-abrir el incidente? Esta acción elmininará las fotos de cierre");

      if (!confirmation)
        return;
    }

    this.setState({
      incidentDialog: {
        action: null,
        open: false,
        title: null,
        selected: null,
        onAccept: null,
        onClose: null
      },
      updateLoading: true,
    });

    var statusUpdateRequest = {
      status: action === "add-solver" ? incidentStatus.WITH_SOLVER : incidentStatus.WITH_HANDLER,
      categoryClosedPictures: [],
    };

    // eslint-disable-next-line
    switch (statusUpdateRequest.status) {
      case incidentStatus.WITH_SOLVER:
        statusUpdateRequest.solver = user.map(u => u.value);
        break;

      case incidentStatus.WITH_HANDLER:
        statusUpdateRequest.handlers = user.map(u => u.value);
        break;
    }

    if (comment.length > 0) {
      statusUpdateRequest.comment = {
        description: comment,
        type: statusUpdateRequest.status === incidentStatus.WITH_HANDLER ? commentType.EXTERNAL : commentType.INTERNAL
      };
    }

    this.updateIncident(statusUpdateRequest);
  };

  updateIncident = statusUpdateRequest => {
    this.AuthService.authFetch(`/admin/incidents/status_update/${this.state.incident.id}`, {
      method: "PUT",
      body: JSON.stringify(statusUpdateRequest),
    })
      .then(res => {
        this.setState({
          updateLoading: false,
          incident: res.result,
        });
      })
      .catch(error => {
        error.message = "Ocurrió un problema actualizando el estatus del incidente";

        this.setState({
          updateLoading: false,
          errorSnackbarOpen: true,
          updateError: error,
        });
      });
  };

  onChangeResponsibleRequested = (action, selectedUser) => {
    const { selected: sentUser } = this.state.incidentDialog;
    const newState = {
      incidentDialog: {
        action: null,
        open: false,
        title: null,
        selected: null,
        onAccept: null,
        onClose: null
      }
    };

    if (selectedUser.value === sentUser.id)
      return this.setState(newState);

    newState.updateLoading = true;
    this.setState(newState);

    let url = `/admin/incidents/${this.state.incident.id}/`;
    let payload;

    if (action === 'change-solver') {
      url += 'change_solver';
      payload = {
        solverID: sentUser.id,
        newSolverID: selectedUser.value
      };
    }
    else {
      url += 'change_handler';
      payload = {
        handlerID: sentUser.id,
        newHandlerID: selectedUser.value
      };
    }

    this.AuthService.authFetch(url, {
      method: 'PUT',
      body: JSON.stringify(payload)
    })
      .then(res => {
        this.setState({
          updateLoading: false,
          incident: res.result
        });
      })
      .catch(reason => {
        reason.message = `Ocurrió un problema cambiando al ${action === 'change-solver' ? 'solucionador' : 'manejador'}`;

        this.setState({
          updateLoading: false,
          errorSnackbarOpen: true,
          updateError: reason
        });
      });
  }

  updateIncidentSolverStatus = statusUpdateRequest => {
    this.AuthService.authFetch(`/admin/incidents/solver_update/${this.state.incident.id}`, {
      method: "PUT",
      body: JSON.stringify(statusUpdateRequest),
    })
      .then(res => {
        this.setState({
          updateLoading: false,
          incident: res.result,
        });
      })
      .catch(error => {
        error.message = "Ocurrió un problema actualizando el estatus del incidente";

        this.setState({
          updateLoading: false,
          errorSnackbarOpen: true,
          updateError: error,
        });
      });
  };

  deleteImage = img => {
    const self = this;

    this.setState({
      updateLoading: true,
    });

    const deleteRes = deleteFromFirebase(img, this.state.incident);

    deleteRes
      .then(() => {
        self.getIncident();
      })
      .catch(error => {
        self.setState({
          incidentIsLoaded: true,
          errorSnackbarOpen: true,
          incidentError: error,
        });
      });
  };

  onUploaded = () => {
    this.getIncident();
  };

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value,
    });
  };

  handleBlur = name => event => {
    const inputValue = event.target.value;

    this.setState({
      [name]: inputValue,
    });

    const incidentRequest = {
      [name]: inputValue,
    };

    this.setState({
      updateLoading: true,
    });

    this.requestIncidentUpdate(incidentRequest)
      .then(() => {
        this.getIncident();
      })
      .catch(error => {
        error.message = "Ocurrió un problema actualizando el incidente";
        this.setState({
          updateLoading: false,
          errorSnackbarOpen: true,
          updateError: error,
        });
      });
  };

  getIncident() {
    const { id } = this.props.match.params;

    this.AuthService.authFetch(`/admin/incidents/incident/${id}`)
      .then(res => {
        var isAllow = false;

        const incidentModule = this.user.modules.filter(m => m.name === "INCIDENT_MODULE")[0];

        if (incidentModule && incidentModule.localRole === incidentRoles.INCIDENT_ADMIN)
          isAllow = true;
        else if (incidentModule && incidentModule.localRole === incidentRoles.INCIDENT_HANDLER)
          isAllow = res.result.handlers && res.result.handlers.some(s => s.id === this.user.id);
        else if (incidentModule && incidentModule.localRole === incidentRoles.INCIDENT_SOLVER)
          isAllow = res.result.solver && res.result.solver.some(s => s.id === this.user.id);

        if (!isAllow)
          throw Error("Este incidente no esta asignado a ti");

        this.setState({
          updateLoading: false,
          incidentIsLoaded: true,
          incident: res.result,
          title: res.result.title,
          description: res.result.description,
          errorSnackbarOpen: false,
          incidentError: null,
        });
      })
      .catch(error => {
        this.setState({
          incidentIsLoaded: true,
          errorSnackbarOpen: true,
          incidentError: error,
        });
      });
  }

  handleInProgress = () => {
    const incidentModule = this.user.modules.filter(m => m.name === "INCIDENT_MODULE")[0];

    if (incidentModule) {
      if (incidentModule.localRole === incidentRoles.INCIDENT_SOLVER) {
        this.updateIncidentSolverStatus({
          status: "IN_PROGRESS",
        });
      } else {
        this.updateIncident({
          status: "IN_PROGRESS",
        });
      }
    }
  };

  handleSolutionCompleted = () => {
    const incidentModule = this.user.modules.filter(m => m.name === "INCIDENT_MODULE")[0];

    if (incidentModule) {
      if (incidentModule.localRole === incidentRoles.INCIDENT_SOLVER) {
        this.updateIncidentSolverStatus({
          status: "DONE",
        });
      } else {
        this.updateIncident({
          status: "SOLUTION_COMPLETED",
        });
      }
    }
  };

  uploadNewImages = files => {
    const self = this;
    const incident = this.state.incident;

    uploadFirebase(files, incident.id).then(urls => {
      self.updateIncident({
        pictureUrl: [...incident.pictureUrl, ...urls],
      });
    });
  };

  requestIncidentUpdate = incidentRequest =>
    this.AuthService.authFetch(`/admin/incidents/incident/${this.state.incident.id}`, {
      method: "PUT",
      body: JSON.stringify(incidentRequest),
    });

  render() {
    const { classes } = this.props;

    const {
      incident,
      incidentError,
      updateError,
      incidentIsLoaded,
      updateLoading,
      incidentDialog,
      disableSwitch
    } = this.state;

    if (incidentError)
      return (
        <div>
          <Snackbar
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            open={this.state.errorSnackbarOpen}
            onClose={this.handleErrorSnackbarClose}
            ContentProps={{
              "aria-describedby": "message-id",
            }}
            message={<span id="message-id">{incidentError.message}</span>}
            action={[
              <IconButton key="close" aria-label="Close" color="inherit" className={classes.close} onClick={this.handleErrorSnackbarClose}>
                <CloseIcon />
              </IconButton>,
            ]}
          />
        </div>
      );
    else if (!incidentIsLoaded)
      return <CircularProgress className={classes.progress} size={50} />;
    else if (!incident)
      return;

    let toAssignButtonTitle = null;
    let toAssignDialogButton = null;
    let toAssignUserAction = null;
    let toAssignEnabled = true;

    switch (incidentStatus[incident.status]) {
      case incidentStatus.OPEN:
        toAssignButtonTitle = "Asignar Manejador";
        toAssignDialogButton = "Seleccione un Manejador";
        toAssignUserAction = "add-handler";
        break;

      case incidentStatus.WITH_HANDLER:
        toAssignButtonTitle = "Asignar Solucionador";
        toAssignDialogButton = "Seleccione un Solucionador";
        toAssignUserAction = "add-solver";
        break;

      default:
        toAssignEnabled = false;
        break;
    }


    let hideIncidentBtn = null;
    let titleBlock = this.state.title;
    let descriptionBlock = <p className={classes.content}>{this.state.description}</p>;

    if (this.isIncidentModuleAdminOrHandler) {
      titleBlock = (
        <TextField
          id="incident-title"
          label="Titulo de incidente"
          className={classes.incidentNakedInput}
          value={this.state.title}
          onChange={this.handleChange("title")}
          onBlur={this.handleBlur("title")}
          margin="normal"
          variant="outlined"
        />
      );

      descriptionBlock = (
        <TextField
          id="incident-description"
          label="Descripcion de incidente"
          multiline
          rowsMax="4"
          className={classes.incidentNakedInput}
          value={this.state.description}
          onChange={this.handleChange("description")}
          onBlur={this.handleBlur("description")}
          margin="normal"
          variant="outlined"
        />
      );

      if (incident.isHidden) {
        hideIncidentBtn = (
          <Button color="danger" onClick={this.toggleHideIncident}>
            Mostrar Incidente
          </Button>
        );
      } else {
        hideIncidentBtn = (
          <Button color="danger" onClick={this.toggleHideIncident}>
            Ocultar Incidente
          </Button>
        );
      }
    }

    return (
      <div className={classes.rootContent}>
        <IncidentUserDialog
          templates={this.state.templates
            .filter(f => f.categories.some(s => s.id === incident.category.id))}
          title={incidentDialog.title}
          selectedValue={incidentDialog.selected}
          action={incidentDialog.action}
          open={incidentDialog.open}
          onAccept={incidentDialog.onAccept}
          onClose={incidentDialog.onClose}
        />

        {updateError && (
          <Snackbar
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            open={this.state.errorSnackbarOpen}
            onClose={this.handleErrorSnackbarClose}
            ContentProps={{
              "aria-describedby": "message-id",
            }}
            message={<span id="message-id">{updateError.message}</span>}
            action={[
              <IconButton
                key="close"
                aria-label="Close"
                color="inherit"
                className={classes.close}
                onClick={this.handleErrorSnackbarClose}>
                <CloseIcon />
              </IconButton>,
            ]}
          />
        )}

        {updateLoading && <div className={classes.coverLoading} />}
        <Card>
          <CardHeader color="primary">
            <h2 className={classes.cardTitleWhite}>{incident.id}</h2>
            <div>{titleBlock}</div>
          </CardHeader>
          <CardBody>
            {incidentStatus[incident.status] !== incidentStatus.CLOSED && (
              <div className={classes.buttonContent}>
                {toAssignEnabled && (
                  <div>
                    <Button
                      color="primary"
                      onClick={() => {
                        this.setState({
                          incidentDialog: {
                            action: toAssignUserAction,
                            open: true,
                            title: toAssignDialogButton,
                            selected: null,
                            onAccept: this.toAssignDialogHandleAccept,
                            onClose: this.onDialogClose
                          }
                        });
                      }}>
                      {toAssignButtonTitle}
                    </Button>
                  </div>
                )}
                {incidentStatus[incident.status] === incidentStatus.WITH_SOLVER ||
                  (incidentStatus[incident.status] === incidentStatus.IN_PROGRESS && (
                    <span>
                      <Button color="primary" onClick={this.handleInProgress}>
                        Iniciar solución
                        </Button>
                      <Button color="primary" onClick={this.handleSolutionCompleted}>
                        Terminar solución
                      </Button>
                    </span>
                  ))}

                {this.isIncidentModuleAdminOrHandler && (
                  <span>
                    <Button color="primary" onClick={this.dialogHandlerOpen("closeDialogOpen")}>
                      Cerrar
                    </Button>

                    <IncidentCloseDialog
                      templates={this.state.templates
                        .filter(f => f.categories.some(s => s.id === incident.category.id))}
                      open={this.state.closeDialogOpen}
                      onClose={this.dialogHandlerClose("closeDialogOpen")}
                      onAccept={this.closeDialogHandleAccept}
                      authService={this.AuthService}
                    />
                    {hideIncidentBtn}
                  </span>
                )}
              </div>
            )}
            {this.isIncidentModuleAdminOrHandler && incidentStatus[incident.status] === incidentStatus.CLOSED && (
              <div className={classes.buttonContent}>
                <Button
                  color="primary"
                  onClick={() => {
                    this.setState({
                      incidentDialog: {
                        action: toAssignUserAction,
                        open: true,
                        title: toAssignDialogButton,
                        selected: incident.handlers,
                        onAccept: this.toAssignDialogHandleAccept,
                        onClose: this.onDialogClose
                      }
                    });
                  }}
                >
                  Re-abrir
                </Button>
              </div>
            )}
            <ExpansionPanel className={classes.expansionPanel} defaultExpanded>
              <ExpansionPanelSummary className={classes.expansionPanelSummary} expandIcon={<ExpandMoreIcon />}>
                <h3 className={classes.heading}>Detalle</h3>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <Grid container spacing={8}>
                  <Grid item xs={12} sm={12} md={7}>
                    <Grid item xs={12}>
                      <h4 className={classes.title}># Incidente</h4>
                      <div style={{ display: "flex" }}>
                        <p className={classes.content}>{incident.id}</p>
                      </div>
                    </Grid>
                    <Grid container spacing={24}>
                      <Grid item xs={12} sm={12} md={6}>
                        <h4 className={classes.title}>Categoría</h4>
                        <div
                          style={{
                            display: "flex",
                          }}>
                          <p className={classes.content}>{incident.category.name}</p>
                          <Button
                            link={true}
                            disableRipple
                            className={classes.link}
                            disabled={incidentStatus[incident.status] === incidentStatus.CLOSED}
                            onClick={this.dialogHandlerOpen("categoryDialogOpen")}>
                            Editar
                            </Button>
                        </div>
                        <IncidentChangeCategoryDialog
                          selectedValue={incident.category}
                          open={this.state.categoryDialogOpen}
                          onClose={this.dialogHandlerClose("categoryDialogOpen")}
                          onAccept={this.categoryDialogHandleAccept}
                          authService={this.AuthService}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <h4 className={classes.title}>Prioridad</h4>
                        <div
                          style={{
                            display: "flex",
                          }}>
                          <p className={classes.content}>{incident.priority.name}</p>
                          <Button
                            link={true}
                            disableRipple
                            className={classes.link}
                            disabled={incidentStatus[incident.status] === incidentStatus.CLOSED}
                            onClick={this.dialogHandlerOpen("priorityDialogOpen")}>
                            Editar
                          </Button>
                        </div>
                        <IncidentChangePriorityDialog
                          selectedValue={incident.priority}
                          open={this.state.priorityDialogOpen}
                          onClose={this.dialogHandlerClose("priorityDialogOpen")}
                          onAccept={this.priorityDialogHandleAccept}
                          authService={this.AuthService}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <h4 className={classes.title}>Estatus</h4>
                        <div className={classes.statusContent}>
                          <p className={classes.content}>{INCIDENT_STATUS[incident.status]}</p>
                        </div>
                      </Grid>
                    </Grid>
                    <Grid container spacing={24}>
                      <Grid item xs={12} sm={12} md={6}>
                        <h4 className={classes.title}>Manejador</h4>
                        <div
                          style={{
                            display: "flex",
                          }}>
                          <div>
                            <div className={classes.content}>
                              {incident.handlers.length > 0
                                ? incident.handlers.map((s, i) => {
                                  return (
                                    <div key={i}>
                                      {this.isIncidentModuleAdmin && (<Button
                                        link
                                        disableRipple
                                        className={classes.link}
                                        disabled={incidentStatus[incident.status] === incidentStatus.CLOSED}
                                        onClick={() => {
                                          this.setState({
                                            incidentDialog: {
                                              action: 'change-handler',
                                              open: true,
                                              title: 'Cambiar manejador',
                                              selected: s,
                                              onAccept: this.onChangeResponsibleRequested,
                                              onClose: this.onDialogClose
                                            }
                                          });
                                        }}>
                                        Cambiar
                                      </Button>)}

                                      <p className={classes.content}>{s.displayName}</p>
                                    </div>
                                  );
                                })
                                : "Sin asignar"}
                            </div>
                          </div>
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <h4 className={classes.title}>Solucionador</h4>
                        <div
                          style={{
                            display: "flex",
                          }}>
                          <div className={classes.content}>
                            {incident.solver.length > 0
                              ? incident.solver.map((s, i) => {
                                return (
                                  <div key={i}>
                                    {this.isIncidentModuleAdminOrHandler && (<Button
                                      link
                                      disableRipple
                                      className={classes.link}
                                      disabled={incidentStatus[incident.status] === incidentStatus.CLOSED}
                                      onClick={() => {
                                        this.setState({
                                          incidentDialog: {
                                            action: 'change-solver',
                                            open: true,
                                            title: 'Cambiar solucionador',
                                            selected: s,
                                            onAccept: this.onChangeResponsibleRequested,
                                            onClose: this.onDialogClose
                                          }
                                        });
                                      }}>
                                      Cambiar
                                    </Button>)}

                                    <strong>{s.displayName}</strong>
                                    <div className={classes.statusContent}>
                                      <p className={classes.content}>{SOLVER_STATUS[s.status]}</p>
                                    </div>
                                  </div>
                                );
                              })
                              : "Sin asignar"}
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                    <Grid container spacing={24}>
                      <Grid item xs={12} sm={12} md={6}>
                        <h4 className={classes.title}>
                          Reportado por

                          {GLOBAL_ROLES.isAdmin(this.user.role) && (
                            <FormControlLabel
                              value="end"
                              control={<Switch
                                color="primary"
                                checked={incident.isAnonymous}
                                onChange={event => {
                                  const newValue = event.target.checked;

                                  this.setState({ disableSwitch: true });

                                  this.requestIncidentUpdate({ isAnonymous: newValue })
                                    .then(() => {
                                      this.setState({
                                        incident: {
                                          ...incident,
                                          isAnonymous: newValue
                                        },
                                        disableSwitch: false
                                      });
                                    }).catch(() => {
                                      this.setState({ disableSwitch: false });
                                    });
                                }}
                                disabled={disableSwitch}
                              />}
                              label="Anónimo"
                              labelPlacement="end"
                              className={classes.switchFormControl}
                            />
                          )}
                        </h4>
                        <div>
                          {incident.isAnonymous && (
                            <p className={classes.content}>
                              <i className={classes.anonymousIncidentBadge}>Reporte anónimo</i>
                            </p>
                          )}
                          <p className={classes.content}>
                            <b>Nombre:</b>
                            <br />
                            {incident.user.displayName.length > 0 ? incident.user.displayName : "N/A"}
                          </p>
                          <p className={classes.content}>
                            <b>Email:</b>
                            <br />
                            {incident.user.email.length > 0 ? incident.user.email : "N/A"}
                          </p>
                          <p className={classes.content}>
                            <b>Telefono:</b>
                            <br />
                            {typeof incident.user.phoneNumber !== "undefined" && incident.user.phoneNumber.length > 0 ? incident.user.phoneNumber : "N/A"}
                          </p>
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <h4 className={classes.title}>Reportado en</h4>
                        <p className={classes.content}>{dateTimeFormat(incident.createdAt)}</p>
                      </Grid>
                    </Grid>
                    <Grid container spacing={24}>
                      <Grid item xs={12} sm={12} md={12}>
                        <h4 className={classes.title}>Descripción</h4>
                        {descriptionBlock}
                      </Grid>
                    </Grid>
                    <Grid container spacing={24}>
                      <Grid item xs={12} sm={12} md={12}>
                        <FormControlLabel
                          classes={{
                            root: classes.formControlLabelRoot,
                          }}
                          control={
                            <Checkbox
                              checked={incident.isMuted}
                              disabled={incidentStatus[incident.status] === incidentStatus.CLOSED}
                              onChange={this.mutedHandler}
                              classes={{
                                root: classes.checkboxRoot,
                                checked: classes.checked,
                              }}
                            />
                          }
                          label="Mutear Incidente"
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={12} md={5}>
                    <h4 className={classes.title}>Localidad</h4>
                    <SimpleMap
                      googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${config.apiKey}`}
                      loadingElement={
                        <div
                          style={{
                            height: `100%`,
                          }}
                        />
                      }
                      containerElement={
                        <div
                          style={{
                            height: `400px`,
                          }}
                        />
                      }
                      mapElement={
                        <div
                          style={{
                            height: `100%`,
                          }}
                        />
                      }
                      mapCenter={{
                        lat: incident.latitude,
                        lng: incident.longitude,
                      }}
                      markerLocation={{
                        lat: incident.latitude,
                        lng: incident.longitude,
                      }}
                    />
                  </Grid>
                </Grid>
              </ExpansionPanelDetails>
            </ExpansionPanel>
            {!isEmpty(incident.address) && (
              <ExpansionPanel className={classes.expansionPanel} defaultExpanded>
                <ExpansionPanelSummary className={classes.expansionPanelSummary} expandIcon={<ExpandMoreIcon />}>
                  <h4 className={classes.heading}>Dirección</h4>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <Grid container spacing={8}>
                    <Grid item xs={12} sm={12} md={12} container>
                      <Grid item xs={12} sm={12} md={12}>
                        <h4 className={classes.title}>Calle/Número</h4>
                        <div
                          style={{
                            display: "flex",
                          }}>
                          <div>
                            <p className={classes.content}>{incident.address.street}</p>
                          </div>
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12}>
                        <h4 className={classes.title}>Sector</h4>
                        <div
                          style={{
                            display: "flex",
                          }}>
                          <div>
                            <p className={classes.content}>{incident.address.neighborhood.name.capitalize()}</p>
                          </div>
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12}>
                        <h4 className={classes.title}>Vecindario</h4>
                        <div
                          style={{
                            display: "flex",
                          }}>
                          <div>
                            <p className={classes.content}>{incident.address.subNeighborhood.name.capitalize()}</p>
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                </ExpansionPanelDetails>
              </ExpansionPanel>
            )}

            <ExpansionPanel className={classes.expansionPanel} defaultExpanded>
              <ExpansionPanelSummary className={classes.expansionPanelSummary} expandIcon={<ExpandMoreIcon />}>
                <h4 className={classes.heading}>Adjuntos</h4>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <Grid container spacing={8}>
                  <Grid item xs={12} sm={12} md={12} container>
                    <Tabs
                      value={this.state.imagesTabSelectedIndex}
                      onChange={this.tabHandleChange("imagesTabSelectedIndex")}
                      classes={{
                        root: classes.tabsRoot,
                        indicator: classes.tabsIndicator,
                      }}
                      variant="fullWidth">
                      <Tab
                        classes={{
                          root: classes.tabRoot,
                          selected: classes.tabSelected,
                        }}
                        label="Imágenes"
                      />
                      <Tab
                        classes={{
                          root: classes.tabRoot,
                          selected: classes.tabSelected,
                        }}
                        label="Imágenes de Cierre"
                      />
                    </Tabs>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} style={{ minHeight: "168px" }}>
                    {this.state.imagesTabSelectedIndex === 0 && (
                      <IncidentImagesGridList
                        canAttach={true}
                        type={imageType.NORMAL}
                        incidentId={incident.id}
                        images={incident.pictureUrl}
                        onUploaded={this.onUploaded}
                        deleteImageHandler={this.deleteImage}
                        uploadImagesHandler={this.uploadNewImages}
                      />
                    )}
                    {this.state.imagesTabSelectedIndex === 1 && (
                      <IncidentImagesGridList
                        canAttach={false}
                        type={imageType.CLOSED}
                        incidentId={incident.id}
                        images={incident.categoryClosedPictures}
                        onUploaded={this.onUploaded}
                        deleteImageHandler={this.deleteImage}
                      />
                    )}
                  </Grid>
                </Grid>
              </ExpansionPanelDetails>
            </ExpansionPanel>
            <ExpansionPanel className={classes.expansionPanel} defaultExpanded>
              <ExpansionPanelSummary className={classes.expansionPanelSummary} expandIcon={<ExpandMoreIcon />}>
                <h3 className={classes.heading}>Actividad</h3>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <Grid container spacing={8}>
                  <Grid item xs={12} sm={12} md={12} container>
                    <Tabs
                      value={this.state.activityTabSelectedIndex}
                      onChange={this.tabHandleChange("activityTabSelectedIndex")}
                      classes={{
                        root: classes.tabsRoot,
                        indicator: classes.tabsIndicator,
                      }}
                      variant="fullWidth">
                      <Tab
                        classes={{
                          root: classes.tabRoot,
                          selected: classes.tabSelected,
                        }}
                        label="Comentarios Externos"
                      />
                      <Tab
                        classes={{
                          root: classes.tabRoot,
                          selected: classes.tabSelected,
                        }}
                        label="Comentarios Internos"
                      />
                      <Tab
                        classes={{
                          root: classes.tabRoot,
                          selected: classes.tabSelected,
                        }}
                        label="Histórico"
                      />
                    </Tabs>
                  </Grid>
                  <Grid className={classes.commentsHolder} item xs={12} sm={12} md={12} style={{ padding: '4px 10px' }}>
                    {this.state.activityTabSelectedIndex === 0 && (
                      <IncidentComments
                        incidentId={incident.id}
                        isEnabledComment={incidentStatus[incident.status] !== incidentStatus.CLOSED && !incident.isMuted}
                        commentType={commentType.EXTERNAL}
                        authService={this.AuthService}
                        isEditable={this.isIncidentModuleAdminOrHandler}
                      />
                    )}
                    {this.state.activityTabSelectedIndex === 1 && (
                      <IncidentComments
                        incidentId={incident.id}
                        isEnabledComment={incidentStatus[incident.status] !== incidentStatus.CLOSED && !incident.isMuted}
                        commentType={commentType.INTERNAL}
                        authService={this.AuthService}
                        isEditable={false}
                      />
                    )}
                    {this.state.activityTabSelectedIndex === 2 && (
                      <IncidentComments incidentId={incident.id} commentType={commentType.LOG} authService={this.AuthService} isEditable={false} />
                    )}
                  </Grid>
                </Grid>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </CardBody>
        </Card>
        {updateLoading && <CircularProgress className={classes.progress} size={50} thickness={5} />}
      </div>
    );
  }
}

export default withStyles(styles)(IncidentDetails);
